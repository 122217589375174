import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CanActivate, CanActivateChild, Router } from '@angular/router';
import { AuthService } from 'app/core/auth/auth.service'; // Kullanıcının KYC Level bilgisine erişmek için
import { KycComponent } from 'app/layout/common/kyc/kyc.component';

@Injectable({
    providedIn: 'root'
})
export class KycLevelGuard implements CanActivate, CanActivateChild {

        constructor(private authService: AuthService, private router: Router, private _matDialog: MatDialog) {}

    canActivate(): boolean {
        return this.checkKycLevel();
    }

    canActivateChild(): boolean {
        return this.checkKycLevel();
    }

    checkKycLevel(): boolean {
        // Burayı biraz daha özelleştirmemiz gerekiyor
        if (localStorage.getItem("usr-access-level") >= "1") {
            return true;
        } else {
            this.showKycLevelModal();
            return false;
        }
    }

    showKycLevelModal(): void {
        // Modal gösterme işlemi burada yapılabilir
        const dialogRef = this._matDialog.open(KycComponent);
        dialogRef.afterClosed()
                 .subscribe((result) => {
                 });        
        this.router.navigate(['/']); // Yeterli olmayan kullanıcıyı yönlendir
    }
}
