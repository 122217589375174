import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef, ViewEncapsulation, Inject } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { MatButton } from '@angular/material/button';
import { Subject, takeUntil } from 'rxjs';
import { NotificationsService } from 'app/layout/common/notifications/notifications.service';
import { FuseConfirmationService } from "@fuse/services/confirmation";
import { FormBuilder, FormGroup, NgForm, Validators } from "@angular/forms";
import { environment } from 'environments/environment'
import { ToastServiceComponent } from 'app/layout/common/toast-service/toast-service.component';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog"
import { MatTooltip } from '@angular/material/tooltip';


@Component({
    selector       : 'notifications',
    templateUrl    : './notifications.component.html',
    encapsulation  : ViewEncapsulation.None,
    // changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs       : 'notifications'
})
export class NotificationsComponent implements OnInit, OnDestroy
{
    @ViewChild('notificationsOrigin') private _notificationsOrigin: MatButton;
    @ViewChild('notificationsPanel') private _notificationsPanel: TemplateRef<any>;
    @ViewChild('tooltip') manualTooltip: MatTooltip;

    NotificationsConfigForm: FormGroup; // dialog
    notificationIsOpen: boolean = false;
    public notificationToolTip: string = "Bildirimler";
    notifications: any[];
    unreadCount: number = 0;
    private _overlayRef: OverlayRef;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _notificationsService: NotificationsService,
        private _overlay: Overlay,
        private _viewContainerRef: ViewContainerRef,
        private _fuseConfirmationService: FuseConfirmationService,
        private _formBuilder: FormBuilder,
        public dialog: MatDialog,

    )
    {
    }


    /**
     * On init
     */
    ngOnInit(): void
    {

        this._notificationsService.notifications$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((notifications: any[]) => {


                this.notifications = notifications;
                if (this.notifications['data']) {
                    this.notifications = this.notifications['data'];
                }

                
                this._calculateUnreadCount();

                this._changeDetectorRef.markForCheck();
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();

        // Dispose the overlay
        if ( this._overlayRef )
        {
            this._overlayRef.dispose();
        }
    }


    openPanel(): void
    {
        this.manualTooltip.hide();
        this.notificationToolTip = "Bildirimler";


        if ( !this._notificationsPanel || !this._notificationsOrigin )
        {
            return;
        }

        // Create the overlay if it doesn't exist
        if ( !this._overlayRef )
        {
            this._createOverlay();
        }

        // Attach the portal to the overlay
        this._overlayRef.attach(new TemplatePortal(this._notificationsPanel, this._viewContainerRef));
    }

    closePanel(): void
    {
        this._overlayRef.detach();
    }


    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any
    {
        return item.id || index;
    }


    /**
     * duyurunun detaylari icin dialog acar
     */ 
    details(notification) {

        if (!notification?.readted_at) {
            this.setReadNotification(notification);
        }

        const dialogRef = this.dialog.open(DetailsNotificationComponent, {
            data: notification,
            panelClass: 'max-height-100'
        });
        dialogRef.afterClosed().subscribe(result => {
            
        });

    }

    /**
     * tooltip degistirir
     */ 
    setNotificationToolTip() {
        this.notificationToolTip = "Yeni bildiriminiz var.";
        setTimeout(() => {
            this.manualTooltip.show();
        }, 50);
    }


    /**
     * Bildirimi okundu yapar
     */
     setReadNotification(notification) {
        this._notificationsService.setReadNotification(notification?.id).
            subscribe((response) => {
                if (response?.status) {
                    this.getAllNotifications();
                }
            });
     } 


     /**
      * Tum bildirimleri siler
      */ 
     allDelete() {
        this._notificationsService.allDelete().
            subscribe((response) => {
                if (response?.status) {
                    this.getAllNotifications();
                }
            });
     }


     /**
      * Secilen bildirimi siler
      */ 
     delete(notification) {
        this._notificationsService.delete(notification?.id).
            subscribe((response) => {
                if (response?.status) {
                    this.getAllNotifications();
                }
            });
     }


    /**
     * Butun duyurulari getirir;
     */ 
    getAllNotifications() {
        this._notificationsService.getAll().subscribe();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create the overlay
     */
    private _createOverlay(): void
    {
        // Create the overlay
        this._overlayRef = this._overlay.create({
            hasBackdrop     : true,
            backdropClass   : 'fuse-backdrop-on-mobile',
            scrollStrategy  : this._overlay.scrollStrategies.block(),
            positionStrategy: this._overlay.position()
                                  .flexibleConnectedTo(this._notificationsOrigin._elementRef.nativeElement)
                                  .withLockedPosition(true)
                                  .withPush(true)
                                  .withPositions([
                                      {
                                          originX : 'start',
                                          originY : 'bottom',
                                          overlayX: 'start',
                                          overlayY: 'top'
                                      },
                                      {
                                          originX : 'start',
                                          originY : 'top',
                                          overlayX: 'start',
                                          overlayY: 'bottom'
                                      },
                                      {
                                          originX : 'end',
                                          originY : 'bottom',
                                          overlayX: 'end',
                                          overlayY: 'top'
                                      },
                                      {
                                          originX : 'end',
                                          originY : 'top',
                                          overlayX: 'end',
                                          overlayY: 'bottom'
                                      }
                                  ])
        });

        // Detach the overlay from the portal on backdrop click
        this._overlayRef.backdropClick().subscribe(() => {
            this._overlayRef.detach();
        });
    }

    /**
     * Calculate the unread count
     *
     * @private
     */
    private _calculateUnreadCount(): void
    {
        let count = 0;

        if ( this.notifications && this.notifications.length )
        {
            count = this.notifications.filter(notification => !notification.readted_at).length;
        }

        this.unreadCount = count;
    }



}



@Component({
    selector: "collectiveInvoicePayment",
    templateUrl: "collectiveInvoicePayment.component.html",
})
export class DetailsNotificationComponent {
    
    @ViewChild(ToastServiceComponent) toastService: ToastServiceComponent;
    
    baseUrl: string;
    session_token: string;

    responsePayments: any; // odenemeye gonderilen faturalarin apiden donen cevabi tutar
    Object = Object;
    displayedColumnsResult: string[] = ['id', 'uid', 'invoice_no', 'debt', 'total_commission', 'total', 'last_payment_date'];


    constructor(
        private dialogRef: MatDialogRef<DetailsNotificationComponent>,
        private _notificationsService: NotificationsService,
        @Inject(MAT_DIALOG_DATA) public data: any

    ) {
        this.baseUrl = environment.baseUrl;
        this.session_token = localStorage.getItem('accessToken');
    }


    ngOnInit() {
        console.log("this.data");
        console.log(this.data);
        console.log("this.data");

        if (new String(this.data?.title).valueOf() == new String('Toplu Fatura Ödeme').valueOf() || this.data?.facility_type_id == 15) {
            this.responsePayments = this.data;
            let content = JSON.parse(this.data?.content);
            this.responsePayments.content = content;

        } else {
            this.responsePayments = this.data;
            
        }

        

    }

    getReceipt() {
        window.open(`${this.baseUrl}/dealer/invoice/paymentGroups/${this.responsePayments?.content?.data?.faturaOdemeGrup_uid}/receipt?session_token=${this.session_token}`, '_blank').focus();
    }


    onConfirmClick(): void {
        this.dialogRef.close(true);
    }

    close(): void {
        this.dialogRef.close();
    }


}
