import { Injectable } from '@angular/core';
import { HttpService } from 'app/core/http/http.service';
import { map, Observable, ReplaySubject, switchMap, take, tap, Subject, takeUntil } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class NotificationsService
{

    private _notifications: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);


    /**
     * Constructor
     */
    constructor(
        private _httpService: HttpService
    )
    {
    }


    get notifications$(): Observable<any[]>
    {
        return this._notifications.asObservable();
    }


    getAll(): Observable<any[]>
    {
        return this._httpService.get('/dealer/auth/notifications/getNotifications').pipe(
            tap((notifications) => {
                this._notifications.next(notifications);
            })
        );
    }


    /**
     * Bildirimi okudum diye apiye istek gonderir -tek
     */
    setReadNotification(id)
    {
        return this._httpService.put(`/dealer/auth/notifications/setReadNotification/${id}`);
    }


    /**
     * Bildirimi siler
     */
    delete(id)
    {
        return this._httpService.delete(`/dealer/auth/notifications/delete/${id}`);
    }


    /**
     * Tum bildirimleri siler
     */
    allDelete()
    {
        return this._httpService.delete(`/dealer/auth/notifications/allDelete`);
    }

}
