import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FocusCommunicationServiceService {

  constructor() { }

  private focusSubject = new BehaviorSubject<boolean>(false);

  focus$ = this.focusSubject.asObservable();

  setFocus( focus: boolean ){
    this.focusSubject.next(focus);
  }
}
