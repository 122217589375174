import { Component, OnInit, OnDestroy, ViewChild, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';;
import { Router } from '@angular/router';

@Component({
	selector     : 'upgrade',
    templateUrl  : './upgrade.component.html',
    styleUrls    : ['upgrade.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class UpgradeComponent implements OnInit, OnDestroy
{
	upgradeCount: number = 0;

	constructor
	(
        private _router: Router
	)
	{
	}


	ngOnInit(): void
	{
		if (localStorage.getItem("upgradeCount")) {
			this.upgradeCount = Number(localStorage.getItem("upgradeCount"));
		}
	}


	ngOnDestroy(): void
    {
    }

	openFire() {
		localStorage.setItem("upgradeCount", "1");
		this.upgradeCount = 1;
	}

	update() {
        this._router.navigate(['/settings/systemic/changelog']);
	}

}
