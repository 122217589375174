import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { VerifyGuard } from 'app/core/auth/guards/verify.guard';
import { LockedGuard } from 'app/core/auth/guards/locked.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';
import { KycLevelGuard } from './core/auth/guards/kycLevel.guard';

// @formatter:off
// tslint:disable:max-line-length
export const appRoutes: Route[] = [

    // Redirect empty path to '/example'
    {path: '', pathMatch : 'full', redirectTo: 'dashboard'},

    // Redirect signed in user to the '/example'
    //
    // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    {path: 'signed-in-redirect', pathMatch : 'full', redirectTo: 'dashboard'},

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule)},
            {path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule)},
            {path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule)},
            {path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule)},
            {path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule)},
        ]
    },


    // Landing routes
    {
        path: '',
        canActivate: [VerifyGuard],
        canActivateChild: [VerifyGuard],
        component  : LayoutComponent,
        data: {
            layout: 'empty'
        },
        children   : [
            {path: 'verify', loadChildren: () => import('app/modules/auth/verify/verify.module').then(m => m.AuthVerifyModule)},
        ]
    },

    {
        path: '',
        canActivate: [LockedGuard],
        canActivateChild: [LockedGuard],
        component  : LayoutComponent,
        data: {
            layout: 'empty'
        },
        children   : [
            {path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule)}
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule)},
        ]
    },

    // Admin routes
    {
        path       : '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component  : LayoutComponent,
        resolve    : {
            initialData: InitialDataResolver,
        },
        children   : [
            {path: 'dashboard', loadChildren: () => import('app/modules/dashboard/dashboard.module').then(m => m.DashboardModule)},
            {path: 'myAccounts', loadChildren: () => import('app/modules/myAccounts/myAccounts.module').then(m => m.MyAccountsModule)},
            {path: 'payments', loadChildren: () => import('app/modules/payments/payments.module').then(m => m.PaymentsModule)},
            {path: 'accounting', loadChildren: () => import('app/modules/accounting/accounting.module').then(m => m.AccountingModule)},
            {path: 'settings', loadChildren: () => import('app/modules/settings/settings.module').then(m => m.SettingsModule)},
            {path: 'support', loadChildren: () => import('app/modules/support/support.module').then(m => m.SupportModule)},

            {path: 'developer', loadChildren: () => import('app/modules/developer/developer.module').then(m => m.DeveloperModule)},
            {path: 'moneyTransfer-appeal', loadChildren: () => import('app/modules/appeals/moneyTransfer/moneyTransferAppeal.module').then(m => m.MoneyTransferAppealModule)},
            {path: 'merchant-appeal', loadChildren: () => import('app/modules/appeals/merchant/merchant.module').then(m => m.MerchantAppealModule)},
            {path: 'invoicePaymentsGroup-appeal', loadChildren: () => import('app/modules/appeals/invoicePaymentsGroup/invoicePaymentsGroup.module').then(m => m.InvoicePaymentsGroupAppealModule)},
        ]
    },

    {
        path       : '',
        canActivate: [AuthGuard, KycLevelGuard],
        canActivateChild: [AuthGuard, KycLevelGuard],
        component  : LayoutComponent,
        resolve    : {
            initialData: InitialDataResolver,
        },
        children   : [
            {path: 'moneyTransfer', loadChildren: () => import('app/modules/moneyTransfer/moneyTransfer.module').then(m => m.MoneyTransferModule)},
            {path: 'merchant', loadChildren: () => import('app/modules/merchant/merchant.module').then(m => m.MerchantModule)},
            {path: 'eshopping', loadChildren: () => import('app/modules/eShopping/eShopping.module').then(m => m.EShoppingModule)},
        ]
    }
];
