import { NgModule } from '@angular/core';
import { LoadingScreenService } from '@fuse/services/loading-screen/loading-screen.service';

@NgModule({
    providers: [
        LoadingScreenService
    ]
})
export class LoadingScreenModule
{
    /**
     * Constructor
     */
    constructor(private _loadingScreenService: LoadingScreenService)
    {
    }
}
