import { Injectable, Inject, EventEmitter } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap, timeout } from 'rxjs/operators';
import { AuthService } from 'app/core/auth/auth.service';
import { Router } from '@angular/router';
import { environment } from 'environments/environment'
import { ToastServiceComponent } from 'app/layout/common/toast-service/toast-service.component';

@Injectable({
    providedIn: 'root'
})
export class HttpService
{

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        // private _authService: AuthService,
        private _router: Router,
        // @Inject("baseUrl") private baseUrl: string,
        private _toastService: ToastServiceComponent

    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Http istekleri GET-POST-PUT-PATCH-DELETE
    // -----------------------------------------------------------------------------------------------------

    get(url?, payload?, token = true)
    {
        if (payload) {
            payload.session_token = localStorage.getItem('accessToken');

        } else {
            payload = {};
            payload.session_token = localStorage.getItem('accessToken');
        }

    	return this._httpClient.get(`${environment.baseUrl}${url}`,  {params: payload}).pipe(

            catchError((err, caught) => {
                if(err.name == "TimeoutError"){
                    this._toastService.open({
                        toastType: "error",
                        toastDescription: ("Bağlantı zaman aşımına uğradı. Lütfen yeniden deneyin.")
                    }, 5000);
                    return of({
                        status: false,
                        error: {
                            description: "Bağlantı zaman aşımına uğradı. Lütfen yeniden deneyin."
                        }
                    })
                } else {
                    this._toastService.open({
                        toastType: "error",
                        toastDescription: ("Beklenmedik bir hata oluştu.")
                    }, 5000);

                    return of({
                        status: false,
                        error: {
                            description: "Beklenmedik bir hata oluştu."
                        }
                    })
                }
                
            }),
            timeout(30000),
            switchMap((response: any) => {

            	if ( response.status ) {
                	return of(response);

            	} else {
            		if ( response.error.code == 400 && response.error.type == "session" ) {
                        this._toastService.open({
                            toastType: "error",
                            toastDescription: (response?.error?.description)
                        }, 5000);
            			// oturum suresi sona erdi
                        console.log("Oturum suresi sona erdi.");
                        // this._authService.signOut();
                        const authService = new AuthService(this);
                        authService.signOut();
                        this._router.navigate(['sign-in']);
                        return of(false);

            		} else if ( response.error.code == 101 && response.error.type == "session" ) {
                        this._toastService.open({
                            toastType: "error",
                            toastDescription: (response?.error?.description)
                        }, 5000);
                        // oturum suresi sona erdi
                        console.log("Oturum suresi sona erdi.");
                        // this._authService.signOut();
                        const authService = new AuthService(this);
                        authService.signOut();
                        this._router.navigate(['sign-in']);
                        return of(false);

                    } else if ( response.error.code == 102 && response.error.type == "session" ) {
                        this._toastService.open({
                            toastType: "error",
                            toastDescription: (response?.error?.description)
                        }, 5000);
                        // ikinci dogrulamaya yonlendir
                        console.log("Oturum zaman asimina ugramis.");
                        const authService = new AuthService(this);
                        authService.signOut();
                        // this._authService.signOut();
                        this._router.navigate(['sign-in']);
                        return of(false);
                    } else if ( response.error.code == 105 && response.error.type == "session" ) {
                        this._toastService.open({
                            toastType: "error",
                            toastDescription: (response?.error?.description)
                        }, 5000);
                        // ikinci dogrulamaya yonlendir
                        console.log("Oturumunuz sistem yöneticisi tarafından sonlandırıldı!");
                        const authService = new AuthService(this);
                        authService.signOut();
                        // this._authService.signOut();
                        this._router.navigate(['sign-in']);
                        return of(false);
                    } else if ( response.error.code == 103 ) {
                        this._toastService.open({
                            toastType: "error",
                            toastDescription: (response?.error?.description)
                        }, 5000);
                        // ikinci dogrulamaya yonlendir
                        return of(false); 
                    } else if ( response.error.code == 110 && response.error.type == "session" ) {
                        this._toastService.open({
                            toastType: "error",
                            toastDescription: (response?.error?.description)
                        }, 5000);
                        // ikinci dogrulamaya yonlendir
                        console.log("Oturumunuz uzun süre işlem yapmadığınız için kilitlenmiş!");
                        const authService = new AuthService(this);
                        authService.locked = 'true';
                        // this._router.navigate(['sign-in']);
                        this._router.navigate(['/unlock-session']);
                        return of(false);
                    } else if ( response.error.code == 404 && response.error.type == "session" ) {
                        this._toastService.open({
                            toastType: "error",
                            toastDescription: (response?.error?.description)
                        }, 5000);
                        console.log("Token bulunamadi.");
                        const authService = new AuthService(this);
                        authService.signOut();
                        // this._authService.signOut();
                        this._router.navigate(['sign-in']);
                        return of(false);
                    } else {
                        return of(response);

                    }

            	}


            }),

        );


    }


    post(url?, payload?, token = true)
    {
        let postUrl;
        if (token) {
            postUrl = `${environment.baseUrl}${url}?session_token=${localStorage.getItem('accessToken')}`;
        } else {
            postUrl = `${environment.baseUrl}${url}`;

        }
    	return this._httpClient.post(postUrl, payload).pipe(

            catchError((err, caught) => {
                if(err.name == "TimeoutError"){
                    this._toastService.open({
                        toastType: "error",
                        toastDescription: ("Bağlantı zaman aşımına uğradı. Lütfen yeniden deneyin.")
                    }, 5000);
                    return of({
                        status: false,
                        error: {
                            description: "Bağlantı zaman aşımına uğradı. Lütfen yeniden deneyin."
                        }
                    })
                } else {
                    this._toastService.open({
                        toastType: "error",
                        toastDescription: ("Beklenmedik bir hata oluştu.")
                    }, 5000);

                    return of({
                        status: false,
                        error: {
                            description: "Beklenmedik bir hata oluştu."
                        }
                    })
                }
                
            }),
            timeout(30000),
            switchMap((response: any) => {

                if ( response.status ) {
                    return of(response);

                } else {
                    if ( response.error.code == 400 && response.error.type == "session" ) {
                        this._toastService.open({
                            toastType: "error",
                            toastDescription: (response?.error?.description)
                        }, 5000);
                        // oturum suresi sona erdi
                        console.log("Oturum suresi sona erdi.");
                        // this._authService.signOut();
                        const authService = new AuthService(this);
                        authService.signOut();
                        this._router.navigate(['sign-in']);
                        return of(false);

                    } else if ( response.error.code == 101 && response.error.type == "session" ) {
                        this._toastService.open({
                            toastType: "error",
                            toastDescription: (response?.error?.description)
                        }, 5000);
                        // oturum suresi sona erdi
                        console.log("Oturum suresi sona erdi.");
                        // this._authService.signOut();
                        const authService = new AuthService(this);
                        authService.signOut();
                        this._router.navigate(['sign-in']);
                        return of(false);

                    } else if ( response.error.code == 102 && response.error.type == "session" ) {
                        this._toastService.open({
                            toastType: "error",
                            toastDescription: (response?.error?.description)
                        }, 5000);
                        // ikinci dogrulamaya yonlendir
                        console.log("Oturum zaman asimina ugramis.");
                        const authService = new AuthService(this);
                        authService.signOut();
                        // this._authService.signOut();
                        this._router.navigate(['sign-in']);
                        return of(false);
                    } else if ( response.error.code == 105 && response.error.type == "session" ) {
                        this._toastService.open({
                            toastType: "error",
                            toastDescription: (response?.error?.description)
                        }, 5000);
                        // ikinci dogrulamaya yonlendir
                        console.log("Oturumunuz sistem yöneticisi tarafından sonlandırıldı!");
                        const authService = new AuthService(this);
                        authService.signOut();
                        // this._authService.signOut();
                        this._router.navigate(['sign-in']);
                        return of(false);
                    } else if ( response.error.code == 103 ) {
                        this._toastService.open({
                            toastType: "error",
                            toastDescription: (response?.error?.description)
                        }, 5000);
                        // ikinci dogrulamaya yonlendir
                        return of(false); 
                    } else if ( response.error.code == 110 && response.error.type == "session" ) {
                        this._toastService.open({
                            toastType: "error",
                            toastDescription: (response?.error?.description)
                        }, 5000);
                        // ikinci dogrulamaya yonlendir
                        console.log("Oturumunuz uzun süre işlem yapmadığınız için kilitlenmiş!");
                        const authService = new AuthService(this);
                        authService.locked = 'true';
                        // this._router.navigate(['sign-in']);
                        this._router.navigate(['/unlock-session']);
                        return of(false);
                    } else if ( response.error.code == 404 && response.error.type == "session" ) {
                        this._toastService.open({
                            toastType: "error",
                            toastDescription: (response?.error?.description)
                        }, 5000);
                        console.log("Token bulunamadi.");
                        const authService = new AuthService(this);
                        authService.signOut();
                        // this._authService.signOut();
                        this._router.navigate(['sign-in']);
                        return of(false);
                    } else {
                        return of(response);

                    }

                }


            }),


        );

    }


    put(url?, payload?)
    {
        return this._httpClient.put(`${environment.baseUrl}${url}?session_token=${localStorage.getItem('accessToken')}`, payload).pipe(

            catchError((err, caught) => {
                if(err.name == "TimeoutError"){
                    this._toastService.open({
                        toastType: "error",
                        toastDescription: ("Bağlantı zaman aşımına uğradı. Lütfen yeniden deneyin.")
                    }, 5000);
                    return of({
                        status: false,
                        error: {
                            description: "Bağlantı zaman aşımına uğradı. Lütfen yeniden deneyin."
                        }
                    })
                } else {
                    this._toastService.open({
                        toastType: "error",
                        toastDescription: ("Beklenmedik bir hata oluştu.")
                    }, 5000);

                    return of({
                        status: false,
                        error: {
                            description: "Beklenmedik bir hata oluştu."
                        }
                    })
                }
                
            }),
            timeout(30000),
            switchMap((response: any) => {

                if ( response.status ) {
                    return of(response);

                } else {
                    if ( response.error.code == 400 && response.error.type == "session" ) {
                        this._toastService.open({
                            toastType: "error",
                            toastDescription: (response?.error?.description)
                        }, 5000);
                        // oturum suresi sona erdi
                        console.log("Oturum suresi sona erdi.");
                        // this._authService.signOut();
                        const authService = new AuthService(this);
                        authService.signOut();
                        this._router.navigate(['sign-in']);
                        return of(false);

                    } else if ( response.error.code == 101 && response.error.type == "session" ) {
                        this._toastService.open({
                            toastType: "error",
                            toastDescription: (response?.error?.description)
                        }, 5000);
                        // oturum suresi sona erdi
                        console.log("Oturum suresi sona erdi.");
                        // this._authService.signOut();
                        const authService = new AuthService(this);
                        authService.signOut();
                        this._router.navigate(['sign-in']);
                        return of(false);

                    } else if ( response.error.code == 102 && response.error.type == "session" ) {
                        this._toastService.open({
                            toastType: "error",
                            toastDescription: (response?.error?.description)
                        }, 5000);
                        // ikinci dogrulamaya yonlendir
                        console.log("Oturum zaman asimina ugramis.");
                        const authService = new AuthService(this);
                        authService.signOut();
                        // this._authService.signOut();
                        this._router.navigate(['sign-in']);
                        return of(false);
                    } else if ( response.error.code == 105 && response.error.type == "session" ) {
                        this._toastService.open({
                            toastType: "error",
                            toastDescription: (response?.error?.description)
                        }, 5000);
                        // ikinci dogrulamaya yonlendir
                        console.log("Oturumunuz sistem yöneticisi tarafından sonlandırıldı!");
                        const authService = new AuthService(this);
                        authService.signOut();
                        // this._authService.signOut();
                        this._router.navigate(['sign-in']);
                        return of(false);
                    } else if ( response.error.code == 103 ) {
                        this._toastService.open({
                            toastType: "error",
                            toastDescription: (response?.error?.description)
                        }, 5000);
                        // ikinci dogrulamaya yonlendir
                        return of(false); 
                    } else if ( response.error.code == 110 && response.error.type == "session" ) {
                        this._toastService.open({
                            toastType: "error",
                            toastDescription: (response?.error?.description)
                        }, 5000);
                        // ikinci dogrulamaya yonlendir
                        console.log("Oturumunuz uzun süre işlem yapmadığınız için kilitlenmiş!");
                        const authService = new AuthService(this);
                        authService.locked = 'true';
                        // this._router.navigate(['sign-in']);
                        this._router.navigate(['/unlock-session']);
                        return of(false);
                    } else if ( response.error.code == 404 && response.error.type == "session" ) {
                        this._toastService.open({
                            toastType: "error",
                            toastDescription: (response?.error?.description)
                        }, 5000);
                        console.log("Token bulunamadi.");
                        const authService = new AuthService(this);
                        authService.signOut();
                        // this._authService.signOut();
                        this._router.navigate(['sign-in']);
                        return of(false);
                    } else {
                        return of(response);

                    }

                }


            }),


        );

    }


    delete(url?, payload?)
    {
        return this._httpClient.delete(`${environment.baseUrl}${url}?session_token=${localStorage.getItem('accessToken')}`, { body: payload }).pipe(

            catchError((err, caught) => {
                if(err.name == "TimeoutError"){
                    this._toastService.open({
                        toastType: "error",
                        toastDescription: ("Bağlantı zaman aşımına uğradı. Lütfen yeniden deneyin.")
                    }, 5000);
                    return of({
                        status: false,
                        error: {
                            description: "Bağlantı zaman aşımına uğradı. Lütfen yeniden deneyin."
                        }
                    })
                } else {
                    this._toastService.open({
                        toastType: "error",
                        toastDescription: ("Beklenmedik bir hata oluştu.")
                    }, 5000);

                    return of({
                        status: false,
                        error: {
                            description: "Beklenmedik bir hata oluştu."
                        }
                    })
                }
                
            }),
            timeout(30000),
            switchMap((response: any) => {

                if ( response.status ) {
                    return of(response);

                } else {
                    if ( response.error.code == 400 && response.error.type == "session" ) {
                        this._toastService.open({
                            toastType: "error",
                            toastDescription: (response?.error?.description)
                        }, 5000);
                        // oturum suresi sona erdi
                        console.log("Oturum suresi sona erdi.");
                        // this._authService.signOut();
                        const authService = new AuthService(this);
                        authService.signOut();
                        this._router.navigate(['sign-in']);
                        return of(false);

                    } else if ( response.error.code == 101 && response.error.type == "session" ) {
                        this._toastService.open({
                            toastType: "error",
                            toastDescription: (response?.error?.description)
                        }, 5000);
                        // oturum suresi sona erdi
                        console.log("Oturum suresi sona erdi.");
                        // this._authService.signOut();
                        const authService = new AuthService(this);
                        authService.signOut();
                        this._router.navigate(['sign-in']);
                        return of(false);

                    } else if ( response.error.code == 102 && response.error.type == "session" ) {
                        this._toastService.open({
                            toastType: "error",
                            toastDescription: (response?.error?.description)
                        }, 5000);
                        // ikinci dogrulamaya yonlendir
                        console.log("Oturum zaman asimina ugramis.");
                        const authService = new AuthService(this);
                        authService.signOut();
                        // this._authService.signOut();
                        this._router.navigate(['sign-in']);
                        return of(false);
                    } else if ( response.error.code == 105 && response.error.type == "session" ) {
                        this._toastService.open({
                            toastType: "error",
                            toastDescription: (response?.error?.description)
                        }, 5000);
                        // ikinci dogrulamaya yonlendir
                        console.log("Oturumunuz sistem yöneticisi tarafından sonlandırıldı!");
                        const authService = new AuthService(this);
                        authService.signOut();
                        // this._authService.signOut();
                        this._router.navigate(['sign-in']);
                        return of(false);
                    } else if ( response.error.code == 103 ) {
                        this._toastService.open({
                            toastType: "error",
                            toastDescription: (response?.error?.description)
                        }, 5000);
                        // ikinci dogrulamaya yonlendir
                        return of(false); 
                    } else if ( response.error.code == 110 && response.error.type == "session" ) {
                        this._toastService.open({
                            toastType: "error",
                            toastDescription: (response?.error?.description)
                        }, 5000);
                        // ikinci dogrulamaya yonlendir
                        console.log("Oturumunuz uzun süre işlem yapmadığınız için kilitlenmiş!");
                        const authService = new AuthService(this);
                        authService.locked = 'true';
                        // this._router.navigate(['sign-in']);
                        this._router.navigate(['/unlock-session']);
                        return of(false);
                    } else if ( response.error.code == 404 && response.error.type == "session" ) {
                        this._toastService.open({
                            toastType: "error",
                            toastDescription: (response?.error?.description)
                        }, 5000);
                        console.log("Token bulunamadi.");
                        const authService = new AuthService(this);
                        authService.signOut();
                        // this._authService.signOut();
                        this._router.navigate(['sign-in']);
                        return of(false);
                    } else {
                        return of(response);

                    }

                }


            }),


        );
    }


    patch(url?, payload?)
    {
        return this._httpClient.patch(`${environment.baseUrl}${url}?session_token=${localStorage.getItem('accessToken')}`, payload).pipe(

            catchError((err, caught) => {
                if(err.name == "TimeoutError"){
                    this._toastService.open({
                        toastType: "error",
                        toastDescription: ("Bağlantı zaman aşımına uğradı. Lütfen yeniden deneyin.")
                    }, 5000);
                    return of({
                        status: false,
                        error: {
                            description: "Bağlantı zaman aşımına uğradı. Lütfen yeniden deneyin."
                        }
                    })
                } else {
                    this._toastService.open({
                        toastType: "error",
                        toastDescription: ("Beklenmedik bir hata oluştu.")
                    }, 5000);

                    return of({
                        status: false,
                        error: {
                            description: "Beklenmedik bir hata oluştu."
                        }
                    })
                }
                
            }),
            timeout(30000),
            switchMap((response: any) => {

                if ( response.status ) {
                    return of(response);

                } else {
                    if ( response.error.code == 400 && response.error.type == "session" ) {
                        this._toastService.open({
                            toastType: "error",
                            toastDescription: (response?.error?.description)
                        }, 5000);
                        // oturum suresi sona erdi
                        console.log("Oturum suresi sona erdi.");
                        // this._authService.signOut();
                        const authService = new AuthService(this);
                        authService.signOut();
                        this._router.navigate(['sign-in']);
                        return of(false);

                    } else if ( response.error.code == 101 && response.error.type == "session" ) {
                        this._toastService.open({
                            toastType: "error",
                            toastDescription: (response?.error?.description)
                        }, 5000);
                        // oturum suresi sona erdi
                        console.log("Oturum suresi sona erdi.");
                        // this._authService.signOut();
                        const authService = new AuthService(this);
                        authService.signOut();
                        this._router.navigate(['sign-in']);
                        return of(false);

                    } else if ( response.error.code == 102 && response.error.type == "session" ) {
                        this._toastService.open({
                            toastType: "error",
                            toastDescription: (response?.error?.description)
                        }, 5000);
                        // ikinci dogrulamaya yonlendir
                        console.log("Oturum zaman asimina ugramis.");
                        const authService = new AuthService(this);
                        authService.signOut();
                        // this._authService.signOut();
                        this._router.navigate(['sign-in']);
                        return of(false);
                    } else if ( response.error.code == 105 && response.error.type == "session" ) {
                        this._toastService.open({
                            toastType: "error",
                            toastDescription: (response?.error?.description)
                        }, 5000);
                        // ikinci dogrulamaya yonlendir
                        console.log("Oturumunuz sistem yöneticisi tarafından sonlandırıldı!");
                        const authService = new AuthService(this);
                        authService.signOut();
                        // this._authService.signOut();
                        this._router.navigate(['sign-in']);
                        return of(false);
                    } else if ( response.error.code == 103 ) {
                        this._toastService.open({
                            toastType: "error",
                            toastDescription: (response?.error?.description)
                        }, 5000);
                        // ikinci dogrulamaya yonlendir
                        return of(false); 
                    } else if ( response.error.code == 110 && response.error.type == "session" ) {
                        this._toastService.open({
                            toastType: "error",
                            toastDescription: (response?.error?.description)
                        }, 5000);
                        // ikinci dogrulamaya yonlendir
                        console.log("Oturumunuz uzun süre işlem yapmadığınız için kilitlenmiş!");
                        const authService = new AuthService(this);
                        authService.locked = 'true';
                        // this._router.navigate(['sign-in']);
                        this._router.navigate(['/unlock-session']);
                        return of(false);
                    } else if ( response.error.code == 404 && response.error.type == "session" ) {
                        this._toastService.open({
                            toastType: "error",
                            toastDescription: (response?.error?.description)
                        }, 5000);
                        console.log("Token bulunamadi.");
                        const authService = new AuthService(this);
                        authService.signOut();
                        // this._authService.signOut();
                        this._router.navigate(['sign-in']);
                        return of(false);
                    } else {
                        return of(response);

                    }

                }


            }),


        );
    }
  
}
