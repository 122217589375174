import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from 'app/shared/shared.module';
import { ToastMessageComponent, ToastServiceComponent } from './toast-service.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatButtonModule } from '@angular/material/button';


@NgModule({
    declarations: [
        ToastServiceComponent,
        ToastMessageComponent
    ],
    imports     : [
        SharedModule,
        MatIconModule,
        MatSnackBarModule,
        MatButtonModule,
    ],
    exports     : [
        ToastServiceComponent
    ],
})
export class ToastServiceModule
{
}
