// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    baseUrl: "https://platform-test-v2-user-api.onaymatik.com",
    storageUrl: "https://platform-storage.onaymatik.com",
    prtmUrl: 'https://onaymatik.com',
    ws: "platform-socket-test.onaymatik.com",
    swPublicKey: "BIlZDeKZJEiVidkfJakktbIae2ezzUSIasyMt1zqh5g_G3HhjBN4g0WQ9I9VG2JTizkXb4BKvvdEhIYK5g4mAfk",
    production: false,
    companyTitle: "Onaymatik Bilişim Ticaret Limited Şirketi",
    userFrontUrl: "platform.user.onaymatik.com",
    userUrlProtocol: "https://",
    saleDomain: ".onaymatik.com",
    vposApi: "https://platform-test-api-vpos.onaymatik.com",

    getUserSaleUrl: (subdomain: string) => `https://${subdomain}.onaymatik.com`,


    publicKvkkUrl: "https://platform-storage.onaymatik.com/Www/kvkk/KVKK-Veri-Koruma-ve-Islenmesi-Aydinlatma-Metni.pdf",
    publicCerceveSozlesmeUrl: "https://platform-storage.onaymatik.com/Www/ekler/Cerceve-Sozlesme.pdf",
    contactPhone: "(+90) 242 742 0304"
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
