<div class="flex flex-col justify-center items-center">
    <!-- <svg xmlns="http://www.w3.org/2000/svg" version="1.2" viewBox="0 0 64 81" width="64" height="81">
        <g id="Layer"></g>
        <path id="logop" repeatCount="indefinite" d="M 26.618 43.414 C 26.618 43.414 30.396 46.751 38.434 47.092 C 46.472 47.433 60.076 40.647 59.923 25.512 C 59.77 10.377 46.296 4.406 39.179 4.409 C 32.062 4.412 17.827 8.69 17.193 25.337 C 16.559 41.984 17.304 77.099 17.304 77.099 C 17.304 77.099 41.189 75.471 45.17 55.386"></path>
        <polyline id="one" repeatCount="indefinite" points="2.305 37.347 32.348 19.748"></polyline>
        <polyline id="two" repeatCount="indefinite" points="2.334 49.19 32.377 31.591"></polyline>
    </svg> -->
    <svg class="onaymatik-logo-animation" version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="64" height="64">
        <defs>
            <image  width="64" height="64" id="img1" href="assets/images/logo/logo-text.svg"/>
        </defs>
        <style>
        </style>
        <use id="Background" href="#img1" x="0" y="0"/>
    </svg>
    <span class="text-md mt-6 text-secondary">Lütfen bekleyiniz.</span>
</div>