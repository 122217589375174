import { NgModule } from '@angular/core';
import { SharedModule } from 'app/shared/shared.module';
import { QuickWithdrawalComponent, QuickWithdrawalDialog } from './quick-withdrawal.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { FuseAlertModule } from '@fuse/components/alert';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule} from "@angular/material/list";
import { SearchInputModule } from '../search-input/search-input.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ToastServiceModule } from '../toast-service/toast-service.module';
import { MatStepperModule } from '@angular/material/stepper';
import { FuseCardModule } from '@fuse/components/card';
import { SmsVerifyModule } from '../sms-verify/sms-verify.module';

@NgModule({
    declarations: [
        QuickWithdrawalComponent,
        QuickWithdrawalDialog
    ],
    imports: [
        SharedModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatDialogModule,
        NgxMaskDirective,
        NgxMaskPipe,
        FuseAlertModule,
        MatProgressSpinnerModule,
        MatIconModule,
        MatListModule,
        SearchInputModule,
        ReactiveFormsModule,
        ToastServiceModule,
        MatStepperModule,
        FuseCardModule,
        SmsVerifyModule,
    ],
    exports     : [
        QuickWithdrawalComponent
    ],
    providers: [
        provideNgxMask()
    ]
})
export class QuickWithdrawalModule
{
}
