import { Component, OnInit, ViewChild, ViewEncapsulation, Input, Output, EventEmitter, Inject, Injectable } from '@angular/core';;
import { MAT_SNACK_BAR_DATA, MatSnackBar, MatSnackBarRef, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

@Component({
    selector     : 'toast-service',
    template     : '<div></div>',
    encapsulation: ViewEncapsulation.None,
})

@Injectable({
    providedIn: 'root'
})

export class ToastServiceComponent
{

    @Input() horizontalPosition: MatSnackBarHorizontalPosition = 'end';
    @Input() verticalPosition: MatSnackBarVerticalPosition = 'bottom';


    message: any;

	constructor
	(
        private _snackBar: MatSnackBar
	)
	{
	}


	ngOnInit(): void {

	}


	/**
     * Hata ekranini gosterir
     */ 
    open(message, time?) {
        this.message = message;
        this._snackBar.openFromComponent(ToastMessageComponent, {
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            duration: time ? time : 3000,
            panelClass: 'custom-snackbar',
            data: {
                toastType: this.message.toastType,
                toastDescription: this.message.toastDescription,
            }
        });
    }

}


@Component({
    selector: 'toast-message',
    templateUrl: './toast-service.component.html',
  })
  export class ToastMessageComponent {
    message: any; 


    constructor(
        public snackBarRef: MatSnackBarRef<ToastMessageComponent>,
        @Inject(MAT_SNACK_BAR_DATA) public data: any,
    )
    {
        this.message = data;
    }
  }