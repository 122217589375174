import { Component, OnInit, ViewChild, ViewEncapsulation, Input, Output, EventEmitter, Inject, OnDestroy } from '@angular/core';;
import { FuseAlertType } from "@fuse/components/alert";
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { fuseAnimations } from '@fuse/animations';
import { SharedService } from 'app/layout/common/services/shared.service';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { ToastServiceComponent } from '../toast-service/toast-service.component';
import { DomesticService } from 'app/modules/moneyTransfer/domestic/domestic.service';
import { SmsVerifyComponent } from '../sms-verify/sms-verify.component';
import { QuickWithdrawalService } from './quick-withdrawal.service';
import { IbanPipes } from '../pipes/ibanPipes';
import { environment } from 'environments/environment'
import { SearchInputComponent } from '../search-input/search-input.component';

export interface DialogData {
    bankAccounts: any; // sektörler
}

@Component({
    selector     : 'quick-withdrawal',
    templateUrl  : './quick-withdrawal.component.html',
    encapsulation: ViewEncapsulation.None,
})


export class QuickWithdrawalComponent
{
    public dialogRef;
    @ViewChild(ToastServiceComponent) toastService: ToastServiceComponent;

    bankAccounts: any; // sektörler

    bankAccountsWaiting: boolean = false;

    constructor
    (
        private _dialog: MatDialog,
        private _quickWithdrawalService: QuickWithdrawalService,

    )
    {
       
    }

    openQuickWithdrawalDialog() {

        
        this._quickWithdrawalService.getMyBankAccounts()
        .pipe(
            finalize(() => {
            })
        )
        .subscribe(
            (response) => {

                if (response.status) {
                    

                    if (response.data.rows != false) {
                        this.bankAccountsWaiting = true;

                        this.dialogRef = this._dialog.open(QuickWithdrawalDialog, {
                            disableClose: true,
                            data: {
                                bankAccounts: response.data.rows
                            }
                        },);

                    } else {
                        this.toastService.open({
                            toastType: "error",
                            toastDescription: "Para çekme işlemi yapılabilecek banka hesabı bulunamadı. Lütfen para çekebilmek için Muhasebe menüsünden onaylı bir Transfer Hesabı ekleyiniz."
                        }, 3000);
                    }
                    
                   
                }
            }
        );

    }

    correct() {
        this.dialogRef.componentInstance.correct();
    }

    incorrect(error_description) {
        this.dialogRef.componentInstance.incorrect(error_description);
    }

    reset(expiresTime) {
        this.dialogRef.componentInstance.reset(expiresTime);
    }

}



@Component({
    selector: 'quick-withdrawalDialog.component.html',
    templateUrl: 'quick-withdrawalDialog.component.html',
    animations   : fuseAnimations
})
export class QuickWithdrawalDialog implements OnDestroy
{

    @ViewChild(SmsVerifyComponent) smsVerify: SmsVerifyComponent;
    @ViewChild(ToastServiceComponent) toastService: ToastServiceComponent;
    @ViewChild(SearchInputComponent) searchInputComponent: SearchInputComponent;

    
    auth: any;

    bankAccounts: any; // sektörler
    SelectedBankAccount: any;
    SelectedBankAccountIban: any;

    displayCombineData: any = {
        data1: 'id',
        data2: 'name',
        status: 'bankAccounts',
    }


    firstForm: FormGroup;

    commissionResponse: any = { // komisyon bilgilerini tutar
        total_commission: 0,
        total_amount: 0,
        receiver_amount: 0,
    };

    getApiCommissionStatus = false;
    lastGetCommission: boolean;
    lastAmount: number;

    baseUrl: string;
    session_token: string;

    formStatus:number;

    constructor(
        private _formBuilder: FormBuilder,
        private _dialogRef: MatDialogRef<QuickWithdrawalDialog>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private _quickWithdrawalService: QuickWithdrawalService,
        private _sharedService: SharedService,

    ) {
        this.baseUrl = environment.baseUrl;
        this.session_token = localStorage.getItem("accessToken");
    }



    setSelectedBankAccount(SelectedBankAccount) {
        this.SelectedBankAccount = SelectedBankAccount

        this.SelectedBankAccountIban = SelectedBankAccount.iban;

        this.firstForm.get('iban').setValue(this.SelectedBankAccountIban);
        this.getApiCommissionStatus = true;

    }

    ngOnInit(): void {

        this.auth = JSON.parse(localStorage.getItem('auth'));

        this.bankAccounts = this.data.bankAccounts;

        this.firstForm = this._formBuilder.group({
            amount                  : ['', Validators.required],
            commission_inOut        : ['0', Validators.required],
            iban           : ['', Validators.required],

        });

    }

    ngAfterViewInit(): void {

        setTimeout(() => {
            this.searchInputComponent.focusOnIt();
        }, 300);
    }


    ngOnDestroy(): void
    {
    }


    /**
     * komisyonlari getirir
     */ 
    getCommission(iban?) {
    
        // gonderici tarih bilgisi gerekli formata donusturuluyor
        // if (this.secondOneForm.value.date_of_birth) {
        //     this.secondOneForm.get('date_of_birth').setValue(formatDate(this.secondOneForm.value.date_of_birth, 'yyyy-MM-dd', 'en-US'));
        // }
        iban = this.firstForm.get('iban').value;

        

        if ( this.firstForm.invalid )
        {
            return;
        }

        this.firstForm.disable();

        let payload;
        if (iban) { // en sonda cagrilan komisyon bilgiler
                payload = {
                amount: this.firstForm.value.amount,
                commission_inOut: this.firstForm.value.commission_inOut,
                iban: this.SelectedBankAccountIban,
            }
            this._quickWithdrawalService.getCommission(payload)
                .pipe(
                    finalize(() => {
                        this.firstForm.enable();

                        this.getApiCommissionStatus = false;

                    })
                )
                .subscribe(
                    (response) => {
                        if (response.status) {
                            this.commissionResponse = response.data;
                        } else {
                            this.toastService.open({
                                toastType: "error",
                                toastDescription: response.error.description
                            }, 3000);
                        }
                        
                    }
                );


        } else { // ilk basta gosterilen komisyon bilgileri
            this.lastGetCommission = this.firstForm.get('commission_inOut').value;
            this.lastAmount = this.firstForm.value.amount;

            payload = {
                amount: this.firstForm.value.amount,
                commission_inOut: this.firstForm.value.commission_inOut,
                iban: 1
            }

            this._quickWithdrawalService.getCommission(payload)
                .pipe(
                    finalize(() => {

                        this.firstForm.enable();
                      
                        
                    })
                    )
                    .subscribe(
                        (response) => {
                            if (response.status) {
                            this.getApiCommissionStatus = false;
                            this.commissionResponse = response.data;
                        } else {
                            this.toastService.open({
                                toastType: "error",
                                toastDescription: response.error.description
                            }, 3000);
                        }
                        
                    }
                );
        }

        
    }

    /**
     * Komisyonu içinden/dışından al için gerekli kontroller
     */
    commissionInformationChanges() {
        // NOTE - Her değiştiriğinde formdaki değeri günceller
        if(this.firstForm.get('commission_inOut').value == 0){
            this.firstForm.get('commission_inOut').setValue(1);
        }else {
            this.firstForm.get('commission_inOut').setValue(0);
        }

        // NOTE - Getirilen son komisyon bilgileri ile şuanki bilgileri eşleştirirerek son istek ile aynı ise tekrar istek atmasını engeller
        if(this.lastGetCommission == this.firstForm.get('commission_inOut').value && this.lastAmount == this.firstForm.value.amount){
            this.getApiCommissionStatus = false;
        }else {
            this.getApiCommissionStatus = true;
        }
    }

    /**
     * komisyonlari getir/devam et butonlari goster/gizle
     */
    getGetCommission() {
        // this.smsVerify.openSmsVerificationDialog("5351102203", 180);
        // NOTE - Getirilen son komisyon bilgileri ile şuanki bilgileri eşleştirirerek son istek ile aynı ise tekrar istek atmasını engeller
        if(this.lastGetCommission == this.firstForm.get('commission_inOut').value && this.lastAmount == this.firstForm.value.amount){
            this.getApiCommissionStatus = false;
        }else {
            this.getApiCommissionStatus = true;
        }
    }
    

    /**
    * sms kaydi gonderir
    */ 
   smsVerifySend() {

        if ( this.firstForm.invalid )
        {
            return;
        }
        this.firstForm.disable();

        let payload = {}

    //    if (escape(this.firstForm.value.bank_accounts[0]) == 't' || escape(this.firstForm.value.bank_accounts[0]) == 'T') {
    //    } else {
    //        this.firstForm.get('bank_accounts').setValue("TR" + this.firstForm.value.bank_accounts);
    //    }

        if (this.SelectedBankAccount.company_type == 2) {

            this._quickWithdrawalService.smsVerify({
                amount: this.firstForm.get('amount').value,
                commission_inOut: this.firstForm.get('commission_inOut').value,
                company_name: this.SelectedBankAccount.company_name,
                iban: this.SelectedBankAccountIban,
                mobile_number: this.auth.session.dealer_personnel.dealer.mobile_number,
            })
               .pipe(
                   finalize(() => {
                       this.firstForm.enable();
                   })
               )
               .subscribe(
                   (response) => {
                       if (response.status) {
                           this.formStatus = response?.data?.form_status;
                           this.smsVerify.openSmsVerificationDialog(this.auth.session.dealer_personnel.dealer.mobile_number, 180,this.formStatus);
    
                       } else {
                           this.toastService.open({
                               toastType: "error",
                               toastDescription: response.error.description
                           }, 3000);
    
                       }
    
                   }
               );

        } else {
            
            this._quickWithdrawalService.smsVerify({
                amount: this.firstForm.get('amount').value,
                commission_inOut: this.firstForm.get('commission_inOut').value,
                name: this.SelectedBankAccount.name,
                surname: this.SelectedBankAccount.surname,
                iban: this.SelectedBankAccountIban,
                mobile_number: this.auth.session.dealer_personnel.dealer.mobile_number,
            })
               .pipe(
                   finalize(() => {
                       this.firstForm.enable();
                   })
               )
               .subscribe(
                   (response) => {
                       if (response.status) {
                           this.formStatus = response?.data?.form_status;
                           this.smsVerify.openSmsVerificationDialog(this.auth.session.dealer_personnel.dealer.mobile_number, 180,this.formStatus);
    
                       } else {
                           this.toastService.open({
                               toastType: "error",
                               toastDescription: response.error.description
                           }, 3000);
    
                       }
    
                   }
               );
        }

     


   }

    /**
     * para transfer islemi
     */ 
    store(smsPayload) {

        // islem bitti, yeni islem baslatir
        if (smsPayload.status == "done") {
            this.done();
            return;
        }

        // sms kodunu yeniler
        if (smsPayload.status == "again") {
            this.againSmsVerify();
            return;
        }

        

        // para transfer girdisi olusturur
        if (smsPayload.status == true) {
            
            if (this.SelectedBankAccount.company_type == 2) { 
                this._quickWithdrawalService.store({
                    amount: this.firstForm.get('amount').value,
                    commission_inOut: this.firstForm.get('commission_inOut').value,
                    company_name: this.SelectedBankAccount.company_name,
                    iban: this.SelectedBankAccountIban,
                    mobile_number: this.auth.session.dealer_personnel.dealer.mobile_number,
                    smsInformation: smsPayload
                })
                    .pipe(
                        finalize(() => {
                            // this.openSmsVerificationDialog();
                        })
                    )
                    .subscribe(
                        (response) => {
                            if (response.status) {
                                this._sharedService.emit(`${this.baseUrl}/dealer/moneyTransfer/moneyTransferReceipt/${response.data?.uid}/receipt?session_token=${this.session_token}`);
                                this.smsVerify.correct();
                            } else {
                                this.smsVerify.incorrect(response.error.description);
                            }
                        }
                    );
            } else {
                this._quickWithdrawalService.store({
                    amount: this.firstForm.get('amount').value,
                    commission_inOut: this.firstForm.get('commission_inOut').value,
                    name: this.SelectedBankAccount.name,
                    surname: this.SelectedBankAccount.surname,
                    iban: this.SelectedBankAccountIban,
                    mobile_number: this.auth.session.dealer_personnel.dealer.mobile_number,
                    smsInformation: smsPayload
                })
                .pipe(
                    finalize(() => {
                        // this.openSmsVerificationDialog();
                    })
                )
                .subscribe(
                    (response) => {
                        if (response.status) {
                            this._sharedService.emit(`${this.baseUrl}/dealer/moneyTransfer/moneyTransferReceipt/${response.data?.uid}/receipt?session_token=${this.session_token}`);
                            this.smsVerify.correct();
                        } else {
                            this.smsVerify.incorrect(response.error.description);
                        }
                    }
                );
            }
        }
            
                

    }


    /**
     * sms sifresini yeniler
     */ 
    againSmsVerify() {
        if ( this.firstForm.invalid )
        {
            return;
        }
        this.firstForm.disable();

        this._quickWithdrawalService.smsVerify({
            amount: this.firstForm.get('amount').value,
            commission_inOut: this.firstForm.get('commission_inOut').value,
            name: this.SelectedBankAccount.name,
            surname: this.SelectedBankAccount.surname,
            iban: this.SelectedBankAccountIban,
            mobile_number: this.auth.session.dealer_personnel.dealer.mobile_number,
        })
            .pipe(
                finalize(() => {
                    this.firstForm.enable();
                })
            )
            .subscribe(
                (response) => {
                    if (response.status) {
                        this.smsVerify.reset(60);                  

                    } else {
                        this.toastService.open({
                            toastType: "error",
                            toastDescription: response.error.description
                        }, 3000);

                    }

                }
            );
    }

    /**
     * islem bitti, tamam
     */ 
    done() {
        this._dialogRef.close({
            status: "done",
        });
    }

    cancel() {
        this._dialogRef.close({
            status: false,
        });
    }

}









