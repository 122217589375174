import { Component, OnInit, ViewChild, ViewEncapsulation, ElementRef, Input, Output, EventEmitter, Inject, OnDestroy } from '@angular/core';;
import { FuseAlertType } from "@fuse/components/alert";
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { fuseAnimations } from '@fuse/animations';
import { SharedService } from 'app/layout/common/services/shared.service';
import { ActivatedRoute, Router } from '@angular/router';

export interface DialogData {
    formStatus: number | undefined;
    mobileNumber: string;
    registerType: string;
    expiresTime: any;
    dekontStatus: boolean;
    description: string;
    successDescription: string;
    failRedirectTo: string;
}

@Component({
    selector     : 'sms-verify',
    templateUrl  : './sms-verify.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class SmsVerifyComponent
{
    @Output() result = new EventEmitter<any>();
    @Input() dekontStatus: boolean;
    formStatus: number;
    @Input() description: string;
    @Input() successDescription: string;
    @Input() failRedirectTo: string;

    public dialogRef;

    constructor
    (
        private _dialog: MatDialog,
    )
    {
        console.log({dekontStatus: this.dekontStatus});
        console.log({formStatus: this.formStatus});
    }

    openSmsVerificationDialog(mobileNumber, expiresTime,formStatus?, registerType?) {
        console.log("registerType: ",registerType);
        if(registerType == 'EMAIL') {
            this.description = 'Doğrulama kodunuz bize vermiş olduğunuz e-posta adresinize gönderilmiştir. Başvurunuzu onaylayabilmemiz için doğrulama kodunu aşağıda belirtilen süre bitmeden girmeniz gerekmektedir.';
        } else {
            this.description = 'Doğrulama kodunuz bize vermiş olduğunuz mobil numaranıza gönderilmiştir. Başvurunuzu onaylayabilmemiz için doğrulama kodunu aşağıda belirtilen süre bitmeden girmeniz gerekmektedir.';
        }

        this.dialogRef = this._dialog.open(SmsVerificationDialog, {
            disableClose: true,


            data: {
                mobileNumber: mobileNumber,
                registerType : registerType,
                formStatus:formStatus,
                expiresTime: expiresTime,
                dekontStatus: this.dekontStatus == undefined ? true : false,
                description: this.description,
                successDescription: this.successDescription,
                failRedirectTo: this.failRedirectTo
            }
            
        });
    
        this.dialogRef.afterClosed().subscribe(result => {
            this.result.emit(result);
        });

        this.dialogRef.componentInstance.submitClicked.subscribe(result => {
            this.result.emit(result);
        });


    }

    correct() {
        this.dialogRef.componentInstance.correct();
    }

    incorrect(error_description) {
        this.dialogRef.componentInstance.incorrect(error_description);
    }

    reset(expiresTime) {
        this.dialogRef.componentInstance.reset(expiresTime);
    }

}



@Component({
    selector: 'smsVerificationDialog.component.html',
    templateUrl: 'smsVerificationDialog.component.html',
    animations   : fuseAnimations
})
export class SmsVerificationDialog implements OnDestroy
{
    @ViewChild('inputRef') inputRef: ElementRef;
    @ViewChild("smsVerifyNgForm") smsVerifyNgForm: NgForm;
    @Output() submitClicked = new EventEmitter<any>();


    alert: { type: FuseAlertType; message: string } = {
        type: "success",
        message: "",
    };

    showAlert: boolean = false; // aler goster/me
    code: string = ""; // sms kodu
    smsVerifyForm: FormGroup; // sms formu
    smsVerifyFormAgain: FormGroup; // sms yenileme formu
    status: boolean = false; // islem sonucu
    expiresTime: number = 180; // kalan sure
    expiresTimeStatus: boolean = false; // sure doldu mu
    interval: any;
    dekontUrl: string;
    formStatus: number;
    dekontStatus: boolean;
    failRedirectTo: string;
    failRedirectToTime: number;

    constructor(
        private _formBuilder: FormBuilder,
        private _dialogRef: MatDialogRef<SmsVerificationDialog>,
        private _sharedService: SharedService,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private _router: Router


    ) {
        this.expiresTime = data.expiresTime;
        this.failRedirectTo = data.failRedirectTo;
        this._sharedService.event$.subscribe(response => {
            console.log(response);
            this.dekontUrl = response;
        });
        this.formStatus = data.formStatus;
    }


    ngOnInit(): void {
        this.smsVerifyForm = this._formBuilder.group({
            code: ["", [Validators.required, Validators.min(100), Validators.max(999999)]],
        });

        this.smsVerifyFormAgain = this._formBuilder.group({
        });
        this.countdown();

        console.log("this.formStatus",this.formStatus)
    }

    ngAfterViewInit(){
        setTimeout(()=>{
            this.inputRef.nativeElement.focus();
        },200);
    }

    onEnter(){
        console.log('tetiklendi');
        const value: string = this.inputRef.nativeElement.value;
        console.log(value.length);
        if(value.length >= 3){
            this.verifySubmit();
        }
       
    }

    ngOnDestroy(): void
    {
        clearInterval(this.interval);
    }


    /**
     * sms kodunu gonderir
     */ 
    verifySubmit() {
        this.submitClicked.emit({
            status: true,
            sms_code: this.smsVerifyForm.value.code
        });
        this.smsVerifyForm.disable();
    }


    /**
     * sms kodu yenilenir
     */ 
    again() {
        this.submitClicked.emit({
            status: "again",
        });
        this.smsVerifyFormAgain.disable();
    }

    
    /**
     * islem bitti, tamam
     */ 
    done() {
        this._dialogRef.close({
            status: "done",
        });
        clearInterval(this.interval);
    }


    /**
     * dekont
     */ 
    dekont() {
        return this.dekontUrl;
    }


    /**
     * islem iptal
     */ 
    cancel() {
        this._dialogRef.close({
            status: false,
        });
    }


    /**
     * islem basarili
     */ 
    correct() {
        this._dialogRef.close({
            status: "done",
        });
        this.status = true;
        this.smsVerifyForm.enable();
        clearInterval(this.interval);

    }


    /**
     * islem basarisiz
     */ 
    incorrect(error_description) {
        this.showAlert = true;
        this.alert = {
            type   : 'error',
            message: error_description
        };
        this.smsVerifyNgForm.resetForm();
        this.smsVerifyForm.enable();
        this.inputRef.nativeElement.focus();
    }


    /**
     * sms kodu yenilendi islem sonucu
     */
    reset(expiresTime) {
        this.expiresTimeStatus = false;
        this.expiresTime = expiresTime;
        this.showAlert = true;
        this.alert = {
            type   : 'success',
            message: "Yeni sms gönderildi."
        };
        this.smsVerifyFormAgain.enable();
        this.countdown();
    }


    /**
     * geri sayim baslatir
     */
    countdown() {
        this.interval = setInterval(() => {
            this.expiresTime = this.expiresTime - 1;
            if (this.expiresTime <= 0) {
                this.expiresTimeStatus = true;
                if (this.status == false && this.expiresTimeStatus == true && this.failRedirectTo) {
                    this.failRedirectToTime = 3;
                    let failRedirectToInterval = setInterval(()=>{
                        this.failRedirectToTime = this.failRedirectToTime - 1;
                        if (this.failRedirectToTime <= 0) {
                            clearInterval(failRedirectToInterval);
                            //SEND OTP AGAIN
                            // console.log("SEND OTP AGAIN");
                            this._router.navigateByUrl(this.failRedirectTo);
                            this._dialogRef.close({
                                status: false,
                            });
                        } 
                    }, 1000);

                }
                clearInterval(this.interval);
            }
        }, 1000);
    }
    

}









