import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, filter, map, Observable, of, switchMap, take, tap, throwError } from 'rxjs';
import { HttpService } from 'app/core/http/http.service';

@Injectable({
    providedIn: 'root'
})
export class QuickWithdrawalService
{

    /**
     * Constructor
     */
    constructor(
        private _httpService: HttpService,
    )
    {
    }


    /**
     * Banka hesaplarini getirir
     */
    getMyBankAccounts()
    {
        return this._httpService.get(`/dealer/accounting/myBankAccounts/activeAccounts`);
        
    }

    /**
     * Yurt ici para çekme komisyonlarini getirir
     */ 
    getCommission(payload)
    {
        return this._httpService.post(`/dealer/domesticMoneyTransfer/withdrawMoney/getCommission`, payload);
    }


    /**
     * Yurt ici para gonder islemi
     */ 
    store(payload)
    {
        return this._httpService.post(`/dealer/domesticMoneyTransfer/withdrawMoney`, payload);
    }
    

    /**
     * Yurt ici para gonder islemi
     */ 
    smsVerify(payload)
    {
        return this._httpService.post(`/dealer/domesticMoneyTransfer/smsVerify/withdrawMoney`, payload);
    }


    /**
     * dekontu getirir
     */ 
    getDekont(payload) {
        return this._httpService.get(`/dealer/moneyTransfer/moneyTransferReceipt/${payload}/receipt`);
    }
}