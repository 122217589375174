import { Injectable } from '@angular/core';
import { HttpService } from 'app/core/http/http.service';
import { map, Observable, ReplaySubject, switchMap, take, tap, Subject, takeUntil } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AnnouncementsService
{

    private _announcements: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);


    /**
     * Constructor
     */
    constructor(
        private _httpService: HttpService
    )
    {
    }


    get announcements$(): Observable<any[]>
    {
        return this._announcements.asObservable();
    }


    getAll(): Observable<any[]>
    {
        return this._httpService.get('/dealer/auth/announcements/getAnnouncements').pipe(
            tap((announcements) => {
                this._announcements.next(announcements);
            })
        );
    }


    /**
     * Duyuruyu okudum diye apiye istek gonderir -tek
     */
    oneClose(payload)
    {
        return this._httpService.post(`/dealer/auth/announcements/${payload?.id}/oneClose`, payload);
    }


    /**
     * Duyuruyu okudum diye apiye istek gonderir -hepsi
     */
    allClose(payload)
    {
        return this._httpService.post(`/dealer/auth/announcements/${payload?.id}/allClose`, payload);
    }


    /**
     * Butun duyurulari getirir
     */
    getAnnouncements()
    {
        return this._httpService.get(`/dealer/auth/announcements/getAnnouncements`);
    }


}
