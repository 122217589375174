import { Component, OnDestroy, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { Navigation } from 'app/core/navigation/navigation.types';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { User } from 'app/core/user/user.types';
import { UserService } from 'app/core/user/user.service';
import { SocketIOService } from 'app/core/socket/socket-io.service';
import Echo from 'laravel-echo';
import { environment } from 'environments/environment';
import { UpgradeComponent } from 'app/layout/common/upgrade/upgrade.component';
import { CollectiveQueryComponent } from 'app/layout/common/collective-query/collective-query.component';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DeveloperService } from 'app/modules/developer/developer.service';
import { NotificationsComponent } from 'app/layout/common/notifications/notifications.component';
import { AnnouncementsComponent } from 'app/layout/common/announcements/announcements.component';
import { QuickWithdrawalComponent } from 'app/layout/common/quick-withdrawal/quick-withdrawal.component';
import { SwPush } from "@angular/service-worker";
import { PushNotification } from 'app/core/pushNotification/pushNotification.service';
import { ToastServiceComponent } from 'app/layout/common/toast-service/toast-service.component';


@Component({
    selector     : 'classy-layout',
    templateUrl  : './classy.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ClassyLayoutComponent implements OnInit, OnDestroy
{
    @ViewChild(NotificationsComponent) notificationsComponent: NotificationsComponent;
    @ViewChild(AnnouncementsComponent) announcementsComponent: AnnouncementsComponent;
    @ViewChild(UpgradeComponent) upgradeComponent: UpgradeComponent;
    @ViewChild(CollectiveQueryComponent) collectiveQueryComponent: CollectiveQueryComponent;
    @ViewChild(QuickWithdrawalComponent) quickWithdrawal: QuickWithdrawalComponent;
    

    isScreenSmall: boolean;
    navigation: Navigation;
    user: User;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    balance: any = "0";
    main_dealer_balance: any = "0";
    cashback_balance: any = "0";
    main_dealer_debt: any = "0";
    configForm: FormGroup;
    basketCount: any;

    contactPhone : string = environment.contactPhone;
    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _navigationService: NavigationService,
        private _userService: UserService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseConfirmationService: FuseConfirmationService,
        private _formBuilder: FormBuilder,
        private _developerService: DeveloperService,
        private swPush: SwPush,
        private _pushNotification: PushNotification,
        private toastService: ToastServiceComponent,

        // private _socketIOService: SocketIOService

    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number
    {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {

        this.getVersion();
        this.getBalance();
        
        // settimeout yapmamizin nedeni kullanici bildirim gondermek istiyor sorusuna cevap vermis olmasi lazim,
        // bu yuzden 2dk gibi bi zaman dilimi konuldu,
        // eger yine bu zaman diliminde cevap vermemis ise sayfayi yenilediginde istek atacaktir
            this.pushNotificationSendInfo();

        // yeni guncelleme icerigi
        this.configForm = this._formBuilder.group({
            title      : 'Güncelleme',
            message    : 'Yeni güncelleme var, şimdi güncellemek ister misiniz?',
            icon       : this._formBuilder.group({
                show : true,
                name : 'heroicons_outline:exclamation',
                color: 'primary'
            }),
            actions    : this._formBuilder.group({
                confirm: this._formBuilder.group({
                    show : true,
                    label: 'Evet',
                    color: 'primary'
                }),
                cancel : this._formBuilder.group({
                    show : true,
                    label: 'Şimdi Değil'
                })
            }),
            dismissible: true
        });



        // Subscribe to navigation data
        this._navigationService.navigation$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((navigation: Navigation) => {
                this.navigation = navigation;
                console.log(this.navigation);
            });

        // Subscribe to the user service
        this._userService.user$
            .pipe((takeUntil(this._unsubscribeAll)))
            .subscribe((user: User) => {
                this.user = user;
            });

        /*
        this.user = {
            "id": "cfaad35d-07a3-4447-a6c3-d8c3d54fd5df",
            "name": "Brian Hughes",
            "email": "hughes.brian@company.com",
            "avatar": "assets/images/avatars/brian-hughes.jpg",
            "status": "online"
        };
        */

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({matchingAliases}) => {

                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    
    /**
     * socketdeki balance kanalini dinler
     */ 
    getBalance() {

        // this._socketIOService.sendMessage("asdfasdfa");

        /*
        this._socketIOService.getMessage('balance').subscribe((msg) => {
            console.log(msg);
        });
        */

        this.balance = localStorage.getItem('balance');
        this.main_dealer_balance = localStorage.getItem('main_dealer_balance');
        this.cashback_balance = localStorage.getItem('cashback_balance');
        
        this.main_dealer_debt = localStorage.getItem('main_dealer_debt');

        this.websockets();
        /*
        this._socketIOService.balance.subscribe((response) => {
            console.log(response);
            this.balance = response;
        })
        */
    }


    /**
     * websocket dinlemeye baslar
     */ 
    websockets() {
        console.log("websockets working");
        const echo = new Echo({
            broadcaster: 'pusher',
            cluster: 'mt1',
            key: 'f32d392ba1c2506e41e2',
            forceTLS: true,
            enabledTransports: ['ws', 'wss'],
        });


        // bakiyeyi dinler
        echo.channel(`balance.${localStorage.getItem('accessToken')}`)
        .listen('DealerBalanceEvent', (response: any) => {
            if (response?.data?.balance == 0 || response?.data?.balance) {
                
                console.log("DealerBalanceEvent", response);

                let balance = localStorage.getItem('balance');
                this.balance = response?.data?.balance;
                localStorage.setItem('balance', this.balance);

                if (response?.data?.main_dealer_balance == 0 || response?.data?.main_dealer_balance) {
                    this.main_dealer_balance = response?.data?.main_dealer_balance;
                    localStorage.setItem('main_dealer_balance', this.main_dealer_balance);
                }


                if (response?.data?.main_dealer_debt == 0 || response?.data?.main_dealer_debt) {
                    this.main_dealer_debt = response?.data?.main_dealer_debt;
                    localStorage.setItem('main_dealer_debt', this.main_dealer_debt);
                }
                
                if (response?.data?.cashback_balance == 0 || response?.data?.cashback_balance) {
                    this.cashback_balance = response?.data?.cashback_balance;
                    localStorage.setItem('cashback_balance', this.cashback_balance);
                }


                /*
                Bakiye yuklendikden sonra ses cikarma --burdaki sorun komisyonlar sonradan eklendigi icin,
                sanki bakiye yuklenmis gibi ses cikiyor
                if (parseFloat(balance) < parseFloat(this.balance)) {
                    let audio = new Audio();
                    audio.src = `${environment.storageUrl}/common/audios/cashCounter/cashCounter.mp3`;
                    setTimeout(()=>{
                        audio.load();
                        audio.play();  
                    }, 50);
                }
                */


            }
        })


        // guncellemeleri dinler
        echo.channel(`upgrade`)
        .listen('UpgradeDealerEvent', (response: any) => {
            if (response?.data?.content) {

                let audio = new Audio();
                audio.src = `${environment.storageUrl}/common/audios/upgrade/upgrade.mp3`;
                setTimeout(()=>{
                    audio.load();
                    audio.play();
                }, 50);
                this.upgradeComponent.openFire();
                // localStorage.setItem("logVersion", response?.data?.version);
                this.openConfirmationDialogForUpgrade();
            }
        })


        // duyurulari dinler
        echo.channel(`announcement`)
        .listen('AnnouncementEvent', (response: any) => {
            if (response?.data?.title && response?.data?.content) {

                let audio = new Audio();
                audio.src = `${environment.storageUrl}/common/audios/newAnnouncement/newAnnouncement.mp3`;
                setTimeout(()=>{
                    audio.load();
                    audio.play();  
                }, 50);
                this.announcementsComponent.setAnnouncements({
                    title: response?.data?.title,
                    content: response?.data?.content,
                    id: response?.data?.id
                }, true);
                this.announcementsComponent.getAllAnnouncements();


            }
        })


        // bildirimleri dinler
        echo.channel(`notification.${localStorage.getItem('accessToken')}`)
        .listen('NotificationEvent', (response: any) => {

            let audio = new Audio();
            audio.src = `${environment.storageUrl}/common/audios/newNotification/newNotification.mp3`;
            setTimeout(()=>{
                audio.load();
                audio.play();
            }, 50);

            if (response?.data?.type == 1) {
                this.toastService.open({
                    toastType: "info",
                    toastDescription: "Gönderdiğiniz faturalar sonuçlandı, bildilerimlerinizi kontrol ediniz."
                }, 5000);
            } else {
                this.toastService.open({
                    toastType: "info",
                    toastDescription: "Yeni bildileriminiz var."
                }, 5000);
            }
            this.notificationsComponent.getAllNotifications();
            this.notificationsComponent.setNotificationToolTip();


        });

        echo.channel(`vp_payments`)
            .listen('VirtualPosPaymentEvent', (response: any) => {
                console.log(response?.data);
                this.toastService.open({
                    toastType: "info",
                    toastDescription: JSON.stringify(response)
                }, 5000);
            });

        console.log("websockets listening..");
    }


    /**
     * push-notification icin bilgileri gonderir -kayit yapar
     */
    pushNotificationSendInfo() {
        this.swPush.requestSubscription({
            serverPublicKey: environment.swPublicKey
        })
        .then(sub => {
            this._pushNotification.addPushSubscriber({data:sub}).subscribe((response) => {
                console.log(response);
            });
        })
        .catch(err => console.log("Bildirim yükleme hatası"));

    }



    openWithdrawalDialog() {
        this.quickWithdrawal.openQuickWithdrawalDialog();
    }


    /**
     * Yeni guncelleme dialogu acar
     */ 
    openConfirmationDialogForUpgrade(): void
    {
        const dialogRef = this._fuseConfirmationService.open(this.configForm.value);

        dialogRef.afterClosed().subscribe((result) => {

            if ( result === 'confirmed' ) {
                localStorage.removeItem("upgradeCount");
                localStorage.removeItem("logVersion");
                window.location.reload();             
            }

        });
    }


    /**
     * İlk giris yapildiginda veya yenileme yapildiginda versiyonu ogrenmek icin istek atar
     */
    getVersion(): void
    {
        this._developerService.getVersion()
            .subscribe(
                (response) => {
                    if (response.status) {
                        let logVersion = localStorage.getItem("logVersion");
                        if (logVersion) {
                            if (response.data.version != logVersion) {
                                this.upgradeComponent.openFire();
                            } else {
                            }
                        }

                    } else {
                        
                    }
                    
                }
            );
    }




    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void
    {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

        if ( navigation )
        {
            // Toggle the opened status
            navigation.toggle();
        }
    }
}
