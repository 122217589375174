import { NgModule } from '@angular/core';
import { SharedModule } from 'app/shared/shared.module';
import { SmsVerifyComponent, SmsVerificationDialog } from './sms-verify.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { FuseAlertModule } from '@fuse/components/alert';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import {MatListModule} from "@angular/material/list";


@NgModule({
    declarations: [
        SmsVerifyComponent,
        SmsVerificationDialog
    ],
    imports: [
        SharedModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatDialogModule,
        NgxMaskDirective,
        NgxMaskPipe,
        FuseAlertModule,
        MatProgressSpinnerModule,
        MatIconModule,
        MatListModule
    ],
    exports     : [
        SmsVerifyComponent
    ],
    providers: [
        provideNgxMask()
    ]
})
export class SmsVerifyModule
{
}
