import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from 'app/core/http/http.service';

@Injectable({
    providedIn: 'root'
})
export class DeveloperService
{

    /**
     * Constructor
     */
    constructor(
        private _httpService: HttpService,
    )
    {
    }


    /**
     * yeni kayit olusturur
     */
    store(payload)
    {
        return this._httpService.post('/dealer/it/changelogs', payload);
    }


    /**
     * en guncel versiyonu ogrenir -burasini classy cagiriyor
     */
    getVersion()
    {
        return this._httpService.get('/dealer/it/changelogs/getVersion');
    }


    requestSubscription() {
        return this._httpService.get('/dealer/it/changelogs/getVersion');
        
    }
}
