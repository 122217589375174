<div class="w-100">

	<div *ngIf="status == true">
		<div class="flex flex-col justify-center items-center">
			<mat-icon class="icon-size-24 rounded-full fuse-border-color-1 fuse-text-color" svgIcon="heroicons_outline:check"></mat-icon>
			<span class="text-2xl text-secondary my-4">İşlem Başarılı</span>
		</div>

		<div *ngIf="data?.successDescription" class="flex justify-center my-4">
		    <span class="text-secondary">{{ data?.successDescription }}</span>
		</div>
		<div *ngIf="data?.dekontStatus&&data?.formStatus==1" class="flex justify-center my-4">
		    <span class="text-secondary">

				   <h1 style="color: red"><b>İşleminiz Onaylanması İçin Alttaki Adımların Uygulanması Gerekmektedir.</b></h1>
				<br>
					  <span mat-line class="demo-2 pt-2"> "Dekontu Göster" Butonu ile Dekontu Açılır </span>
					  <mat-divider [inset]="true"></mat-divider>
				   <mat-divider></mat-divider>
					  <span mat-line class="demo-2"> Dekont Üzerindeki QR Kod ile Açılan Sayfadaki Yönergeleri Uygulanır </span>
				<br>
				<h1 style="color: red"><b>Aksi Halde İşlem Tamamlanmayacaktır.</b></h1>
			</span>
		</div>

		<!-- dekont varsa -->
		<div *ngIf="data?.dekontStatus" class="flex">
			<a class="fuse-mat-button-large mt-3 px-2 w-2/3 mr-2 font-bold text-2xl" mat-flat-button color="secondary" href="{{ dekont() }}" target="_blank">Dekontu Göster</a>

			<button class="fuse-mat-button-large mt-3 px-2 w-1/3 font-bold text-2xl" mat-flat-button color="primary" (click)="done()">Tamam</button>
		</div>
		
		<div *ngIf="!data?.dekontStatus" class="flex">
			<button class="fuse-mat-button-large w-full font-bold text-2xl" mat-flat-button color="primary" (click)="done()">Tamam</button>
		</div>

	</div>


	<div *ngIf="status == false && expiresTimeStatus == false">
		<div class="flex flex-row">
			<div class="basis-3/4">
				<div class="text-2xl font-extrabold tracking-tight leading-tight text-right">DOĞRULAMA KODU</div>
			</div>
			<div class="basis-1/4 text-right">
				<button
					type="button"
					[color]="'warn'"
					mat-icon-button
					matTooltip="Kapat"
					(click)="cancel()">
					<mat-icon [svgIcon]="'mat_solid:close'"></mat-icon>
				</button>
			</div>
		</div>

		{{registerType}}
		<div class="mt-0.5 font-medium text-center" *ngIf="registerType == 'MOBILE_NUMBER'">
		    <span class="font-bold">{{ data?.mobileNumber }}</span>
		    <span class="text-secondary"> numaralı cep telefonunuza gelen SMS kodunu aşağıdaki kutuya giriniz.</span>
		</div>

		<div class="mt-0.5 font-medium text-center" *ngIf="registerType == 'EMAIL'">
			<span class="font-bold" *ngIf="registerType == 'EMAIL'">{{ data?.email }}</span>
			<span class="text-secondary" *ngIf="registerType == 'EMAIL'"> e-postanıza gelen doğrulama kodunu aşağıdaki kutuya giriniz.</span>
		</div>


		<div *ngIf="data?.description" class="mt-4 text-sm text-center">
		    <span class="text-secondary">{{ data?.description }}</span>
		</div>

		<!-- Alert -->
		<fuse-alert class="mt-8 -mb-4" *ngIf="showAlert" [appearance]="'outline'" [showIcon]="false" [type]="alert.type" [@shake]="alert.type === 'error'">
		    {{ alert.message }}
		</fuse-alert>

		<form class="mt-8" [formGroup]="smsVerifyForm" #smsVerifyNgForm="ngForm">

		    <mat-form-field id="labelInput" class="w-full">
		        <mat-label class="text-secondary mt-2">Doğrulama Kodu</mat-label>
		        <!--
		        <input type="number" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" type = "number" maxlength = "6" class="text-3xl w-full border-none" id="code" matInput [formControlName]="'code'">
		    	-->
		        <input #inputRef (keyup.enter)="onEnter()" type="text" onKeyDown="if(this.value.length==6 && event.keyCode!=8) return false;" class="text-secondary text-center text-5xl w-full border-none caret-transparent font-extralight" id="code" [formControlName]="'code'" matInput>
		        <mat-error *ngIf="smsVerifyForm.get('code').hasError('required')">
		            İkinci dogrulama alani zorunludur.
		        </mat-error>
		    </mat-form-field>

		    <div class="w-1/2 inline-flex">
		        Kalan Süre: {{ expiresTime }} sn.
		    </div>

		    <!-- Submit button -->
		    <div class="flex">

				<button class="fuse-mat-button-large w-2/3 ml-2 mt-3 order-last" mat-flat-button [color]="'primary'" [disabled]="smsVerifyForm.invalid || smsVerifyForm.disabled" (click)="verifySubmit()">
		            <span *ngIf="!smsVerifyForm.disabled">
						Devam Et
					</span>
					<mat-progress-spinner
						*ngIf="smsVerifyForm.disabled"
						[diameter]="24"
						[mode]="'indeterminate'">
					</mat-progress-spinner>
		        </button>

		        <button 
					[disabled]="smsVerifyForm.disabled"
		        	class="fuse-mat-button-large mt-3 px-2 w-1/3 mr-2 order-first"
		        	mat-flat-button 
		        	color="warn" 
		        	(click)="cancel()">
		        	<span *ngIf="!smsVerifyForm.disabled">
						İptal Et
					</span>
					<mat-progress-spinner
						*ngIf="smsVerifyForm.disabled"
						[diameter]="24"
						[mode]="'indeterminate'">
					</mat-progress-spinner>
		        </button>

		    </div>
		</form>
	</div>


	<div *ngIf="status == false && expiresTimeStatus == true">
		<div class="flex flex-row" *ngIf="!data.failRedirectTo">
			<div class="basis-3/4">
				<div class="text-2xl font-extrabold tracking-tight leading-tight text-right">DOĞRULAMA KODU</div>
			</div>
			<div class="basis-1/4 text-right">
				<button
					type="button"
					[color]="'warn'"
					mat-icon-button
					matTooltip="Kapat"
					(click)="cancel()">
					<mat-icon [svgIcon]="'mat_solid:close'"></mat-icon>
				</button>
			</div>
		</div>

		<div *ngIf="!data.failRedirectTo" class="mt-0.5 font-medium text-center">
		    <span class="font-bold">{{ data?.mobileNumber }}</span>
		</div>

		<form *ngIf="!data.failRedirectTo" class="mt-8" [formGroup]="smsVerifyFormAgain" #smsVerifyNgForm="ngForm">
			Süreniz sona erdi
			
	        <button class="fuse-mat-button-large w-2/3 ml-2 mt-3" mat-flat-button [color]="'primary'" [disabled]="smsVerifyFormAgain.invalid || smsVerifyFormAgain.disabled" (click)="again()">
	            <span *ngIf="!smsVerifyFormAgain.disabled">
               		Kodu tekrar gönder
				</span>
				<mat-progress-spinner
					*ngIf="smsVerifyFormAgain.disabled"
					[diameter]="24"
					[mode]="'indeterminate'">
				</mat-progress-spinner>
	        </button>

		</form>

		<div *ngIf="data.failRedirectTo" class="text-2xl font-extrabold tracking-tight leading-tight text-center">İŞLEM BAŞARISIZ</div>
		<form *ngIf="data.failRedirectTo" class="mt-8 flex items-center flex-col justify-between" [formGroup]="smsVerifyFormAgain" #smsVerifyNgForm="ngForm">
			Süreniz sona erdi. {{ failRedirectToTime }} sn. sonra giriş sayfasına yönlendiriliyorsunuz.
		</form>

	</div>

</div>