import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({name: 'money'})
export class MoneyPipes extends DecimalPipe implements PipeTransform {
	
    transform(value: any): any {

	    let formatedByCurrencyPipe = super.transform(value, '1.2-2');
    	let d = formatedByCurrencyPipe.replace(/\./gi, 'x');
        d = d.replace(/,/gi, '.');
        d = d.replace(/x/gi, ',');
        return d;

    }
}

/**

43,612.22 to 43.612,22

**/