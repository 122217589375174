import { Injectable } from '@angular/core';
import { map, Observable, ReplaySubject, tap } from 'rxjs';
import { HttpService } from 'app/core/http/http.service';
import { FormBuilder, FormGroup, NgForm, Validators } from "@angular/forms";
import { FuseConfirmationService } from "@fuse/services/confirmation";
import { Router, ActivatedRoute } from "@angular/router";


@Injectable({
    providedIn: 'root'
})
export class FailSessionService
{
    private _failSession: ReplaySubject<any> = new ReplaySubject<any>(1);
    FailLoginConfigForm: FormGroup; // dialog

    /**
     * Constructor
     */
    constructor(
        private _httpService: HttpService,
        private _formBuilder: FormBuilder,
        private _fuseConfirmationService: FuseConfirmationService,
        private _router: Router,
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for failSession
     *
     * @param value
     */
    set failSession(value: any)
    {
        // Store the value
        this._failSession.next(value);
    }

    get failSession$(): Observable<any>
    {
        return this._failSession.asObservable();
    }

    //hatalı girişleri getir.
    get(): Observable<any>
    {
        return this._httpService.get(`/dealer/auth/sessions/lastFailSession`).pipe(
            tap((response) => {
                if ( response.status ) {
                    if(response.data){
                        this.FailLoginConfigForm = this._formBuilder.group({
                            title: "Bilgilendirme",
                            message:
                                "Hesabına " +
                                response.data.lfl_date +
                                " tarihinde " +
                                response.data.ip +
                                " ip numaralı " +
                                response.data.device_detail +
                                " bilgilerine sahip cihaz ve platform üzerinden hatalı giriş denemesi olmuştur.",
                
                            icon: this._formBuilder.group({
                                show: true,
                                name: "heroicons_outline:exclamation",
                                color: "warning",
                            }),
                            actions: this._formBuilder.group({
                                confirm: this._formBuilder.group({
                                    show: true,
                                    label: "Tamam",
                                    color: "primary",
                                }),
                                cancel: this._formBuilder.group({
                                    show: true,
                                    color: "primary",
                                    label: "Görüntüle",
                                }),
                            }),
                            dismissible: false,
                        });
                        this.openDialog(response.data.dealerPersonnel);
                    }
                    this._failSession.next(response.data);
                } else {
                    this._failSession.next(null);
                }
            })
        );
    }

    /**
    * Yanlis giris dialogu
    */ 
    openDialog(dealerPersonnel): void {
    
        const dialogRef = this._fuseConfirmationService.open(
            this.FailLoginConfigForm.value
        );

        dialogRef.afterClosed().subscribe((result) => {
            if (result == "confirmed") {
                this.update(dealerPersonnel).subscribe();
            } else {
                this.update(dealerPersonnel).subscribe();
                this._router.navigate(["settings/security/failed-logins"]);
            }
        });
    }

    //okuduğuna dair bilgi gönderiyor
    update(dealerPersonnel): Observable<any>
    {
        return this._httpService.post(`/dealer/auth/lastFailLogin`, {id:    dealerPersonnel}).pipe(
            tap((response) => {
                console.log(response);
                if ( response.status ) {
                    this._failSession.next(response.data);
                } else {
                    this._failSession.next(null);
                }
            })
        );
    }
}
