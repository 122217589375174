import { Pipe } from "@angular/core";

@Pipe({
  name: "phone"
})
export class PhonePipe {
    transform(rawNum) {
        if (rawNum) {
            rawNum = rawNum.toString();
            rawNum = rawNum.charAt(0) != 0 ? "0" + rawNum : "" + rawNum;
            let newStr = "";
            for (let i = 0; i < rawNum.length; i++) {
                if (i >= 0 && i <= 3) {
                    if (i == 3) {
                        newStr = newStr + rawNum[i] + " ";
                    } else {
                        newStr = newStr + rawNum[i];
                    }
                } else if (i > 3 && i <= 6) {
                    if (i == 6) {
                        newStr = newStr + rawNum[i] + " ";
                    } else {
                        newStr = newStr + rawNum[i];
                    }
                } else if (i >= 7 && i <= 8) {
                    if (i == 8) {
                        newStr = newStr + rawNum[i] + " ";
                    } else {
                        newStr = newStr + rawNum[i];
                    }
                } else {
                    newStr = newStr + rawNum[i];
                }
            }
            return newStr;
        }
        return;

    }
}