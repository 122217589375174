import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from 'app/core/http/http.service';
import { Subject, takeUntil } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SharedService
{
	private source = new Subject<any>();
    public event$ = this.source.asObservable();
    emit(eventData: any): void {
        this.source.next(eventData);
    }
    
}
