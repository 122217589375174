/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';
import { environment } from 'environments/environment';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id   : 'dashboard',
        title: 'Anasayfa',
        type : 'basic',
        icon : 'heroicons_outline:home',
        link : '/dashboard',
        kycLevel : '0',
    },
    {
        id      : 'myAccounts',
        title   : 'Hesaplar',
        type    : 'collapsable',
        icon    : 'heroicons_outline:user',
        link    : '/myAccounts',
        kycLevel : '0',
        children: [
            {
                id        : 'apps.help-center.home',
                title     : 'Hesaplarım',
                type      : 'basic',
                link      : '/myAccounts/accounts',
                kycLevel : '0',
            },
            {
                id   : 'apps.help-center.faqs',
                title: 'Bakiye Yükle',
                type : 'basic',
                link : '/myAccounts/paymentNotifications',
                kycLevel : '0',
            }
        ]
    },
    {
        id      : 'invoicePaymentsGroup',
        title   : 'Ödemeler',
        type    : 'collapsable',
        icon    : 'mat_outline:receipt_long',
        kycLevel : '0',
        children: [
            {
                id      : 'navigation-features.level.0',
                title   : 'Fatura',
                icon    : 'heroicons_outline:view-list',
                type    : 'collapsable',
                kycLevel : '0',
                children: [
                    {
                        id      : 'navigation-features.level.0.1',
                        title   : 'Ödeme',
                        type    : 'basic',
                        link    : 'payments/invoice/payment',
                        kycLevel : '0',
                    },
                    {
                        id      : 'navigation-features.level.0.1',
                        title   : 'Sepet',
                        type    : 'basic',
                        kycLevel : '0',
                        link    : 'payments/invoice/basket'
                    },
                    {
                        id      : 'navigation-features.level.0.1',
                        title   : 'Hesap Hareketleri',
                        kycLevel : '0',
                        type    : 'basic',
                        link    : 'payments/invoice/movements'
                    }
                ]
            },
            {
                id      : 'navigation-features.level.0',
                title   : 'SGK - Bağkur',
                icon    : 'heroicons_outline:view-list',
                type    : 'collapsable',
                kycLevel : '0',
                children: [
                    {
                        id      : 'navigation-features.level.0.1',
                        title   : 'Ödeme',
                        type    : 'basic',
                        link    : 'payments/socialInsurance/payment',
                        kycLevel : '0',
                    },
                    {
                        id      : 'navigation-features.level.0.1',
                        title   : 'Hesap Hareketleri',
                        type    : 'basic',
                        kycLevel : '0',
                        link    : 'payments/socialInsurance/movements'
                    }
                ]
            },
            {
                id      : 'navigation-features.level.0',
                title   : 'Cep Lira',
                icon    : 'heroicons_outline:view-list',
                type    : 'collapsable',
                kycLevel : '0',
                children: [
                    {
                        id      : 'navigation-features.level.0.1',
                        title   : 'Ödeme',
                        type    : 'basic',
                        link    : 'payments/mobileCredit/payment',
                        kycLevel : '0',
                    },
                    {
                        id   : 'apps.help-center.faqs',
                        title: 'Hesap Hareketleri',
                        type : 'basic',
                        link : 'payments/mobileCredit/movements',
                        kycLevel : '0',
                    }
                ]
            },
            {
                id      : 'navigation-features.level.0',
                title   : 'Kayıtlı Müşteriler',
                icon    : 'heroicons_outline:view-list',
                kycLevel : '0',
                type    : 'basic',
                link    : '/payments/registeredCustomers'
            },
        ]
    },
    {
        id      : 'moneyTransfer',
        title   : 'Para Transferi',
        type    : 'collapsable',
        icon    : 'heroicons_outline:cash',
        kycLevel : '1',
        children: [
            {
                id   : 'apps.help-center.faqs',
                title: 'Yurt İçi Para Transferi',
                type : 'collapsable',
                kycLevel : '1',
                children: [
                    {
                        id   : 'apps.help-center.faqs',
                        title: "IBAN'a Transfer",
                        type : 'basic',
                        link : '/moneyTransfer/domestic/sendMoney',
                        kycLevel : '1',
                    },
                    // {
                    //     id   : 'apps.help-center.faqs',
                    //     title: 'Kredi Kartına Transfer',
                    //     type : 'basic',
                    //     link : '/moneyTransfer/creditCard/sendMoney',
                    //     kycLevel : '1',
                    // }
                ]
            },
            {
                id   : 'apps.help-center.faqs',
                title: 'Hesap Hareketleri',
                type : 'basic',
                kycLevel : '0',
                link : '/moneyTransfer/movements'
            }
        ]
    },
    {
        id      : 'merchant',
        title   : 'Üye İşyeri',
        type    : 'collapsable',
        icon    : 'heroicons_outline:briefcase',
        kycLevel : '1',
        children: [
            {
                id   : 'apps.merchant.product-management',
                title: 'Ürün Yönetimi',
                type : 'basic',
                kycLevel : '1',
                link : '/merchant/inventory'
            },
            {
                id   : 'apps.merchant.pos',
                title: 'Satış Noktası',
                type : 'basic',
                kycLevel : '1',
                link : 'merchant/pointOfSale'
            },
            {
                id   : 'apps.merchant.movements',
                title: 'İşlem Hareketleri',
                kycLevel : '1',
                type : 'collapsable',
                icon    : 'heroicons_outline:book-open',
                children: [
                    {
                        id   : 'apps.merchant.movements-vpos',
                        title: 'Sanal POS',
                        type : 'basic',
                        kycLevel : '1',
                        link : 'merchant/movements/vpos'
                    },
                    // {
                    //     id   : 'apps.merchant.movements-pos',
                    //     title: 'Fiziki POS',
                    //     kycLevel : '1',
                    //     type : 'basic',
                    //     link : 'merchant/movements/pos'
                    // },
                    // {
                    //     id   : 'apps.merchant.movements-remittance',
                    //     title: 'Havale & EFT',
                    //     type : 'basic',
                    //     kycLevel : '1',
                    //     link : 'merchant/movements/remittance'
                    // }
                ]
            }
        ]
    },
    
    {
        id      : 'accounting',
        title   : 'Muhasebe',
        type    : 'collapsable',
        icon    : 'mat_outline:calculate',
        kycLevel : '0',
        children: [
            {
                id   : 'apps.accounting.account-management',
                title: 'Hesap Hareketleri',
                type : 'basic',
                link : '/accounting/accountMovements',
                kycLevel : '0',
            },
            {
                id   : 'apps.accounting.account-management',
                title: 'Banka Hesapları',
                type : 'basic',
                link : '/accounting/bankAccounts',
                kycLevel : '0',
            },
            {
                id   : 'apps.accounting.account-management',
                title: 'Transfer Hesapları',
                type : 'basic',
                link : '/accounting/transferAccounts',
                kycLevel : '1',
            },
            {
                id   : 'apps.accounting.cashback-invoices',
                title: 'Faturalar',
                type : 'basic',
                link : '/accounting/cashbackInvoices',
                kycLevel : '1',
            }
        ]
    },
    {
        id   : 'support',
        title: 'Destek',
        type : 'basic',
        kycLevel : '0',
        icon : 'heroicons_outline:support',
        link: 'support'
    },
    {
        id   : 'settings',
        title: 'Ayarlar',
        kycLevel : '0',
        type : 'basic',
        icon : 'heroicons_outline:cog',
        link: 'settings'
    },
    {
        id   : 'e-shopping',
        title: 'E-Mağaza',
        kycLevel : environment.production == true ? '1' : '0',
        type : 'collapsable',
        icon : 'mat_outline:add_shopping_cart',
        children: [
            // {
            //     id   : 'e-shopping.products',
            //     title: 'Mağaza',
            //     type : 'basic',
            //     icon : "mat_outline:shopping_basket",
            //     link : '/eshopping/listing',
            //     kycLevel : '0',
            // },
            // {
            //     id   : 'e-shopping.sale',
            //     title: 'İlan Ver',
            //     type : 'basic',
            //     icon : "mat_outline:sell",
            //     link : '/eshopping/sell',
            //     kycLevel : '0',
            // },
            {
                id   : 'e-shopping.profile',
                title: 'Sipariş Yönetimi',
                type : 'basic',
                icon : "mat_outline:person",
                link : '/eshopping/profile',
                kycLevel : '0',
            }
        ]
    },
];

export const compactNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example',
        kycLevel : '0',
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example',
        kycLevel : '0',
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example',
        kycLevel : '0',
    }
];

