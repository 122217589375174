<button
    mat-icon-button
    matTooltip="Duyurular"
    (click)="openPanel()"
    #announcementsOrigin>
    <ng-container *ngIf="unreadCount > 0">
        <span class="absolute top-0 right-0 left-0 flex items-center justify-center h-3">
            <span class="flex items-center justify-center shrink-0 min-w-4 h-4 px-1 ml-4 mt-2.5 rounded-full bg-teal-600 text-indigo-50 text-xs font-medium">
                {{unreadCount}}
            </span>
        </span>
    </ng-container>

    <mat-icon role="img" class="mat-icon notranslate mat-icon-no-color" aria-hidden="true" ng-reflect-svg-icon="heroicons_outline:bell" data-mat-icon-type="svg" data-mat-icon-name="bell" data-mat-icon-namespace="heroicons_outline">

    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.8" stroke="currentColor" aria-hidden="true" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
            <path stroke-linecap="round" stroke-linejoin="round" d="M10.34 15.84c-.688-.06-1.386-.09-2.09-.09H7.5a4.5 4.5 0 110-9h.75c.704 0 1.402-.03 2.09-.09m0 9.18c.253.962.584 1.892.985 2.783.247.55.06 1.21-.463 1.511l-.657.38c-.551.318-1.26.117-1.527-.461a20.845 20.845 0 01-1.44-4.282m3.102.069a18.03 18.03 0 01-.59-4.59c0-1.586.205-3.124.59-4.59m0 9.18a23.848 23.848 0 018.835 2.535M10.34 6.66a23.847 23.847 0 008.835-2.535m0 0A23.74 23.74 0 0018.795 3m.38 1.125a23.91 23.91 0 011.014 5.395m-1.014 8.855c-.118.38-.245.754-.38 1.125m.38-1.125a23.91 23.91 0 001.014-5.395m0-3.46c.495.413.811 1.035.811 1.73 0 .695-.316 1.317-.811 1.73m0-3.46a24.347 24.347 0 010 3.46"></path>
        </svg>
    </mat-icon>


</button>

<ng-template #announcementsPanel>

    <div class="fixed inset-0 sm:static sm:inset-auto flex flex-col sm:min-w-90 sm:w-90 sm:rounded-2xl overflow-hidden shadow-lg">

        <!-- Header -->
        <div class="flex shrink-0 items-center py-4 pr-4 pl-6 bg-primary text-on-primary">
            <div class="sm:hidden -ml-1 mr-3">
                <button
                    mat-icon-button
                    (click)="closePanel()">
                    <mat-icon
                        class="icon-size-5 text-current"
                        [svgIcon]="'heroicons_solid:x'"></mat-icon>
                </button>
            </div>
            <div class="text-lg font-medium leading-10">Duyurular</div>
            <!--
            <div class="ml-auto">
                <button
                    mat-icon-button
                    [matTooltip]="'Mark all as read'"
                    [disabled]="unreadCount === 0"
                    (click)="markAllAsRead()">
                    <mat-icon
                        class="icon-size-5 text-current"
                        [svgIcon]="'heroicons_solid:mail-open'"></mat-icon>
                </button>
            </div>
            -->
        </div>

        <!-- Content -->
        <div class="relative flex flex-col flex-auto sm:max-h-120 divide-y overflow-y-auto bg-card">

            <ng-container *ngFor="let announcement of announcements; trackBy: trackByFn">

                <div
                    (click)="details(announcement)"
                    class="flex group hover:bg-default dark:hover:bg-black dark:hover:bg-opacity-5"
                    [ngClass]="{'unread': !announcement.read}">

                    <div class="relative flex flex-col justify-center items-center">
                        <button
                            class="w-6 h-6 min-h-6"
                            mat-icon-button
                            [matTooltip]="announcement.read ? 'Mark as unread' : 'Yeni'">
                            <span
                                class="w-2 h-2 rounded-full"
                                [ngClass]="{'bg-gray-400 dark:bg-gray-500 sm:opacity-0 sm:group-hover:opacity-100': announcement.read,
                                            'bg-primary': !announcement.read}"></span>
                        </button>
                    </div>

                    <ng-container *ngIf="announcement.link">
                        <ng-container *ngIf="!announcement.useRouter">
                            <a
                                class="flex flex-auto py-5 pl-6 cursor-pointer"
                                [href]="announcement.link">
                                <ng-container *ngTemplateOutlet="announcementContent"></ng-container>
                            </a>
                        </ng-container>
                        <ng-container *ngIf="announcement.useRouter">
                            <a
                                class="flex flex-auto py-5 pl-6 cursor-pointer"
                                [routerLink]="announcement.link">
                                <ng-container *ngTemplateOutlet="announcementContent"></ng-container>
                            </a>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="!announcement.link">
                        <div class="flex flex-auto py-5 pl-6">
                            <ng-container *ngTemplateOutlet="announcementContent"></ng-container>
                        </div>
                    </ng-container>

                    <!--
                    <div class="relative flex flex-col my-5 mr-6 ml-2">
                        <button
                            class="w-6 h-6 min-h-6 sm:opacity-0 sm:group-hover:opacity-100"
                            mat-icon-button
                            (click)="delete(announcement)"
                            [matTooltip]="'Sil'">
                            <mat-icon
                                class="icon-size-4"
                                [svgIcon]="'heroicons_solid:x'"></mat-icon>
                        </button>
                    </div>
                    -->

                </div>


                <ng-template #announcementContent>
                    <ng-container *ngIf="announcement.icon && !announcement.image">
                        <div class="flex shrink-0 items-center justify-center w-8 h-8 mr-4 rounded-full bg-gray-100 dark:bg-gray-700">
                            <mat-icon
                                class="icon-size-5"
                                [svgIcon]="announcement.icon">
                            </mat-icon>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="announcement.image">
                        <img
                            class="shrink-0 w-8 h-8 mr-4 rounded-full overflow-hidden object-cover object-center"
                            [src]="announcement.image"
                            [alt]="'announcement image'">
                    </ng-container>
                    <div class="flex flex-col flex-auto">
                        <ng-container *ngIf="announcement.title">
                            <div
                                class="font-semibold line-clamp-1"
                                [innerHTML]="announcement.title"></div>
                        </ng-container>
                        <ng-container *ngIf="announcement.content">
                            <div
                                class="line-clamp-2"
                                [innerHTML]="announcement.content"></div>
                        </ng-container>
                        <div class="mt-2 text-sm leading-none text-secondary">
                            {{announcement.created_at | date:'dd-MM-yyyy, H:mm'}}
                        </div>
                    </div>
                </ng-template>
            </ng-container>

            <ng-container *ngIf="!announcements || !announcements.length">
                <div class="flex flex-col flex-auto items-center justify-center sm:justify-start py-12 px-8">
                    <div class="flex flex-0 items-center justify-center w-14 h-14 rounded-full bg-primary-100">
                        <mat-icon
                            class="text-primary-500-700"
                            [svgIcon]="'heroicons_outline:bell'"></mat-icon>
                    </div>
                    <div class="mt-5 text-2xl font-semibold tracking-tight">Duyuru Yok</div>
                    <div class="w-full max-w-60 mt-1 text-md text-center text-secondary">Duyurularınız olduğunda burada görünecekler.</div>
                </div>
            </ng-container>

        </div>

    </div>

</ng-template>
