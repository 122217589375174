import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from 'app/core/http/http.service';


@Injectable({
    providedIn: 'root'
})
export class CollectiveQueryService
{

    /**
     * Constructor
     */
    constructor(
        private _httpService: HttpService,
    )
    {
    }


    /**
     * Yurt ici para gonder komisyonlarini getirir
     */ 
    registeredCustomers(payload)
    {
        return this._httpService.get(`/dealer/dashboard/registeredCustomers`, payload);
    }

    

}
