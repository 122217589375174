<div class="h-full">
    <div class="flex justify-between">
        <div class="flex my-auto h-full py-4 pl-2">
            <span class="flex my-auto">
                <mat-icon *ngIf="message.toastType == 'error'" class="text-red-500" [svgIcon]="'heroicons_solid:x-circle'"></mat-icon>
                <mat-icon *ngIf="message.toastType == 'info'" class="text-amber-300" [svgIcon]="'heroicons_solid:information-circle'"></mat-icon>
                <mat-icon *ngIf="message.toastType == 'success'"  class="text-green-500" [svgIcon]="'heroicons_solid:check-circle'"></mat-icon>
                <mat-icon *ngIf="!message.toastType"></mat-icon>
            </span>
            <span class="my-auto pl-2" matSnackBarLabel>
                {{message.toastDescription}}
            </span>
        </div>
       
        <span class="flex" matSnackBarActions>
            <button 
            class="rounded-r rounded-l-none m-auto h-full max-h-full min-w-0 max-w-0"
            mat-button matSnackBarAction (click)="snackBarRef.dismissWithAction()">
                <mat-icon [svgIcon]="'heroicons_solid:x'"></mat-icon>
            </button>
        </span>
    </div>
    
</div>

