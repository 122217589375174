import { NgModule } from '@angular/core';
import { LoadingComponentService } from '@fuse/services/loading-component/loading-component.service';

@NgModule({
    providers: [
        LoadingComponentService
    ]
})
export class LoadingComponentModule
{
    /**
     * Constructor
     */
    constructor(private _loadingComponentService: LoadingComponentService)
    {
    }
}
