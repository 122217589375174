<mat-form-field class="w-full">
    <mat-label class="text-sm text-secondary" *ngIf="labelName">{{ labelName }}</mat-label>
    <input type="text"
		[placeholder]="placeholder"
		matInput
		(focus)="start()"
		[formControl]="myControl"
		class="w-full" 
		[matAutocomplete]="auto"
		#inputRef>
    	<mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)" [displayWith]="displayFn.bind(this)">
        
        <ng-container *ngIf="displayData == 'name'">
	        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
	    	    {{ option.name }}
	        </mat-option>
        </ng-container>

        <ng-container *ngIf="displayData == 'full_name'">
	        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
	    	    {{ option.full_name }}
	        </mat-option>
        </ng-container>

		<ng-container *ngIf="displayCombineData?.status == 'signUp'">
	        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
	    	    ({{ option.mmc }}) {{ option.name }}
	        </mat-option>
        </ng-container>

		<ng-container *ngIf="displayCombineData?.status == 'taxName'">
	        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
	    	    {{ option.name }}
	        </mat-option>
        </ng-container>

		<ng-container *ngIf="displayCombineData?.status == 'bankAccounts'">
	        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
	    	    <div *ngIf="option.optional_name == null">
					{{ option.bankName }} ({{ option.iban }})
				</div>
				<div *ngIf="option.optional_name != null">
					{{ option.optional_name }} ({{ option.iban }})
				</div>
	        </mat-option>
        </ng-container>

        <ng-container *ngIf="displayCombineData?.status == 'mobile'">
	        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
	    	    {{ option.name }} {{ option.amounts.total }}₺
	        </mat-option>
        </ng-container>

		<ng-container *ngIf="displayData == 'display_name'">
	        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
	    	    {{ option.name }} {{ option.surname }}
	        </mat-option>
        </ng-container>

    </mat-autocomplete>
</mat-form-field>