import { Injectable, Inject } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { HttpService } from 'app/core/http/http.service';
import { BehaviorSubject } from 'rxjs';


@Injectable()
export class AuthService
{
    private selectedType: string;
    private _authenticated: boolean = false;
    public authenticatorsType: any;

    /**
     * Constructor
     */
    constructor(
        private _httpService: HttpService
        
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set auth(data: any)
    {
        localStorage.setItem("auth", JSON.stringify(data));
    }

    get auth(): any
    {
        return JSON.parse(localStorage.getItem("auth")) ?? '';
    }

    set account(data: any)
    {
        localStorage.setItem("account", JSON.stringify(data));
    }

    get account(): any
    {
        if (JSON.parse(localStorage.getItem("account"))) {
            return JSON.parse(localStorage.getItem("account"));

        } else {
            this.setAccount();
            return JSON.parse(localStorage.getItem("account"));
        }
        // return JSON.parse(localStorage.getItem("account")) ?? '';
    }

    set accessToken(token: string)
    {
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string
    {
        return localStorage.getItem('accessToken') ?? '';
    }

    set isVerify(verify: string)
    {
        localStorage.setItem('verify', verify);
    }

    get isVerify()
    {
        return localStorage.getItem('verify') ?? '';
    }

    set mobile_number(data: any)
    {
        localStorage.setItem("mobile_number", JSON.stringify(data));
    }

    get mobile_number(): any
    {
        return JSON.parse(localStorage.getItem("mobile_number")) ?? '';
    }

    set referenceCode(reference_code: string)
    {
        localStorage.setItem('reference_code', reference_code);
    }

    get referenceCode()
    {
        return localStorage.getItem('reference_code') ?? '';

    }

    set locked(locked: string)
    {
        localStorage.setItem('locked', locked);
    }

    get locked()
    {
        return localStorage.getItem('locked') ?? '';
    }


    
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */

    // Şifremi unuttum isteği atar
    forgotPassword(payload): Observable<any>
    {
        return this._httpService.post(`/dealer/auth/forgotPassword`, payload).pipe(
            switchMap((response: any) => {
                if (response.status) {
                    this.mobile_number = response.data;
                }
                return of(response);
            })
        );
    }

    // Şifremi unuttum isteğinde dönen telefon numarası ile doğrulama isteği atar
    smsVerify(payload) {
        return this._httpService.post(`/dealer/auth/forgotPassword/verify`, payload);
    }

    // Yeni şifreyi belirleme isteğini atar
    complete(payload) {
        return this._httpService.post('/dealer/auth/forgotPassword/complete', payload);
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string): Observable<any>
    {
        return this._httpService.post('api/auth/reset-password', password);
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    // signIn(credentials: { email: string; password: string }): Observable<any>
    signIn(credentials: { dealer_id: string; id: string; password: string; type: string; }): Observable<any>
    {
        // data -> session -> authenticator -> reference_code
        // Throw error, if the user is already logged in
        if ( this._authenticated )
        {
            return throwError('User is already logged in.');
        }
        

        credentials["angular"] = 1;
        return this._httpService.post(`/dealer/auth`, credentials, false).pipe(
            catchError(() =>

                // Return false
                of(false)
            ),
            switchMap((response: any) => {

                if (response.status) {
                    
                    console.log("basarili giris");

                    // Store the access token in the local storage
                    this.accessToken = response.data.session.token;


                    // referans kodu local storage kayit ediliyor
                    this.referenceCode = response.data?.session?.authenticator?.reference_code;

                    // ikinci dogrulama alani gerekli  
                    this.isVerify = 'false';

                    // Set the authenticated flag to true
                    this._authenticated = true;

                    // Store the user on the user service
                    // this._userService.user = response.data.user;

                    // Return true
                    return of(response);

                } else {

                    console.log("basarisiz giris");

                    return of(response);
                }

            })

        );
    }

    checkPass(pass: string): Observable<any>
    {
        return this._httpService.get(`/dealer/auth/security?password=${pass}`).pipe(

            switchMap((response: any) => {
                return of(response)
            })
        );

    }


    /**
     * Doğrulama kodunu gonderir 
     */
    verifyIn(credentials: { code: any; }): Observable<any>
    {
        // giris zaten yapilmis ise

        // code: 25800
        // reference_code: "RHGPC"
        // session_token: "3711277912812dd4cad5043bcb453cd18451692afc362c14b1b66c0335c121c0"
        // type: "sms"
        // const selectedType = this.getSelectedType(); 

        credentials["session_token"] = this.accessToken;
        if (credentials["type"] == "totp") {
            credentials["code"] = Number(credentials['code']);
        } else {
            credentials["reference_code"] = this.referenceCode;
        }

        return this._httpService.post(`/dealer/auth/verify`, credentials, false).pipe(
            catchError(() =>
                of(false)
            ),
            switchMap((response: any) => {
                if (response.status) {
                    console.log("dogrulandi");
                    this.isVerify = 'true';
                    this._httpService.put(`/dealer/auth`, {session_token: this.accessToken}).subscribe((response) => {
                        if (response.status) {
                            this.auth = response.data;
                            localStorage.setItem("usr-access-level", response.data.session.dealer_personnel.dealer.kyc_level);
                        }
                    });
                    return of(response);

                } else {
                    console.log("dogrulanamadi");
                    return of(response);

                }

            })
        )
    }
    
    verifyInSelectTwoFactory(credentials: any): Observable<any>
    {
        return this._httpService.patch(`/dealer/auth/verify`, credentials).pipe(
            catchError(() =>
                of(false)
            ),
            switchMap((response: any) => {
                if (response.status) {
                    if (credentials?.type == 'sms') {
                        this.referenceCode = response.data?.session?.authenticator?.reference_code;
                    }

                    return of(response);
                } else {
                    return of(response);
                }
            })
        )
    }


    /**
     * kullanici bilgilerini getirir
     */
    getAuth() {
        return this._httpService.get(`/dealer/auth`).pipe(
                switchMap((response: any) => {
                    if (response.status) {
                        this.auth = response.data;
                    }
                    return of(response);
                })
            );
    }


    /**
     * oturum kilitlemisini acar
     */
    unlockSession(payload) {
        return this._httpService.put(`/dealer/auth/lock`, payload).pipe(
                switchMap((response: any) => {
                    return of(response);
                })
            );
    }


    /**
     * kullanici hesap bilgilerini getirir
     */
    getAccount() {
        return this._httpService.get(`/dealer/configs/account`).pipe(
                switchMap((response: any) => {
                    if (response.status) {
                        this.account = response.data;
                    }
                    return of(response);
                })
            );
    }


    setAccount() {
        this.getAccount().subscribe();
    }


    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any>
    {
        return this._httpService.get(`/dealer/auth`, {
            params: {
                session_token: this.accessToken
            }
        }).pipe(
            catchError(() =>
                // Return false
                of(false)
            ),
            switchMap((response: any) => {
                console.log("signInUsingToken!");
                return of(response)
            })
        );

    }

    /**
     * Sign out
     */
    signOut(): Observable<any>
    {
        // Remove the access token from the local storage
        // localStorage.removeItem('accessToken');
        // localStorage.removeItem('verify');
        // localStorage.removeItem('referenceCode');
        // localStorage.removeItem('auth');
        let logVersion = localStorage.getItem("logVersion");

        // Beni hatırla özelliği için local storage'deki bu veriler dışındaki verileri temizler
        for (const key in localStorage) {
            if (
                key !== 'dealer_id' && 
                key !== 'id' && 
                key !== 'citizen_no' && 
                key !== 'mobile_number' && 
                key !== 'code' &&
                key !== 'logVersion' &&
                key !== 'scheme' &&
                key !== 'theme'
            ) {
                localStorage.removeItem(key);
            }
        }
        
        localStorage.setItem("logVersion", logVersion);
        
        // Set the authenticated flag to false
        this._authenticated = false;

        // Return the observable
        return of(true);
    }


    /**
     * Kayıt ol islemi
     *
     * @param user
     */
    signUp(payload): Observable<any>
    {
        return this._httpService.post('/dealer/auth/register', payload, false);
    }


    /**
     * Kayit ol islemini sms bilgileri ile gonderir
     */ 
    signUpVerify(payload): Observable<any>
    {
        return this._httpService.post('/dealer/auth/register/verify', payload, false);
    }


    /**
     * Check the authentication status
     */
    check(): Observable<number>
    {

        return this.checkVerify();

        // Check if the user is logged in
        if ( this._authenticated )
        {
            return of(1); // anasayfaya yonlendir
        }

        // Check the access token availability
        if ( !this.accessToken )
        {
            return of(0); // giris yap sayfasina yonlendir
        }

        // Check the access token expire date
        /*
        if ( AuthUtils.isTokenExpired(this.accessToken) )
        {
            return of(false);
        }
        */

        // If the access token exists and it didn't expire, sign in using it
        return this.signInUsingToken();
    }

    checkVerify(): Observable<number>
    {
        if ( this.locked == 'true' ) {
            return of(3);
        }

        if ( this.isVerify == '' ) // giris yapa yonlendir
        {
            return of(0);
        }

        if ( this.isVerify == 'true' ) // anasayfaya yonlendir
        {
            return of(1);
        }

        if ( this.isVerify != 'true' ) // ikinci dogrulamaya yonlendir
        {
            return of(2);
        }

        return of(0);
    }
}