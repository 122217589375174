<button
    mat-icon-button
    [matTooltip]="notificationToolTip"
    #tooltip="matTooltip"
    (click)="openPanel()"
    #notificationsOrigin>
    <ng-container *ngIf="unreadCount > 0">
        <span class="absolute top-0 right-0 left-0 flex items-center justify-center h-3">
            <span class="flex items-center justify-center shrink-0 min-w-4 h-4 px-1 ml-4 mt-2.5 rounded-full bg-teal-600 text-indigo-50 text-xs font-medium">
                {{unreadCount}}
            </span>
        </span>
    </ng-container>
    
    <mat-icon [svgIcon]="'heroicons_outline:bell'"></mat-icon>

</button>

<ng-template #notificationsPanel>

    <div class="fixed inset-0 sm:static sm:inset-auto flex flex-col sm:min-w-90 sm:w-90 sm:rounded-2xl overflow-hidden shadow-lg">

        <!-- Header -->
        <div class="flex shrink-0 items-center py-4 pr-4 pl-6 bg-primary text-on-primary">
            <div class="text-lg font-medium leading-10 flex justify-between w-full">
                Bildirimler
                <button *ngIf="notifications && notifications.length"
                mat-icon-button
                [matTooltip]="'Tüm Bildirimleri Temizle'"
                (click)="allDelete()">
                <mat-icon
                    class="icon-size-5 text-white"
                    [svgIcon]="'heroicons_outline:trash'"></mat-icon>
                </button>
            </div>
            <div class="ml-auto">
                <!--
                <button
                    mat-icon-button
                    [matTooltip]="'Bütün bildirimleri temizle'"
                    (click)="allClearNotifications()">
                    <mat-icon
                        class="icon-size-5 text-red-500"
                        [svgIcon]="'heroicons_solid:x'"></mat-icon>
                </button>
                -->
            </div>
        </div>

        <!-- Content -->
        <div class="relative flex flex-col flex-auto sm:max-h-120 divide-y overflow-y-auto bg-card">

            <ng-container *ngFor="let notification of notifications; trackBy: trackByFn; let i = index;">

                <div
                    class="flex group hover:bg-default dark:hover:bg-black dark:hover:bg-opacity-5"
                    [ngClass]="{'unread': !notification.readted_at}">

                    <div class="relative flex flex-col justify-center items-center">
                        <button
                            class="w-6 h-6 min-h-6"
                            mat-icon-button
                            [matTooltip]="notification.readted_at ? 'Mark as unread' : 'Yeni'">
                            <span
                                class="w-2 h-2 rounded-full"
                                [ngClass]="{'bg-gray-400 dark:bg-gray-500 sm:opacity-0 sm:group-hover:opacity-100': notification.readted_at,
                                            'bg-primary': !notification.readted_at}"></span>
                        </button>
                    </div>

                    <!-- <div class="absolute flex left-0 flex-col justify-center items-center">
                        
                        <span class="text-sm fuse-text-color p-2" *ngIf="!notification.readted_at">
                            Yeni Bildirim
                        </span>

                    </div> -->
                    <div class="absolute flex right-0 flex-col justify-center items-center">
                        <!--
                        <button
                            class="w-6 h-6 min-h-6"
                            mat-icon-button
                            [matTooltip]="notification.readted_at ? 'Mark as unread' : 'Yeni'">
                            <span
                                class="w-2 h-2 rounded-full"
                                [ngClass]="{'bg-gray-400 dark:bg-gray-500 sm:opacity-0 sm:group-hover:opacity-100': notification.readted_at,
                                            'bg-primary': !notification.readted_at}"></span>
                        </button>
                        -->
                        <button
                            mat-icon-button
                            [matTooltip]="'Bildirimi Sil'"
                            (click)="delete(notification)">
                            <mat-icon
                                class="icon-size-5 text-red-500"
                                [svgIcon]="'heroicons_solid:x'"></mat-icon>
                        </button>
                    </div>

                    <div 
                        (click)="details(notification)">
                        
                    <ng-container *ngIf="notification.link">
                        <ng-container *ngIf="!notification.useRouter">
                            <a
                                class="flex flex-auto py-5 cursor-pointer"
                                [href]="notification.link">
                                <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
                            </a>
                        </ng-container>
                        <ng-container *ngIf="notification.useRouter">
                            <a
                                class="flex flex-auto py-5 cursor-pointer"
                                [routerLink]="notification.link">
                                <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
                            </a>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="!notification.link">
                        <div class="flex flex-auto py-5">
                            <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
                        </div>
                    </ng-container>
                    </div>


                </div>


                <ng-template #notificationContent>
                    <ng-container *ngIf="notification.icon">
                        <div class="flex shrink-0 items-center justify-center w-8 h-8 mx-2 my-auto rounded-full bg-gray-100 dark:bg-gray-700">
                            <mat-icon
                                class="icon-size-5"
                                [svgIcon]="notification.icon">
                            </mat-icon>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="notification.image && !notification.icon">
                        <img
                            class="shrink-0 w-8 h-8 mx-2 my-auto rounded-full overflow-hidden object-cover object-center"
                            [src]="notification.image"
                            [alt]="'notification image'">
                    </ng-container>

                    
                    <div class="flex flex-col flex-auto mt-2"
                        [ngClass]="{'ml-4': !notification.icon && !notification.image,
                                            '': notification.icon || notification.image}">
                        <ng-container *ngIf="notification.title">
                            <div
                                class="font-semibold line-clamp-1"
                                [innerHTML]="notification.title"></div>
                        </ng-container>
                        <ng-container *ngIf="notification.content">
                            
                            <div *ngIf="notification.title != 'Toplu Fatura Ödeme'">
                                <div
                                    class="line-clamp-2"
                                    [innerHTML]="notification.content"></div>
                            </div>
                            <div *ngIf="notification.title == 'Toplu Fatura Ödeme' || notification.facility_type_id == 15">
                                <div class="line-clamp-2">
                                    Fatura detaylarını görmek için tıklayınız.     
                                </div>
                            </div>
                        </ng-container>
                        <div class="mt-2 text-sm leading-none text-secondary">
                            {{notification.created_at | date:'dd-MM-yyyy, H:mm'}}
                        </div>
                    </div>
                </ng-template>
            </ng-container>

            <ng-container *ngIf="!notifications || !notifications.length">
                <div class="flex flex-col flex-auto items-center justify-center sm:justify-start py-12 px-8">
                    <div class="flex flex-0 items-center justify-center w-14 h-14 rounded-full bg-primary-100">
                        <mat-icon
                            class="text-primary-500-700"
                            [svgIcon]="'heroicons_outline:bell'"></mat-icon>
                    </div>
                    <div class="mt-5 text-2xl font-semibold tracking-tight">Bildirim Yok</div>
                    <div class="w-full max-w-60 mt-1 text-md text-center text-secondary">Bildirim olduğunda burada görünecekler.</div>
                </div>
            </ng-container>

        </div>

    </div>


</ng-template>
