<h2 mat-dialog-title class="mb-0">Yeni Abonelik Ekle</h2>
<span class="text-secondary text-sm" mat-dialog-title>Müşterinizin bütün aboneliklerini ekleyerek müşteriniz daha sonraki geldiğinde tüm borçlarını tek bir buton ile sorgulayabilirsiniz.</span>




<mat-dialog-content class="mat-typography">

	<div class="flex flex-col p-2 w-full">
		
		<form [formGroup]="firstForm" #firstNgForm="ngForm">

			<search-input class="sendMoney_province w-full my-1" [escapeFromFocus]="true" [placeholder]="'Kurum seçiniz'" [labelName]="'Kurumlar'" [dataSource]="soots" [searchData]="'name'" [displayData]="'name'" (result)="setSelectedSoot($event)"></search-input>

			<ng-container *ngFor="let item of firstForm.get('fields')['controls']; let i = index; trackBy:trackByFn;">
                <ng-container class="form-group" formArrayName="fields">

					<mat-form-field class="mat-form-mb-0 mat-form-field md:mr-6 w-full ng-tns-c4-7 mat-primary ng-star-inserted mat-form-field-type-mat-input mat-form-field-appearance-fill mat-form-field-can-float mat-form-field-has-label mat-form-field-hide-placeholder ng-untouched ng-pristine ng-valid">
						<mat-label class="text-sm text-secondary">{{ selectedSootDetails?.fields[i].name }}</mat-label>
						<input type="{{selectedSootDetails?.fields[i].type}}" maxlength="{{selectedSootDetails?.fields[i].max_length}}" minlength="{{selectedSootDetails?.fields[i].min_length}}" class="border-none" required="{{selectedSootDetails?.fields[i].is_required}}"  formControlName="{{i}}" matInput>
					</mat-form-field>

				</ng-container>

				<mat-error [ngClass]="{'-mt-4': true, 'mb-4': selectedSootDetails?.fields.length > '0'}">
					<div *ngIf="item.hasError('required')">
						{{ selectedSootDetails?.fields[i].name }} alanı zorunludur.
					</div>
					<div *ngIf="item.hasError('minlength')">
						{{ selectedSootDetails?.fields[i].name }} alanı en az {{ selectedSootDetails?.fields[i].min_length }} karakter olabilir.
					</div>
					<div *ngIf="item.hasError('maxlength')">
						{{ selectedSootDetails?.fields[i].name }} alanı en fazla {{ selectedSootDetails?.fields[i].max_length }} karakter olabilir.
					</div>
	            </mat-error>
			</ng-container>

			<mat-form-field id="mb-clear" class="md:mr-6 w-full">
				<mat-label class="text-sm text-secondary">Açıklama</mat-label>
				<input type="text" class="border-none" id="description" id="description" [formControlName]="'description'"  matInput>
			</mat-form-field>

		</form>

	</div>

</mat-dialog-content>

<mat-dialog-actions align="end">
	<button mat-button mat-dialog-close (click)="cancel()">İptal</button>
	<button
		class="fuse-mat-button rounded"
		mat-flat-button
		[color]="'primary'"
		[disabled]="firstForm.invalid || firstForm.disabled"
		(click)="createSubscriber()">
		<span class="text-md" *ngIf="!firstForm.disabled">
			Ekle
		</span>
		<mat-progress-spinner
			*ngIf="firstForm.disabled"
			[diameter]="24"
			[mode]="'indeterminate'"></mat-progress-spinner>
	</button>
	<!--
	<button mat-button mat-dialog-close cdkFocusInitial (click)="createSubscriber(fields)">Ekle</button>
	-->
</mat-dialog-actions>










