import { Component,ElementRef, AfterViewInit , OnDestroy, OnInit, ViewChild, Output, ChangeDetectorRef, /*ViewEncapsulation*/ } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { Subject, takeUntil } from 'rxjs';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { Router } from '@angular/router';
import { AuthService } from 'app/core/auth/auth.service';
import { ToastServiceComponent } from 'app/layout/common/toast-service/toast-service.component';
import { FocusCommunicationServiceService } from 'app/focus-communication-service.service';
@Component({
    selector       : 'settings',
    templateUrl    : './settings.component.html',
    styleUrls      : ['./settings.component.scss'],
    // encapsulation  : ViewEncapsulation.None,
})
export class SettingsComponent implements OnInit, OnDestroy
{
    @ViewChild('inputRef') inputRef: ElementRef;

    ngAfterViewInit(): void {
        setTimeout(()=>{
            if(this.inputRef){
                console.log("element ref oluştu");
                this.inputRef.nativeElement.focus();
            }else{
                console.log("element oluşmadı");
            }
        },100);
    }

    @ViewChild('matDrawer', {static: true}) matDrawer: MatDrawer;
    authData: any;
    drawerMode: 'side' | 'over';
    drawerOpened: boolean;
    menuData: FuseNavigationItem[] = [
        {
            id      : 'account',
            title   : 'Hesap',
            type    : 'group',
            kycLevel    : '0',
            children: [
                {
                    id   : 'account.account-info',
                    title: 'Hesap Bilgileri',
                    type : 'basic',
                    link : 'account/account-info',
                    kycLevel    : '0',
                },
                {
                    id   : 'account.reset-password',
                    title: 'Şifre Değiştir',
                    type : 'basic',
                    link : 'account/reset-password',
                    kycLevel    : '0',
                },
                {
                    id   : 'account.reset-phone',
                    title: 'Numara Değiştir',
                    type : 'basic',
                    link : 'account/reset-phone',
                    kycLevel    : '0',
                },
                {
                    id   : 'account.sessions',
                    title: 'Oturumlar',
                    type : 'basic',
                    link : 'account/sessions',
                    kycLevel    : '0',
                }
            ]
        },
        {
            id      : 'representation',
            title   : 'Üyelik',
            type    : 'group',
            kycLevel    : '0',
            children: [
                {
                    id   : 'representation.representation-info',
                    title: 'Üyelik Bilgileri',
                    type : 'basic',
                    link : 'representation/representation-info',
                    kycLevel    : '0',

                },
                
                {
                    id   : 'representation.kyc-verification',
                    title: 'Kimlik Doğrulama/Güncelleme',
                    type : 'basic',
                    link : 'representation/kyc-verification',
                    kycLevel    : '0',
                },

                {
                    id   : 'representation.documents-pending-approval',
                    title: 'Bekleyen Evraklar',
                    type : 'basic',
                    link : 'representation/documents-pending-approval',
                    kycLevel    : '0',
                },
                {
                    id   : 'representation.personnel-groups',
                    title: 'Personel Grupları',
                    type : 'basic',
                    link : 'representation/personnel-groups',
                    kycLevel    : '0',
                },
                {
                    id   : 'representation.personnels',
                    title: 'Personeller',
                    type : 'basic',
                    link : 'representation/personnels',
                    kycLevel    : '0',
                },
                {
                    id   : 'representation.commissions',
                    title: 'İşlem Ücretleri',
                    type : 'basic',
                    link : 'representation/commissions',
                    kycLevel    : '0',
                },
                {
                    id   : 'representation.receipt',
                    title: 'Dekont',
                    type : 'basic',
                    link : 'representation/receipt',
                    kycLevel    : '0',
                }
            ]
        },
        {
            id      : 'customization',
            title   : 'Özelleştirme',
            type    : 'group',
            kycLevel    : '0',
            children: [
                {
                    id   : 'customization.theming',
                    title: 'Tema Ayarları',
                    type : 'basic',
                    link : 'customization/theming',
                    kycLevel    : '0',
                },
            ]
        },
        {
            id      : 'security',
            title   : 'Güvenlik',
            type    : 'group',
            kycLevel    : '0',
            children: [
                {
                    id   : 'security.twostep-verification',
                    title: 'İki Adımlı Doğrulama',
                    type : 'basic',
                    link : 'security/twostep-verification',
                    kycLevel    : '0',
                },
                {
                    id   : 'security.session-settings',
                    title: 'Oturum Ayarları',
                    type : 'basic',
                    link : 'security/session-settings',
                    kycLevel    : '0',
                },
                {
                    id   : 'security.ip-settings',
                    title: 'IP Ayarları',
                    type : 'basic',
                    link : 'security/ip-settings',
                    kycLevel    : '0',
                },
                {
                    id   : 'security.failed-logins',
                    title: 'Hatalı Giriş Denemeleri',
                    type : 'basic',
                    link : 'security/failed-logins',
                    kycLevel    : '0',
                },
                {
                    id   : 'security.secretKey',
                    title: 'Api Erişimi',
                    type : 'basic',
                    link : 'security/secretKey',
                    kycLevel    : '0',
                }
            ]
        },
        {
            id      : 'applications',
            title   : 'Uygulamalar',
            type    : 'group',
            kycLevel    : '0',
            children: [
                // {
                //     id   : 'applications.b2b',
                //     title: 'B2B',
                //     type : 'basic',
                //     link : 'applications/b2b'
                // },
                {
                    id   : 'applications.posApp',
                    title: 'POS Uygulaması',
                    type : 'basic',
                    link : 'applications/posApp',
                    kycLevel    : '0',
                },
            ]
        },
        {
            id      : 'systemic',
            title   : 'Sistemsel',
            type    : 'group',
            kycLevel    : '0',
            children: [
                {
                    id   : 'security.twostep-verification',
                    title: 'Güncellemeler',
                    type : 'basic',
                    link : 'systemic/changelog',
                    kycLevel    : '0',
                },
            ]
        },
        {
            id      : 'information',
            title   : 'Bilgilendirme',
            type    : 'group',
            kycLevel    : '0',
            children: [
                {
                    id   : 'security.twostep-verification',
                    title: 'Fatura Ödeme',
                    type : 'basic',
                    link : 'information/invoicePayments',
                    kycLevel    : '0',
                },
                {
                    id   : 'information.current-documents',
                    title: 'Güncel Evraklar',
                    type : 'basic',
                    link : 'information/currentDocs',
                    kycLevel    : '0',
                }
            ]
        }
    ];
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    pass: string;
    passStatus: boolean = true;



    filteredMenuData = [];
    
    /**
     * Constructor
     */
    constructor(
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _router: Router,
        private _authService: AuthService,
        private toastService: ToastServiceComponent,
        private focusService: FocusCommunicationServiceService,
        private cdr: ChangeDetectorRef
    )
    {
       
    }

    giveFocus(){
        this.focusService.setFocus(true);
    }
    
    checkPassword(){
        this.giveFocus();

        this._authService.checkPass(this.pass).subscribe((response) => {
            if(response.status){
                this.authData = response.data;
                this.passStatus = true;
            }else{
                this.pass = '';
                if(response.error.description = "Form Doğrulama Başarısız!"){
                    this.toastService.open({
                        toastType: "error",
                        toastDescription: "Girilen şifre hatalı!"
                    }, 3000);
                }else{
                    this.toastService.open({
                        toastType: "error",
                        toastDescription: response.error.description
                    }, 3000);  
                }

            }
        });
    }
    
    disablePass() {
        this.passStatus = true;
        console.log(this.passStatus);
    }

    enablePass() {
        this.passStatus = false;
        console.log(this.passStatus);
    }
    
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */

    ngOnInit(): void
    {

        this.loadMenu();
        // Subscribe to media query change
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({matchingAliases}) => {

                // Set the drawerMode and drawerOpened
                if ( matchingAliases.includes('md') )
                {
                    this.drawerMode = 'side';
                    this.drawerOpened = true;
                }
                else
                {
                    this.drawerMode = 'over';
                    this.drawerOpened = false;
                }

            });

    }

    private loadMenu() {
        const auth = JSON.parse(localStorage.getItem('auth') || '{}');
        const accountType = auth?.session?.dealer_personnel?.dealer?.accountType;
        console.log('Original Menu Data:', this.menuData);
        this.filteredMenuData = this.filterMenu(this.menuData, accountType);
        console.log('Filtered Menu Data:', this.filteredMenuData);

        this.cdr.detectChanges();
    }

    private filterMenu(menuData: FuseNavigationItem[], accountType: string): FuseNavigationItem[] {
        return menuData
        .filter(group => {
            // 'applications' grubunu gizle
            if (group.id === 'applications' && accountType === 'CUSTOMER') {
                console.log(group.id);
                return false;
            }
            // Diğer grup ve öğeleri işle
            if (group.children) {
                group.children = group.children.filter(item => {
                    // 'representation.personnel-groups', 'representation.personnels' ve 'security.secretKey' öğelerini gizle
                    if (accountType === 'CUSTOMER') {
                        return !(item.id === 'representation.personnel-groups' || 
                                 item.id === 'representation.personnels' || 
                                 item.id === 'security.secretKey');
                    }
                    return true;
                });
            }
            return true;
        });
        
    }
    
    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

}
