import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { MatButton } from '@angular/material/button';
import { Subject, takeUntil } from 'rxjs';
import { AnnouncementsService } from 'app/layout/common/announcements/announcements.service';
import { FuseConfirmationService } from "@fuse/services/confirmation";
import { FormBuilder, FormGroup, NgForm, Validators } from "@angular/forms";
import { AuthService } from 'app/core/auth/auth.service';

@Component({
    selector       : 'announcements',
    templateUrl    : './announcements.component.html',
    encapsulation  : ViewEncapsulation.None,
    // changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs       : 'announcements'
})
export class AnnouncementsComponent implements OnInit, OnDestroy
{
    @ViewChild('announcementsOrigin') private _announcementsOrigin: MatButton;
    @ViewChild('announcementsPanel') private _announcementsPanel: TemplateRef<any>;

    AnnouncementsConfigForm: FormGroup; // dialog
    announcementIsOpen: boolean = false;

    announcements: any[];
    unreadCount: number = 0;
    private _overlayRef: OverlayRef;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _announcementsService: AnnouncementsService,
        private _overlay: Overlay,
        private _viewContainerRef: ViewContainerRef,
        private _fuseConfirmationService: FuseConfirmationService,
        private _formBuilder: FormBuilder,
        private _authService: AuthService,

    )
    {
    }


    /**
     * On init
     */
    ngOnInit(): void
    {
        this.getAnnouncements();

        
        this._announcementsService.announcements$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((announcements: any[]) => {


                this.announcements = announcements;
                if (this.announcements['data']) {
                    this.announcements = this.announcements['data'];
                }

                
                this._calculateUnreadCount();

                this._changeDetectorRef.markForCheck();
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();

        // Dispose the overlay
        if ( this._overlayRef )
        {
            this._overlayRef.dispose();
        }
    }


    getAnnouncements() {
        let newAnnouncement = localStorage.getItem("newAnnouncement");
        newAnnouncement = JSON.parse(newAnnouncement);
        if (newAnnouncement) {
            this.setAnnouncements(newAnnouncement, true);
        } else {
            let newAnnouncements = localStorage.getItem("newAnnouncements");
            newAnnouncements = JSON.parse(newAnnouncements);
            if (newAnnouncements) {
                this.setAnnouncements(newAnnouncements[0]);
            }
        }
    }

    openPanel(): void
    {
        if ( !this._announcementsPanel || !this._announcementsOrigin )
        {
            return;
        }

        // Create the overlay if it doesn't exist
        if ( !this._overlayRef )
        {
            this._createOverlay();
        }

        // Attach the portal to the overlay
        this._overlayRef.attach(new TemplatePortal(this._announcementsPanel, this._viewContainerRef));
    }

    closePanel(): void
    {
        this._overlayRef.detach();
    }


    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any
    {
        return item.id || index;
    }


    /**
     * bildirimi ekrana basar
     */ 
    openDialog(value, flag = false) {

        const dialogRef = this._fuseConfirmationService.open(
            this.AnnouncementsConfigForm.value
        );

        dialogRef.afterClosed().subscribe((result) => {
            if (result == "confirmed") {
                this.oneClose(value, flag);
            } else if (result == "cancelled") {
                this.allClose(value, flag);
            } else {
                // dialog kapandiginda duyurulari tekrar getirir
                this._announcementsService.getAll().subscribe();
            }
        });
    }


    /**
     * yeni bir bildirim gelirse, gelen verilere ait bi yapi olusturur
     */ 
    setAnnouncements(value, flag = false) {
        if (this.announcementIsOpen == false) {
            this.announcementIsOpen = true;
            this.AnnouncementsConfigForm = this._formBuilder.group({
                title: value.title,
                message: value.content,
                icon: this._formBuilder.group({
                    show: true,
                    name: "heroicons_outline:exclamation",
                    color: "warning",
                }),
                actions: this._formBuilder.group({
                    confirm: this._formBuilder.group({
                        show: true,
                        label: "Okudum",
                        color: "primary",
                    }),
                    cancel: this._formBuilder.group({
                        show: true,
                        label: "Tümünü Okudum",
                        color: "primary",
                    }),
                }),
                dismissible: true,
            });

            if (flag) {
                localStorage.setItem("newAnnouncement", JSON.stringify(value));
                this.openDialog(value, true);

            } else {
                this.openDialog(value);
            }


        } else {
            let newAnnouncements = localStorage.getItem("newAnnouncements");
            newAnnouncements = JSON.parse(newAnnouncements);
            console.log("newAnnouncements");
            console.log(newAnnouncements);
            console.log("newAnnouncements");
            if (newAnnouncements) {

                if (Array.isArray(newAnnouncements)) {
                    newAnnouncements[newAnnouncements.length] = value;
                    localStorage.setItem("newAnnouncements", JSON.stringify(newAnnouncements));
                }

            } else {
                let newAnnouncement = [];
                newAnnouncement[0] = value;
                localStorage.setItem("newAnnouncements", JSON.stringify(newAnnouncement));
            }
        }
        
    }


    /**
     * bildirimi -tek okudugunu soyler
     */ 
    oneClose(value, flag = false) {
        this._announcementsService.oneClose(value)
            .subscribe(
                (response) => {
                    this.announcementIsOpen = false;

                    if (flag) {
                        let newAnnouncement = localStorage.getItem("newAnnouncement");
                        newAnnouncement = JSON.parse(newAnnouncement);
                        if (newAnnouncement) {
                            localStorage.removeItem("newAnnouncement");
                            this.getAnnouncements();

                        }


                    } else {

                        let newAnnouncements = localStorage.getItem("newAnnouncements");
                        newAnnouncements = JSON.parse(newAnnouncements);
                        if (newAnnouncements) {
                            // this.setAnnouncements(newAnnouncements[0]);
                            if (Array.isArray(newAnnouncements)) {
                                newAnnouncements.shift();
                                localStorage.setItem("newAnnouncements", JSON.stringify(newAnnouncements));
                                if (!newAnnouncements[0]) {
                                    localStorage.removeItem("newAnnouncements");
                                } else {
                                    this.getAnnouncements();
                                }
                            }
                        }

                    }

                    this._announcementsService.getAll().subscribe();
                }
            );
    }


    /**
     * bildirimi -hepsi okudugunu soyler
     */ 
    allClose(value, flag = false) {
        this._announcementsService.allClose(value)
            .subscribe(
                (response) => {
                    this.announcementIsOpen = false;
                    localStorage.removeItem("newAnnouncement");
                    localStorage.removeItem("newAnnouncements");
                    this.getAnnouncements();

                    this._announcementsService.getAll().subscribe();

                }
            );
    }


    /**
     * duyurunun detaylari icin dialog acar
     */ 
    details(announcement) {
        this.AnnouncementsConfigForm = this._formBuilder.group({
                title: announcement.title,
                message: announcement.content,
                icon: this._formBuilder.group({
                    show: true,
                    name: "heroicons_outline:exclamation",
                    color: "info",
                }),
                actions: this._formBuilder.group({
                    confirm: this._formBuilder.group({
                        show: true,
                        label: "Okudum",
                        color: "primary",
                    }),
                    cancel: this._formBuilder.group({
                        show: true,
                        label: "Tümünü Okudum",
                        color: "primary",
                    }),
                }),
                dismissible: true,
            });

        this.openDialog(announcement);

    }


    /**
     * Butun duyurulari getirir;
     */ 
    getAllAnnouncements() {
        this._announcementsService.getAll().subscribe();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create the overlay
     */
    private _createOverlay(): void
    {
        // Create the overlay
        this._overlayRef = this._overlay.create({
            hasBackdrop     : true,
            backdropClass   : 'fuse-backdrop-on-mobile',
            scrollStrategy  : this._overlay.scrollStrategies.block(),
            positionStrategy: this._overlay.position()
                                  .flexibleConnectedTo(this._announcementsOrigin._elementRef.nativeElement)
                                  .withLockedPosition(true)
                                  .withPush(true)
                                  .withPositions([
                                      {
                                          originX : 'start',
                                          originY : 'bottom',
                                          overlayX: 'start',
                                          overlayY: 'top'
                                      },
                                      {
                                          originX : 'start',
                                          originY : 'top',
                                          overlayX: 'start',
                                          overlayY: 'bottom'
                                      },
                                      {
                                          originX : 'end',
                                          originY : 'bottom',
                                          overlayX: 'end',
                                          overlayY: 'top'
                                      },
                                      {
                                          originX : 'end',
                                          originY : 'top',
                                          overlayX: 'end',
                                          overlayY: 'bottom'
                                      }
                                  ])
        });

        // Detach the overlay from the portal on backdrop click
        this._overlayRef.backdropClick().subscribe(() => {
            this._overlayRef.detach();
        });
    }

    /**
     * Calculate the unread count
     *
     * @private
     */
    private _calculateUnreadCount(): void
    {
        let count = 0;

        if ( this.announcements && this.announcements.length )
        {
            count = this.announcements.filter(announcement => !announcement.read).length;
        }

        this.unreadCount = count;
    }
}
