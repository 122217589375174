import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation, Inject, Output, EventEmitter, Injectable, Renderer2 } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, NgForm, FormArray } from '@angular/forms';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { debounceTime, map, merge, Observable, Subject, switchMap, takeUntil } from 'rxjs';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { RegisteredCustomersPagination } from 'app/modules/payments/registeredCustomers/registeredCustomers.types';
import { RegisteredCustomersService } from 'app/modules/payments/registeredCustomers/registeredCustomers.service';
import { CommonService } from 'app/layout/common/services/common.service';
import { ToastServiceComponent } from 'app/layout/common/toast-service/toast-service.component';
import { environment } from 'environments/environment'
import { finalize } from 'rxjs/operators';
import { DataTableDirective } from 'angular-datatables';
import { DatePipe } from '@angular/common';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { Router } from '@angular/router';

export interface DialogData {
    id: number;
}

@Component({
    selector       : 'registeredCustomers-list',
    templateUrl    : './registeredCustomers.component.html',
    styleUrls: ['registeredCustomers.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations     : fuseAnimations,
    providers: [DatePipe]
})


@Injectable({
    providedIn: 'root',
})

export class RegisteredCustomersComponent implements OnInit, AfterViewInit, OnDestroy
{
    @ViewChild(DataTableDirective, {static: false})
    dtElement: DataTableDirective;
    dtOptions: any = {};

    storageUrl: string;
    session_token: string;
    baseUrl: string;
    

    customers: any; // musteriler
    selectedCustomerInformations: any; // secilen musterinin bilgileri
    templateStatus: string; // informations - subscribers
    selectedCustomerSubscribers: any; // secilen musterinin abonelikleri

    customersWaiting: boolean = false;
    queryAllStatus = false; // tum borclar sorgula butonunu disable/enable yapar
    renderTable = true; // tum borclar sorgula butonunu disable/enable yapar
    listenerFn: () => void;


    pagination: RegisteredCustomersPagination;
    tempPayload = {  // yuk verileri
        "previousPageIndex": 1,
        "pageIndex": 0,
        "pageSize": 10,
        "length": 10,
        "active": "id",
        "direction": "desc",
        "searchData": null
    };

    // @ViewChild(ToastServiceComponent) toastService: ToastServiceComponent;
    @ViewChild(MatPaginator) private _paginator: MatPaginator;
    @ViewChild(MatSort) private _sort: MatSort;

    searchInputControl: FormControl = new FormControl();
    selectedCustomerInformationsForm: FormGroup;
    selectedCustomerSubscribersForm: FormGroup;
    isLoading: boolean = false;
    _spinnerDialog : SpinnerDialog;


    /**
     * Constructor
     */
    constructor(
        // private _changeDetectorRef: ChangeDetectorRef,
        private _fuseConfirmationService: FuseConfirmationService,
        private _formBuilder: FormBuilder,
        private _registeredCustomersService: RegisteredCustomersService,
        public _MatPaginatorIntl: MatPaginatorIntl,
        private _datePipe: DatePipe,
        public _dialog: MatDialog,
        private toastService: ToastServiceComponent,
        private _renderer: Renderer2,

    )
    {
        this.session_token = localStorage.getItem('accessToken');
    }


    /**
     * On init
     */
    ngOnInit(): void
    {
        // this.newSubscribers();
        this.baseUrl = environment.baseUrl;
        this.storageUrl = environment.storageUrl;
        this.session_token = localStorage.getItem('accessToken');


        this.getCustomers();


        this._MatPaginatorIntl.itemsPerPageLabel = 'Sayfa başına gösterim';
        this._MatPaginatorIntl.firstPageLabel = 'İlk Sayfa';
        this._MatPaginatorIntl.lastPageLabel = 'Son Sayfa';
        this._MatPaginatorIntl.nextPageLabel = 'Sonraki Sayfa';
        this._MatPaginatorIntl.previousPageLabel = 'Önceki Sayfa'; 
        this._MatPaginatorIntl.getRangeLabel = (page: number, pageSize: number, length: number) => {
            if (length === 0 || pageSize === 0) {
              return `0 à ${length }`;
            }
            length = Math.max(length, 0);
            const startIndex = page * pageSize;
            const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
            return `${length} üründen ${startIndex + 1} - ${endIndex} arası listeleniyor`;
        };


        // Musterilerin form yapisi
        this.selectedCustomerInformationsForm = this._formBuilder.group({
            id               : [''],
            display_name     : ['', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
            name             : ['', [Validators.required, Validators.minLength(2), Validators.maxLength(25)]],
            surname          : ['', [Validators.required, Validators.minLength(2), Validators.maxLength(25)]],
            telephone        : ['', [Validators.required, Validators.min(5000000000), Validators.max(5900000000), Validators.minLength(10), Validators.maxLength(10)]],
            email            : ['', Validators.email],
            created_at       : [''],
        });


        // Musterilerin form yapisi
        this.selectedCustomerSubscribersForm = this._formBuilder.group({
            id               : [''],
            display_name     : [''],
            name             : [''],
            surname          : [''],
            telephone        : [''],
            email            : [''],
            created_at       : [''],
        });


        // Arama inputu
        this.searchInputControl.valueChanges
            .pipe(
                debounceTime(300),
            )
            .subscribe((response) => {
                this.searchData(response);
            });


    }


    /**
     * After view init
     */
    ngAfterViewInit()
    {
        this.listenerFn = this._renderer.listen('document', 'click', (event) => {
            
            // sil aksiyonunu dinler
            if (event.target.getAttribute('repetition') == "repetition" || event.target.getAttribute('svgicon') == "mat_solid:remove_shopping_cart") {
                if (event.target.getAttribute('selecteddata')) {


                    let subscriberId = event.target.getAttribute('selecteddata');

                    if (subscriberId) {
                        const confirmation = this._fuseConfirmationService.open({
                            message: 'Abonelik silinecek onaylıyor musunuz?',
                            actions: {
                                cancel: {
                                    label: 'Hayır'
                                },
                                confirm: {
                                    label: 'Onaylıyorum'
                                }
                            }
                        });

                        confirmation.afterClosed().subscribe((result) => {

                            if (result === 'confirmed') {

                                this._registeredCustomersService.subscriberDelete(this.selectedCustomerSubscribers.id, subscriberId)
                                    .subscribe(
                                        (response) => {
                                            if (response.status) {
                                                this.toastService.open({
                                                    toastType: "success",
                                                    toastDescription: "Abone silindi."
                                                }, 3000);
                                                this.reRender();
                                                // this.getCustomers();
                                            } else {
                                                this.toastService.open({
                                                    toastType: "error",
                                                    toastDescription: response.error.description
                                                }, 3000);

                                            }
                                        }
                                    );

                                // console.log("OK");
                                return;
                            } else {
                                // console.log("NO");
                                return;
                            }
                        });
                    }

                }
            }

            // sorgula aksiyonunu dinler
            if (event.target.getAttribute('query') == "query" || event.target.getAttribute('svgicon') == "heroicons_outline:magnifying-glass") {
                if (event.target.getAttribute('selecteddata')) {

                    let subscriberId = event.target.getAttribute('selecteddata');
                    if (subscriberId) {
                        
                        const dialogRef2 = this._dialog.open(SpinnerDialog, {disableClose: true});

                        this._registeredCustomersService.queryOne(this.selectedCustomerSubscribers.id, subscriberId)
                            .subscribe(
                                (response) => {

                                    if(dialogRef2) {
                                        dialogRef2.close();
                                    }

                                    const dialogRef = this._dialog.open(AllDebtsDialog, {
                                        disableClose: true,
                                        data: response.data,
                                        panelClass: 'invoiceBasketClass'
                                    });

                                    dialogRef.componentInstance.submitClicked.subscribe(result => {
                                        if (result?.status == true) {
                                            this.toastService.open({
                                                toastType: "success",
                                                toastDescription: "Sepete eklendi."
                                            }, 3000);
                                            if(this.renderTable == true) {
                                                this.reRender();
                                            } 
                                        } else if (result?.status == false) {
                                            this.toastService.open({
                                                toastType: "error",
                                                toastDescription: result?.description
                                            }, 3000);
                                        }

                                    });

                                    if (response.status) {
                                        this.toastService.open({
                                            toastType: "success",
                                            toastDescription: "Abone Sorgulandı."
                                        }, 3000);
                                    } else {
                                        dialogRef.close();
                                        this.toastService.open({
                                            toastType: "error",
                                            toastDescription: response.error.description
                                        }, 3000);

                                    }
                                }
                            );
                    }
                }
            }
        })
    }


    /**
     * On destroy
     */
    ngOnDestroy() {
        if (this.listenerFn) {
            this.listenerFn();
        }
    }



    /**
     * Urunleri getirir -baslangic icin
     */ 
    getCustomers() {
        this.customersWaiting = false;
        this._registeredCustomersService.getCustomers(this.tempPayload)
            .pipe(
                finalize(() => {
                    this.customersWaiting = true;
                })
            )
            .subscribe(
                (response) => {

                    if (response.status) {
                        this.customers = response.data.rows;

                        this.pagination = {
                            "length": response.data.recordsTotal,
                            "size": 10,
                            "page": 0,
                            "lastPage": 0,
                            "startIndex": 0,
                            "endIndex": 0
                        };

                    }
                }
            );
    }


    /**
     * Duzenle veya sil icin musteri bilgilerini getirir
     */ 
    toggleInformations(customerId) {

        if ( this.selectedCustomerInformations && this.selectedCustomerInformations.id === customerId )
        {

            if (this.selectedCustomerInformations.id == 0) {
                const confirmation = this._fuseConfirmationService.open({
                    title  : 'İşlem İptal',
                    message: 'Bu işlemi iptal etmek istediğinizden emin misiniz? Bu işlem geri alınamaz!',
                    actions: {
                        cancel: {
                            label: 'Hayır'
                        },
                        confirm: {
                            label: 'Evet'
                        }
                    }
                });

                confirmation.afterClosed().subscribe((result) => {

                    if (result === 'confirmed') {
                        this.customers.shift();
                        this.closeInformations();
                        return;

                    } else {
                        return;
                    }
                });
            } else {
                this.closeInformations();
                return;
            }

        }

        if (this.selectedCustomerInformations == undefined || this.selectedCustomerInformations == null || this.selectedCustomerInformations?.id != 0) {

            this.templateStatus = 'informations';
            this._registeredCustomersService.getCustomerInformation(customerId)
                .subscribe(
                    (response) => {
                        if (response.status) {
                            this.selectedCustomerInformations = response.data;

                            this.selectedCustomerInformationsForm.patchValue(response.data);

                        }
                    }
                );

        }

    }


    /**
     * Musteri bilgilerini kapatir
     */ 
    closeInformations() {
        this.templateStatus = '';
        this.selectedCustomerInformations = null;
    }


    /**
     * Abonelikler icin detaylari getirir
     */ 
    toggleSubscribers(customerId) {

        if ( this.selectedCustomerSubscribers && this.selectedCustomerSubscribers.id === customerId )
        {
            this.closeSubscribers();
            return;
        }

        this.templateStatus = 'subscribers';

        this.selectedCustomerSubscribers = {};
        this.selectedCustomerSubscribers.id = customerId;
        this.createTableSubscribers(customerId);

    }


    /**
     * Musteri bilgilerini kapatir
     */ 
    closeSubscribers() {
        this.templateStatus = '';
        this.selectedCustomerSubscribers = null;
    }

    
    /**
     * Tabloyu olusturur
     */
    abone: any;
    createTableSubscribers(customerId) {
        const that = this;

        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: 10,
            serverSide: true,
            processing: true,
            columnDefs: [{
                "defaultContent": "",
                "targets": "_all"
            }],
            ajax: {
                url: `${that.baseUrl}/dealer/customers/${customerId}/subscribers?session_token=${localStorage.getItem('accessToken')}`,
                xhr: function(res) {
                    var xhr = jQuery.ajaxSettings.xhr();
                    var setRequestHeader = xhr.setRequestHeader;
                    xhr.setRequestHeader = function(name, value) {
                        if (name == 'X-Socket-Id') return;
                        setRequestHeader.call(this, name, value);
                    }
                    return xhr;
                },
                dataFilter: function(data){
                    var json = jQuery.parseJSON( data );
                    json.recordsTotal = json.data.recordsTotal;
                    json.recordsFiltered = json.data.recordsFiltered;
                    json.draw = json.data.draw;
                    json.data = json.data.rows;
                    // that.selectedCustomerSubscribers = json;
                    that.abone = json.recordsTotal;
                    return JSON.stringify( json ); // return JSON string
                },
            },
            columns: [
                { 
                    title: '',
                    data: 'id', 
                    render: function ( data, type, row, meta ) {
                        if (row) {
                            
                            let selectedData = row.id;
                            
                            return `<mat-icon role="img" selectedData='${selectedData}' svgicon="heroicons_outline:magnifying-glass" class="mat-icon notranslate mat-tooltip-trigger cursor-pointer mat-primary" aria-hidden="true" data-mat-icon-type="svg" data-mat-icon-name="magnifying-glass" data-mat-icon-namespace="heroicons_outline"><svg query='query' selectedData='${selectedData}' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path query='query' selectedData='${selectedData}' stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"></path></svg></mat-icon>` + 
                                `<mat-icon role="img" selectedData='${selectedData}' svgicon="mat_solid:remove_shopping_cart" class="mat-icon notranslate mat-tooltip-trigger cursor-pointer mat-warn" aria-hidden="true" ng-reflect-svg-icon="mat_solid:remove_shopping_cart" ng-reflect-message="Faturayı sepetten sil" ng-reflect-color="warn" data-mat-icon-type="svg" data-mat-icon-name="remove_shopping_cart" data-mat-icon-namespace="mat_solid" aria-describedby="cdk-describedby-message-aag-1-27" cdk-describedby-host="aag-1"><svg repetition='repetition' selectedData='${selectedData}' viewBox="0 0 24 24" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path repetition='repetition' selectedData='${selectedData}' d="M22.73 22.73L2.77 2.77 2 2l-.73-.73L0 2.54l4.39 4.39 2.21 4.66-1.35 2.45c-.16.28-.25.61-.25.96 0 1.1.9 2 2 2h7.46l1.38 1.38c-.5.36-.83.95-.83 1.62 0 1.1.89 2 1.99 2 .67 0 1.26-.33 1.62-.84L21.46 24l1.27-1.27zM7.42 15c-.14 0-.25-.11-.25-.25l.03-.12.9-1.63h2.36l2 2H7.42zm8.13-2c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.08-.14.12-.31.12-.48 0-.55-.45-1-1-1H6.54l9.01 9zM7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2z"></path></svg></mat-icon>`;

                        }
                        return "NULL";
                    }
                },
                { 
                    title: 'Kurum',
                    data: 'soot_name'
                },
                { 
                    title: 'Abone No',
                    data: 'subscriber_no'
                },
                { 
                    title: 'Açıklama',
                    data: 'description'
                },
                { 
                    title: 'Tarih',
                    data: 'created_at',
                    render: function ( data, type, row, meta ) {
                        if (data) {
                            return `<span style="white-space: nowrap;">${that._datePipe.transform(data, 'dd-MM-yyyy')}</span><br><span>&nbsp;${that._datePipe.transform(data, 'HH:mm:s')}</span>`;
                        }
                        return "-";
                    },
                    class: 'text-sm'
                }
            ],
            responsive: true,
            autoWidth: true,
            dom: 'Bfrtip',
            buttons: [
                'colvis',
                'copy',
                'print',
                'excel',
            ]

        };
    }


    /**
     * Yeni abone eklemek icin dialog acar
     */ 
    newSubscribers() {

        const dialogRef = this._dialog.open(NewSubscribersDialog, {
            disableClose: true,
            data: {
                id: this.selectedCustomerSubscribers.id,
            }
        });

        dialogRef.componentInstance.submitClicked.subscribe(result => {
            if (result?.status == true) {
                this.toastService.open({
                    toastType: "success",
                    toastDescription: "Yeni abone eklendi."
                }, 3000);
                this.reRender();
            } else if (result?.status == false) {
                this.toastService.open({
                    toastType: "error",
                    toastDescription: result?.description
                }, 3000);
            }


        });



    }


    /**
     * Tabloyu yeniler
     */ 
    reRender() {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.draw();
        });
    }


    /**
     * Musteriyi gunceller
     */ 
    updateCustomer() {
        if ( this.selectedCustomerInformationsForm.invalid )
        {
            return;
        }

        this.selectedCustomerInformationsForm.disable();

        this._registeredCustomersService.updateCustomer(this.selectedCustomerInformations.id, this.selectedCustomerInformationsForm.value)
            .pipe(
                finalize(() => {
                    this.selectedCustomerInformationsForm.enable();
                })
            )
            .subscribe(
                (response) => {
                    if (response.status) {
                        this.toastService.open({
                            toastType: "success",
                            toastDescription: "Müşteri bilgileri güncellendi."
                        }, 3000);
                        this.getCustomers();
                    } else {
                        this.toastService.open({
                            toastType: "error",
                            toastDescription: response.error.description
                        }, 3000);

                    }
                }
            );
    }


    /**
     * Yeni musteri ekler -front
     */
    createCustomer() {

        // yeni musteri icin gecici bi yapi olusturur
        let newCustomer = {
            "id": 0,
            "display_name": "",
            "name": "",
            "surname": "",
            "telephone": "",
            "email": "",
            "created_at": "",
        };


        this.templateStatus = 'informations';
        this.customers.unshift(newCustomer);

        this.selectedCustomerInformations = newCustomer;
        this.selectedCustomerInformationsForm.patchValue(newCustomer);
        

    }


    /**
     * Yeni musteri ekler -backend
     */ 
    storeCustomer() {

        if ( this.selectedCustomerInformationsForm.invalid )
        {
            return;
        }

        this.selectedCustomerInformationsForm.disable();

        this._registeredCustomersService.storeCustomer(this.selectedCustomerInformationsForm.value)
            .pipe(
                finalize(() => {
                    this.selectedCustomerInformationsForm.enable();
                })
            )
            .subscribe(
                (response) => {
                    if (response.status) {
                        this.toastService.open({
                            toastType: "success",
                            toastDescription: "Yeni müşteri eklendi."
                        }, 3000);
                        this.getCustomers();
                        this.closeInformations();
                    } else {
                        this.toastService.open({
                            toastType: "error",
                            toastDescription: response.error.description
                        }, 3000);

                    }
                }
            );


    }


    /**
     * Musteriyi siler
     */ 
    hardDeleteCustomer() {

        const confirmation = this._fuseConfirmationService.open({
            title  : 'Müşteriyi Sil',
            message: 'Bu müşteriyi silmek istediğinizden emin misiniz? Bu işlem geri alınamaz!',
            actions: {
                cancel: {
                    label: 'İptal'
                },
                confirm: {
                    label: 'Sil'
                }
            }
        });

        confirmation.afterClosed().subscribe((result) => {

            if ( result === 'confirmed' )
            {
                this._registeredCustomersService.hardDeleteCustomer(this.selectedCustomerInformations?.id)
                    .subscribe(
                        (response) => {
                            if (response.status) {

                                this.toastService.open({
                                    toastType: "success",
                                    toastDescription: "Müşteri kalıcı olarak silindi."
                                }, 3000);

                                this.getCustomers();
                                this.closeInformations();

                            }
                        }
                    );

            }
        });
        
    }


    /**
     * Musteri eklemeyi iptal eder
     */
    cancel(): void
    {
        const confirmation = this._fuseConfirmationService.open({
            title  : 'İşlemi İptal Et',
            message: 'Bu işlemi iptal etmek istediğinizden emin misiniz? Bu işlem geri alınamaz!',
            actions: {
                cancel: {
                    label: 'Hayır'
                },
                confirm: {
                    label: 'Evet'
                }
            }
        });

        confirmation.afterClosed().subscribe((result) => {

            if ( result === 'confirmed' )
            {
                this.customers.shift();
                this.closeInformations();

            }
        });

    }


    /**
     * Musterilerin tum abonelerini sorgular
     */
    setIntervalForQueryAll: any;
    iQueryFinished: boolean = false;
    orderByDesc: boolean = false;
    queryAll()
    {
        const dialogRef = this._dialog.open(SpinnerDialog, {disableClose: true});
        let countRequest = 0;
        this.setIntervalForQueryAll = setInterval(() => {
            
            if (this.iQueryFinished == true) {
                clearInterval(this.setIntervalForQueryAll);
                this.iQueryFinished = false;
                dialogRef.close();
            }

            if (this.abone > 0) {
                if (!this.queryAllStatus) {

                    let payload;


                    if (countRequest == this.abone?.data?.length) {
                        payload = {
                            iQueryFinished: true,
                            orderByDesc: this.orderByDesc
                            
                        }
                    } else if (countRequest == 0){
                        payload = {
                            firstRequest: true,
                            orderByDesc: this.orderByDesc

                        }
                    } else {
                        payload = {
                            orderByDesc: this.orderByDesc
                        }
                    }

                    this.query(payload, dialogRef);


                    if (countRequest == this.abone?.data?.length) {
                        this.queryAllStatus = false;
                        clearInterval(this.setIntervalForQueryAll);
                        this.iQueryFinished = false;
                        dialogRef.close();
                        // bitti
                    }

                    ++countRequest;

                }

            } else {
                this.queryAllStatus = false;
                clearInterval(this.setIntervalForQueryAll);
                this.iQueryFinished = false;
                dialogRef.close();
                this.toastService.open({
                    toastType: "error",
                    toastDescription: "Bu müşteriye ait abonelik yok."
                }, 3000);
            }

        }, 10);
    }


    query(payload, dialogRefSpinner?) {
        this.queryAllStatus = true;
        this._registeredCustomersService.queryAll(this.selectedCustomerSubscribers?.id, payload)
            .pipe(
                finalize(() => {
                    this.queryAllStatus = false;
                })
            )
            .subscribe(
                (response) => {
                    if (response.status) {


                        if (response.data.isFinished == 1) {

                            const dialogRef = this._dialog.open(AllDebtsDialog, {
                                disableClose: true,
                                data: response.data,
                                panelClass: 'invoiceBasketClass'
                            });

                            dialogRef.componentInstance.submitClicked.subscribe(result => {
                                if (result?.status == true) {
                                    this.toastService.open({
                                        toastType: "success",
                                        toastDescription: "Sepete eklendi."
                                    }, 3000);
                                    if(this.renderTable == true) {
                                        this.reRender();
                                    } 
                                } else if (result?.status == false) {
                                    if(dialogRefSpinner) {
                                        dialogRefSpinner.close();
                                    }
                                    this.toastService.open({
                                        toastType: "error",
                                        toastDescription: result?.description
                                    }, 3000);
                                }

                            });

                            this.iQueryFinished = true;
                        
                        }

                        if (response.data?.debt_count == 0) {
                            this.abone = null;
                            if(dialogRefSpinner) {
                                dialogRefSpinner.close();
                            }
                            this.toastService.open({
                                toastType: "error",
                                toastDescription: 'Borç bilgisi bulunamadı'
                            }, 3000);
                            clearInterval(this.setIntervalForQueryAll);
                        }

                    } else {
                        if (response.error?.code == 109) {
                            this.abone = null;
                            if(dialogRefSpinner) {
                                dialogRefSpinner.close();
                            }
                            clearInterval(this.setIntervalForQueryAll);
                        }                        

                        this.toastService.open({
                            toastType: "error",
                            toastDescription: response.error.description
                        }, 3000);
                    }
                }
            );
    }


    /**
     * Arama yapar
     */ 
    searchData(payload) {
        this.tempPayload.searchData = payload;

        this.customersWaiting = false;
        this._registeredCustomersService.search(this.tempPayload)
            .pipe(
                finalize(() => {
                    this.customersWaiting = true;
                })
            )
            .subscribe(
                (response) => {
                    if (response.status) {
                        this.customers = response.data.rows;
                        this.pagination.length = response.data.recordsTotal;

                        

                    }
                }
            );
    }


    /**
     * Musterileri getirir -sayfalandirma icin
     */ 
    getServerData(event) {
        this.tempPayload.previousPageIndex = event.previousPageIndex;
        this.tempPayload.pageIndex = event.pageIndex;
        this.tempPayload.pageSize = event.pageSize;
        this.tempPayload.length = event.length;

        this._registeredCustomersService.getCustomers(this.tempPayload)
            .subscribe(
                (response) => {
                    if (response.status) {
                        this.customers = response.data.rows;

                    }
                }
            );
    }


    /**
     * Musterilerin siralanmasi 
     */ 
    sortData(event) {
        this.tempPayload.active = event.active;
        this.tempPayload.direction = event.direction;
        this.getServerData(this.tempPayload);
    }

}



@Component({
    selector: 'new-subscribers-dialog',
    templateUrl: 'newSubscribers-dialog.html',
    styleUrls: ['registeredCustomers.component.scss'],
})
export class NewSubscribersDialog {

    @ViewChild('firstNgForm') firstNgForm: NgForm;

    @Output() submitClicked = new EventEmitter<any>();

    firstForm: FormGroup;

    soots:any;  // kurumlar
    selectedSoot: any;  // secilen kurum
    selectedSootDetails: any;  // secilen kurumun detaylari

    fields: any = [];
    description: string;

    /**
     * Constructor
     */
    constructor(
        private _registeredCustomersService: RegisteredCustomersService,
        private _dialogRef: MatDialogRef<NewSubscribersDialog>,
        private _formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    )
    {
    }


    /**
     * On init
     */
    ngOnInit(): void
    {
        if (localStorage.getItem("soots")) {
            this.soots = JSON.parse(localStorage.getItem("soots"));
        } else {
            this.getSoots();
        }

        this.firstForm = this._formBuilder.group({
            soot_id               : ['', Validators.required],
            fields                : this._formBuilder.array([
                // new FormControl(null),
            ]),
            description           : [''],
        });


    }


    /**
     * Kurumlari getirir
     */ 
    getSoots() {
        this._registeredCustomersService.getSoots()
            .subscribe(
                (response) => {
                    if (response.status) {
                        this.soots = response.data;
                        localStorage.setItem("soots", JSON.stringify(this.soots));

                    }
                }
            );
    }


    /**
     * Secilen kurumu getirir
     */ 
    setSelectedSoot(response) {

        this.selectedSoot = response;
        (<FormArray>this.firstForm.get("fields")).clear();
        this.firstForm.get('soot_id').setValue(this.selectedSoot.id);
        
        this._registeredCustomersService.getSootDetails(this.selectedSoot.id)
            .subscribe(
                (response) => {
                    if (response.status) {
                        this.selectedSootDetails = response.data;
                        this.selectedSootDetails.fields.forEach((val) => {
                            (<FormArray>this.firstForm.get("fields")).push(new FormControl(null, val?.is_required == true ? Validators.required : null));
                        })

                    }
                }
            );

    }

    trackByFn(index: number, soot: any): any {
        return index;
    }


    /**
     * Kurum ekleme -onay
     */ 
    createSubscriber() {

        if ( this.firstForm.invalid )
        {
            return;
        }

        this.firstForm.disable();

        this._registeredCustomersService.newSubscribers(this.data.id, this.firstForm.value)
            .pipe(
                finalize(() => {
                    this.firstForm.enable();
                })
            )
            .subscribe(
                (response) => {
                    if (response.status) {

                        this.submitClicked.emit({
                            status: true,
                        });
                        this.firstNgForm.resetForm();
                        this.firstForm.get('soot_id').setValue(this.selectedSoot.id);


                    } else {
                        this.submitClicked.emit({
                            status: false,
                            description: response.error.description
                        });
                    }
                }
            );


    }


    /**
     * Kurum ekleme -iptal
     */ 
    cancel() {
        this._dialogRef.close({
            status: false,
        });
    }

}




@Component({
    selector: 'all-debts-dialog',
    templateUrl: 'allDebts-dialog.html',
})
export class AllDebtsDialog {

    @ViewChild(MatAccordion) accordion: MatAccordion;

    @Output() submitClicked = new EventEmitter<any>();

    invoiceAllPayCheckStatus: boolean = false; // butun faturalarin gonderilmesi aktif/pasif
    invoiceStatus: boolean = true; // ode butonu aktif/pasif
    debts = []; // odenecek faturalari tutar
    invoicePayCheckStatus = null;
    displayedColumns: string[] = ['id', 'institutions', 'name', 'fields', 'invoice_no', 'debt', 'total_commission', 'total', 'last_payment_date'];
    responseQueryOne = null;

    eye: boolean = true;
    /**
     * Constructor
     */
    constructor(
        private _registeredCustomersService: RegisteredCustomersService,
        private _dialogRef: MatDialogRef<AllDebtsDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _router: Router
    )
    {
    }


    /**
     * On init
     */
    ngOnInit(): void
    {
        if (this.data.soot) {
            this.invoicePayCheckStatus = new Array();

            for (let i = 0; i < this.data.debts.length; i++) {
                this.invoicePayCheckStatus[i] = true;
            }
            this.responseQueryOne = this.data;
        } else if (this.data.soots) {
            this.invoicePayCheckStatus = new Array();

            for (let i = 0; i < this.data.soots.length; i++) {
                this.invoicePayCheckStatus[i] = [];

                for (let j = 0; j < this.data.soots[i].subscribers.length; j++) {
                    this.invoicePayCheckStatus[i][j] = [];

                    for (let k = 0; k < this.data.soots[i].subscribers[j].debts.length; k++) {
                        this.invoicePayCheckStatus[i][j][k] = false;

                    }
                }

            }
        }

        
        this.invoiceAllPayCheckStatus = true;
        this.selectAllPayInvoices();

    }


    /**
     * Butun fatuaralari secer
     */
    selectAllPayInvoices()
    {
        if (this.data.soot) {

            if (this.invoiceAllPayCheckStatus) {
                for (let i = 0; i < this.invoicePayCheckStatus.length; i++) {
                    this.invoicePayCheckStatus[i] = true;
                }
            } else {
                for (let i = 0; i < this.invoicePayCheckStatus.length; i++) {
                    this.invoicePayCheckStatus[i] = false;
                }
            }

        } else if (this.data.soots) {
            if (this.invoiceAllPayCheckStatus) {
                for (let i = 0; i < this.invoicePayCheckStatus.length; i++) {
                    for (let j = 0; j < this.invoicePayCheckStatus[i].length; j++) {
                        for (let k = 0; k < this.invoicePayCheckStatus[i][j].length; k++) {
                            this.invoicePayCheckStatus[i][j][k] = true;
                        }
                    }
                }
            } else {
                for (let i = 0; i < this.invoicePayCheckStatus.length; i++) {
                    for (let j = 0; j < this.invoicePayCheckStatus[i].length; j++) {
                        for (let k = 0; k < this.invoicePayCheckStatus[i][j].length; k++) {
                            this.invoicePayCheckStatus[i][j][k] = false;
                        }
                    }
                }
            }
        }
        
        this.calculateInvoice();

    }

    /**
     * Secilen faturalarin tutarini hesaplar
     */
    calculateInvoice()
    {
        this.data.totalDebt = 0;
        this.data.totalCommission = 0;
        this.data.totalDebtCommission = 0;

        if (this.data.soot) {
            for (let i = 0; i < this.data.debts.length; i++) {
                if (this.invoicePayCheckStatus[i] == true) {
                    this.data.totalDebt += this.data.debts[i].debt;
                    this.data.totalCommission += this.data.debts[i].total_commission;
                    this.data.totalDebtCommission = this.data.totalDebt + this.data.totalCommission;
                }
            }
            
        } else if (this.data.soots) {
        
            for (let i = 0; i < this.data.soots.length; i++) {
                for (let j = 0; j < this.data.soots[i].subscribers.length; j++) {
                    for (let k = 0; k < this.data.soots[i].subscribers[j].debts.length; k++) {
                        if (this.invoicePayCheckStatus[i][j][k] == true) {
                            this.data.totalDebt += this.data.soots[i].subscribers[j].debts[k].debt;
                            this.data.totalCommission += this.data.soots[i].subscribers[j].debts[k].total_commission;
                            this.data.totalDebtCommission = this.data.totalDebt + this.data.totalCommission;
                        }
                    }
                }

            }
        }

    }


    /**
     * Tek faturayi secer
     */ 
    selectPayInvoice()
    {
        let flag = false;

        if (this.data.soot) {

            for (let i = 0; i < this.invoicePayCheckStatus.length; i++) {
                if (this.invoicePayCheckStatus[i] == false) {
                    this.invoiceAllPayCheckStatus = false;
                    flag = true;
                }
                this.calculateInvoice();
            }
        } else if (this.data.soots) {
            for (let i = 0; i < this.invoicePayCheckStatus.length; i++) {
                for (let j = 0; j < this.invoicePayCheckStatus[i].length; j++) {
                    for (let k = 0; k < this.invoicePayCheckStatus[i][j].length; k++) {
                        if (this.invoicePayCheckStatus[i][j][k] == false) {
                            this.invoiceAllPayCheckStatus = false;
                            flag = true;
                        }
                        this.calculateInvoice();    
                    }
                }
            }
        }


        if (flag == false) {
            this.invoiceAllPayCheckStatus = true;
        } else {
            this.invoiceAllPayCheckStatus = false;
        }

    }


    /**
     * Sepete Ekle
     */ 
    basket() {
        this.debts = [];
        let debtsCount = 0;
        
        if (this.data.soot) {

            for (let i = 0; i < this.data.debts.length; i++) {
                if (this.invoicePayCheckStatus[i] == true) {
                    debtsCount++;

                    this.debts.push(this.data.debts[i]?.uid);
                    
                } else {
                    // console.log("secilmemis")
                }
            }
        } else if (this.data.soots) {
            for (let i = 0; i < this.data.soots.length; i++) {
                for (let j = 0; j < this.data.soots[i].subscribers.length; j++) {
                    for (let k = 0; k < this.data.soots[i].subscribers[j].debts.length; k++) {
                        if (this.invoicePayCheckStatus[i][j][k] == true) {
                            debtsCount++;

                            this.debts.push(this.data.soots[i].subscribers[j].debts[k]?.uid);
                            
                        } else {
                            // console.log("secilmemis")
                        }
                    }
                }

            }

        }


        if (debtsCount < 1) {
            return;
        }

        this.invoiceStatus = false;

        this._registeredCustomersService.basket({
            debts: this.debts
        })
            .subscribe(
                (response) => {
                    this.invoiceStatus = true;

                    if (response.status) {
                        this.submitClicked.emit({
                            status: true,
                        });

                    } else {
                        this.submitClicked.emit({
                            status: false,
                            description: response.error.description
                        });

                    }
                }
            );
    }


    /**
     * Sepete ekleme -iptal
     */ 
    cancel() {
        this._dialogRef.close({
            status: false,
        });
    }

    /**
     * Sepete gider
     */ 
    getBasketRoute() {
        this.cancel();
        this._router.navigate(['/payments/invoice/basket']);
    }

}




@Component({
    selector: 'spinner-dialog',
    templateUrl: 'spinner-dialog.html',
    styleUrls: ['registeredCustomers.component.scss'],
})
export class SpinnerDialog {
    /**
     * Constructor
     */

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    )
    {
    }
}