import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { Router, RouterModule } from '@angular/router';
import { FuseAlertModule } from '@fuse/components/alert';
import { ToastServiceComponent } from 'app/layout/common/toast-service/toast-service.component';

@Component({
    selector     : 'kyc-control',
    templateUrl  : './kyc.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone : true,
    imports: [
        MatIconModule,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        ReactiveFormsModule,
        MatButtonModule,
        FuseAlertModule,
        RouterModule
      ],
})
export class KycComponent implements OnInit
{
    composeForm: FormGroup;
    quillModules: any = {
        toolbar: [
            ['bold', 'italic', 'underline'],
            [{align: []}, {list: 'ordered'}, {list: 'bullet'}],
            ['clean']
        ]
    };
    departments: any;

    @ViewChild(ToastServiceComponent) toastService: ToastServiceComponent;


    /**
     * Constructor
     */
    constructor(
        private _router: Router,
        public matDialogRef: MatDialogRef<KycComponent>,
        private _formBuilder: FormBuilder,
        // private _sharedService: SharedService,

        // private _supportListComponent: SupportListComponent,


    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.composeForm = this._formBuilder.group({
            department_id     : ['', Validators.required],
            title: ['', Validators.required],
            message   : ['', [Validators.required]]
        });
    }

    redirectForKyc() {
        this.matDialogRef.close();
        this._router.navigate(['/settings/representation/kyc-verification']);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------


    
    
    /**
     * Discard the message
    */
   discard(): void
   {
        // Close the dialog
        this.matDialogRef.close();
    }
}
