import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from 'app/core/http/http.service';


@Injectable({
	providedIn: 'root'
})
export class PushNotification
{

    /**
     * Constructor
     */
    constructor(
        private _httpService: HttpService,
    )
    {
    }


    /**
     * push-notification kayiti icin bilgileri gonderir
     */ 
    addPushSubscriber(payload) {
        return this._httpService.post('/dealer/auth/notifications/pushNotification', payload);
        
    }
    
}
