<button
    mat-icon-button
    matTooltip="Güncellemeler"
    (click)="update()"
    #notificationsOrigin>

    <ng-container *ngIf="upgradeCount > 0">
        <span class="absolute top-0 right-0 left-0 flex items-center justify-center h-3">
            <span class="flex items-center justify-center shrink-0 min-w-4 h-4 px-1 ml-4 mt-2.5 rounded-full bg-primary text-indigo-50 text-xs font-medium">
                Yeni
            </span>
        </span>
    	<img src="assets/gif/fire.gif">
    </ng-container>
	
    <ng-container *ngIf="upgradeCount == 0">
		<mat-icon [ngClass]="{'text-yellow-500': upgradeCount > 0}" svgIcon="heroicons_outline:fire"></mat-icon>
    </ng-container>

</button>
