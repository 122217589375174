import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, filter, map, Observable, of, switchMap, take, tap, throwError } from 'rxjs';
import { HttpService } from 'app/core/http/http.service';

@Injectable({
    providedIn: 'root'
})
export class RegisteredCustomersService
{

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _httpService: HttpService,
    )
    {
    }


    /**
     * Musterileri getirir
     */
    getCustomers(payload)
    {
        return this._httpService.get(`/dealer/customers/getCustomers`, payload);
    }

     /**
     * Tüm Musterileri getirir
     */
     getAllCustomers()
     {
         return this._httpService.get(`/dealer/customers`);
     }


    /**
     * Musterileri bilgilerini getirir
     */
    getCustomerInformation(customerId)
    {
        return this._httpService.get(`/dealer/customers/${customerId}`);
    }
    

    /**
     * Musterileri bilgilerini getirir
     */
    getCustomerSubscribers(customerId)
    {
        return this._httpService.get(`/dealer/customers/${customerId}/subscribers`);
    }


    /**
     * Kurumlari getirir
     */
    getSoots()
    {
        return this._httpService.get(`/dealer/soots`);
    }


    /**
     * Kurum detaylarini getirir
     */
    getSootDetails(sootId)
    {
        return this._httpService.get(`/dealer/soots/${sootId}`);
    }


    /**
     * Yeni abonelik getirir
     */
    newSubscribers(customerId, payload)
    {
        return this._httpService.post(`/dealer/customers/${customerId}/subscribers`, payload);
    }


    /**
     * Musteri bilgilerini gunceller
     */
    updateCustomer(customerId, payload)
    {
        return this._httpService.put(`/dealer/customers/${customerId}`, payload);
    }


    /**
     * Secilen musteriyi siler
     */
    hardDeleteCustomer(customerId)
    {
        return this._httpService.delete(`/dealer/customers/${customerId}`);
    }


    /**
     * Yeni musteri ekler
     */
    storeCustomer(payload)
    {
        return this._httpService.post(`/dealer/customers`, payload);
    }


    /**
     * Musterilerin tum abonelerini sorgular
     */
    queryAll(customerId, payload)
    {
        return this._httpService.post(`/dealer/customers/${customerId}/queryAll`, payload);
    }


    /**
     * Musterinin tek abonesini sorgular
     */
    queryOne(customerId, subscriberId)
    {
        return this._httpService.post(`/dealer/customers/${customerId}/subscribers/${subscriberId}`);
    }


    /**
     * Faturalari sepete ekler
     */
    basket(payload)
    {
        return this._httpService.post(`/dealer/invoice/basket`, payload);
    }


    /**
     * Musteri icin arama yapar
     */
    search(payload)
    {
        return this._httpService.get(`/dealer/customers/search`, payload);
    }


    /**
     * Secilen aboneyi siler
     */
    subscriberDelete(customerId, subscriberId)
    {
        return this._httpService.delete(`/dealer/customers/${customerId}/subscribers/${subscriberId}`);
    }

}
