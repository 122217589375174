<sms-verify (result)="store($event)"></sms-verify>
<toast-service></toast-service>

<div class="w-140">

    <div class="relative flex">
        <div class="flex w-full justify-center my-2 text-2xl font-extrabold tracking-tight leading-tight text-right">
            PARA ÇEK</div>
        <div class="absolute right-0">
            <button type="button" [color]="'warn'" mat-icon-button matTooltip="Kapat" (click)="cancel()">
                <mat-icon [svgIcon]="'mat_solid:close'"></mat-icon>
            </button>
        </div>
    </div>


    <!-- TUTAR -->
    <form [formGroup]="firstForm" class="flex flex-col justify-center rounded-xl" #firstNgForm="ngForm">

        <div class="flex w-full">
            <search-input class="quick-withdrawal_bankAccounts w-full" [formControlName]="'iban'"
                [escapeFromFocus]="false" [placeholder]="'Banka Hesabı Seçiniz'" [labelName]="'Banka Hesabı'"
                [dataSource]="bankAccounts" [searchData]="'iban'" [displayCombineData]="displayCombineData"
                (result)="setSelectedBankAccount($event)"></search-input>
        </div>

        <ng-template matStepLabel>Tutar Giriniz</ng-template>

        <div class="w-full rounded-xl m-auto">

            <mat-form-field class="w-full">
                <mat-label class="text-sm text-secondary">Gönderilecek Tutar</mat-label>
                <input type="text" (ngModelChange)="getGetCommission()" [formControlName]="'amount'" maxlength="15"
                    mask="separator" thousandSeparator="." prefix="₺" inputmode="numeric" class="text-3xl border-none"
                    id="amount" [formControlName]="'amount'" matInput>
                <img class="w-6 h-6 mx-2" src="https://platform-storage.onaymatik.com/currencies_symbols/TRY.png">
                <label class="text-3xl font-medium">TRY</label>

            </mat-form-field>

            <div class="invoice-check-card mx-2 text-right">
                <input id="commission_inOut" name="commission_inOut" type="checkbox"
                    (change)="commissionInformationChanges()">
                <label for="commission_inOut" class="text-sm text-secondary">
                    Komisyonu İçinden Al
                </label>
            </div>

            <span class="flex flex-row justify-between">
                <span class="flex flex-row items-center">
                    <mat-icon class="mr-1" svgIcon="heroicons_solid:plus-circle"></mat-icon>
                    <label class="text-sm mt-0.5 font-medium ps-6 ms-6">{{ commissionResponse.total_commission | number:
                        '1.2-2' }} TRY</label>
                </span>
                <span class="text-sm text-secondary whitespace-nowrap flex items-center">Komisyon Ücreti</span>
            </span>
            <span class="flex flex-row justify-between">
                <span class="flex flex-row items-center">
                    <mat-icon class="mr-1" svgIcon="heroicons_solid:minus-circle"></mat-icon>
                    <label class="text-sm mt-0.5 font-medium ps-6 ms-6">{{ commissionResponse.receiver_amount | number:
                        '1.2-2' }} TRY</label>
                </span>
                <span class="text-sm text-secondary">Alıcıya Ulaşacak Tutar</span>
            </span>
            <span class="flex flex-row justify-between">
                <span class="flex flex-row items-center">
                    <mat-icon class="mr-1 text-primary" style="transform: rotate(90deg)"
                        svgIcon="heroicons_solid:pause"></mat-icon>
                    <label class="text-sm mt-0.5 font-bold text-primary ps-6 ms-6">{{ commissionResponse.total_amount |
                        number: '1.2-2' }} TRY</label>
                </span>
                <span class="text-sm text-secondary">Hesaptan Düşülecek Tutar</span>
            </span>

            <div class="flex items-center mt-6" *ngIf="!getApiCommissionStatus">
                <button class="fuse-mat-button-large rounded w-full" mat-flat-button [color]="'primary'"
                    [disabled]="firstForm.invalid || firstForm.disabled" (click)="smsVerifySend()">
                    <span class="text-xl md:text-3xl lg:text-4xl" *ngIf="!firstForm.disabled">
                        Devam Et
                    </span>
                    <mat-progress-spinner *ngIf="firstForm.disabled" [diameter]="24"
                        [mode]="'indeterminate'"></mat-progress-spinner>
                </button>
            </div>

            <div class="flex items-center mt-6" *ngIf="getApiCommissionStatus">
                <button class="fuse-mat-button-large rounded w-full" mat-flat-button [color]="'primary'"
                    [disabled]="firstForm.invalid || firstForm.disabled" (click)="getCommission()">
                    <span class="text-xl md:text-3xl lg:text-4xl" *ngIf="!firstForm.disabled">
                        İşlem Ücretini Getir
                    </span>
                    <mat-progress-spinner *ngIf="firstForm.disabled" [diameter]="24"
                        [mode]="'indeterminate'"></mat-progress-spinner>
                </button>
            </div>


        </div>

    </form>

</div>