<div class="flex flex-col flex-auto overflow-y-auto h-full">
	<div class="flex flex-row justify-end">

	    <button
	    	[color]="'warn'"
		    mat-icon-button
		    matTooltip="Kapat"
		    (click)="close()">
		    <mat-icon [svgIcon]="'mat_solid:close'"></mat-icon>
		</button>


	</div>

	<div *ngIf="responsePayments?.facility_type_id == 15">

		<!-- odeme basarili -->
		<div *ngIf="responsePayments?.content?.status == true" class="flex flex-col rounded-xl overflow-hidden mt-6 bg-card">

			<div class="flex flex-col mb-2">
				<div *ngFor="let key of Object.keys(responsePayments?.content?.data?.kurumListe);">
					<div *ngFor="let abone of Object.keys(responsePayments?.content?.data?.kurumListe[key]?.aboneListe);"
					class="flex flex-col mb-2 bg-default rounded-xl">
					
						<table class="mat-table-bg-none" mat-table [dataSource]="responsePayments?.content?.data?.kurumListe[key]?.aboneListe[abone]?.debts">


							<ng-container matColumnDef="uid">
								<th class="px-2 text-center border-r" mat-header-cell *matHeaderCellDef> {{ responsePayments?.content?.data?.kurumListe[key]?.soot?.name }} </th>
								<td class="px-2 text-center text-sm" style="border-left: 1px solid rgba(var(--fuse-border-rgb), var(--tw-border-opacity)) !important;" mat-cell *matCellDef="let element let i = index"> {{ responsePayments?.content?.data?.kurumListe[key]?.aboneListe[abone]?.subscriber?.name }} </td>
							</ng-container>

							<ng-container matColumnDef="id">
								<th class="px-2 text-center border-r" mat-header-cell class="text-center" *matHeaderCellDef> Durumlar </th>
								<td [ngClass]="{'mat-table-last-border-none': i === responsePayments?.content?.data?.kurumListe[key]?.aboneListe[abone]?.debts.length - 1}" class="px-2 text-center text-sm" style="border-left: 1px solid rgba(var(--fuse-border-rgb), var(--tw-border-opacity)) !important;" mat-cell class="text-center" *matCellDef="let element let i = index">
									<div class="flex flex-col items-center justify-center">
										
										<span 
											class="flex flex-col text-sm font-normal text-secondary"
											*ngIf="element.status == 1">
											<span class="flex justify-center items-center" style="color: #0d9488 !important;">
												<i
													class="rounded mr-1.5 inline-block h-1.5 w-1.5 align-middle"
													style="background: #0d9488;"></i>
												Ödendi
											</span>
											<span class="fuse-bg-color text-white cursor-pointer rounded">
												<a 
													href="{{baseUrl}}/dealer/invoice/payments/{{element.uid}}/receipt?session_token={{session_token}}"
													target="_blank" >
												Dekont</a>
											</span>
										</span>

										<span 
											class="flex justify-center items-center text-sm font-normal text-secondary"
											style="color: #f43f5f !important;"
											*ngIf="element.status != 1">
											<i
												class="rounded mr-1.5 inline-block h-1.5 w-1.5 align-middle"
												style="background: #f43f5f;"></i>
											Ödenemedi
										</span>

						            </div>
								</td>
							</ng-container>

							<ng-container matColumnDef="invoice_no">
								<th class="px-2 text-center border-r" mat-header-cell *matHeaderCellDef> Fatura No </th>
								<td [ngClass]="{'mat-table-last-border-none': i === responsePayments?.content?.data?.kurumListe[key]?.aboneListe[abone]?.debts.length - 1}" class="px-2 text-center text-sm" style="border-left: 1px solid rgba(var(--fuse-border-rgb), var(--tw-border-opacity)) !important;" mat-cell *matCellDef="let element let i = index"> {{ element.invoice_no }} </td>
							</ng-container>

							<ng-container matColumnDef="debt">
								<th class="px-2 text-center border-r" mat-header-cell *matHeaderCellDef> Borç </th>
								<td [ngClass]="{'mat-table-last-border-none': i === responsePayments?.content?.data?.kurumListe[key]?.aboneListe[abone]?.debts.length - 1}" class="px-2 text-center text-sm" style="border-left: 1px solid rgba(var(--fuse-border-rgb), var(--tw-border-opacity)) !important;" mat-cell *matCellDef="let element let i = index"> {{ element.debt | number: '1.2-2' }} ₺ </td>
							</ng-container>

							<ng-container matColumnDef="total_commission">
								<th class="px-2 text-center border-r" mat-header-cell *matHeaderCellDef> İşlem Tutar </th>
								<td [ngClass]="{'mat-table-last-border-none': i === responsePayments?.content?.data?.kurumListe[key]?.aboneListe[abone]?.debts.length - 1}" class="px-2 text-center text-sm" style="border-left: 1px solid rgba(var(--fuse-border-rgb), var(--tw-border-opacity)) !important;" mat-cell *matCellDef="let element let i = index"> {{ element.total_commission | number: '1.2-2' }} ₺ </td>
							</ng-container>

							<ng-container matColumnDef="total">
								<th class="px-2 text-center border-r" mat-header-cell *matHeaderCellDef> Toplam Tutar </th>
								<td [ngClass]="{'mat-table-last-border-none': i === responsePayments?.content?.data?.kurumListe[key]?.aboneListe[abone]?.debts.length - 1}" class="px-2 text-center text-sm" style="border-left: 1px solid rgba(var(--fuse-border-rgb), var(--tw-border-opacity)) !important;" mat-cell *matCellDef="let element let i = index"> {{ element.debt + element.total_commission | number: '1.2-2' }} ₺ </td>
							</ng-container>

							<ng-container matColumnDef="last_payment_date">
								<th class="px-2 text-center border-r" mat-header-cell *matHeaderCellDef> Son Ödeme Tarihi </th>
								<td [ngClass]="{'mat-table-last-border-none': i === responsePayments?.content?.data?.kurumListe[key]?.aboneListe[abone]?.debts.length - 1}" class="px-2 text-center text-sm" style="border-left: 1px solid rgba(var(--fuse-border-rgb), var(--tw-border-opacity)) !important;" mat-cell *matCellDef="let element let i = index"> {{ element.last_payment_date | date: 'dd.MM.y' }} </td>
							</ng-container>

							<tr mat-header-row *matHeaderRowDef="displayedColumnsResult"></tr>
							<tr mat-row *matRowDef="let row; columns: displayedColumnsResult;"></tr>
						</table>

					</div>
				</div>
			</div>

			<div class="flex flex-col mt-4 md:mt-6 mb-2 text-center">
				<div class="grid grid-cols-3 gap-3">
					<div class="flex flex-col fuse-border-color-right-1">
						<span class="text-sm text-secondary">Borç Toplam</span>
						<span class="font-medium">{{ responsePayments?.content?.data?.totals?.debt | number: '1.2-2' }} ₺</span>
					</div>
					<div class="flex flex-col fuse-border-color-right-1">
						<span class="text-sm text-secondary">İşlem Bedeli</span>
						<span class="font-medium">{{ responsePayments?.content?.data?.totals?.total_commission | number: '1.2-2' }} ₺</span>
					</div>
					<div class="flex flex-col">
						<span class="text-sm text-secondary">Genel Toplam</span>
						<span class="font-medium">{{ responsePayments?.content?.data?.totals?.total | number: '1.2-2' }} ₺</span>
					</div>
				</div>
			</div>

			<div class="flex justify-center overflow-hidden mt-2 md:mt-4 lg:mt-6">

	            <button
	            	*ngIf="responsePayments?.content?.data?.faturaOdemeGrup_uid"
	                class="px-6"
	                (click)="getReceipt()"
	                mat-flat-button
	                [color]="'primary'">
	                <span>
						Toplu Dekont
	                </span>
	            </button>
			</div>

		</div>

		<!-- odeme basarisiz -->
		<div *ngIf="responsePayments?.content?.status == false" class="flex flex-col rounded-xl overflow-hidden mt-2 bg-card p-4">
			<span class="text-lg">Fatura ödemeniz BAŞARISIZ oldu.</span>
			<hr>
			<span>
				<span class="font-semibold text-md">
					Hata Nedeni:	
				</span>
				<span>
					 {{ responsePayments?.content?.error?.description }}
				</span>
			</span>
		</div>	

		<!-- odeme cozumlenemedi -->
		<div *ngIf="responsePayments?.content?.status == null">
			<span class="text-lg">Fatura ödemeniz BAŞARISIZ oldu.</span>
			<hr>
			<span>
				<span class="font-semibold text-md">
					Hata Nedeni:	
				</span>
				<span>
					 {{ responsePayments?.content }}
				</span>
			</span>
		</div>

	</div>


	<div *ngIf="responsePayments?.content == false" class="flex flex-col rounded-xl overflow-hidden mt-6 bg-card">
		<span class="text-lg">İşlem BAŞARISIZ oldu.</span>
		<hr>
		<span>
			<span class="font-semibold text-md">
				Hata Nedeni:	
			</span>
			<span>
				Tanımsız bir hata oluştu.
			</span>
		</span>
	</div>

	<div *ngIf="responsePayments?.facility_type_id != 15" class="flex flex-col rounded-xl overflow-hidden mt-6 bg-card">
		{{ responsePayments?.content }}
	</div>

	


</div>