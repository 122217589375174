import { AfterViewInit, Component, OnInit, ViewChild, ViewEncapsulation, Input, Output, EventEmitter, forwardRef, ElementRef } from '@angular/core';;
import { animate, state, style, transition, trigger} from '@angular/animations';
import { FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';

@Component({
    selector     : 'search-input',
    templateUrl  : './search-input.component.html',
    encapsulation: ViewEncapsulation.None,
    animations: [
    	trigger('detailExpand', [
			state('collapsed', style({height: '0px', minHeight: '0'})),
			state('expanded', style({height: '*'})),
			transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
		]),
	],

    // ControlValueAccessor eklendi //
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SearchInputComponent),
            multi: true
        }
    ],
    // ControlValueAccessor eklendi //
})

export class SearchInputComponent implements ControlValueAccessor
{

    // ControlValueAccessor eklendi //
    private onChange: (value: any) => void = () => {};
    private onTouched: () => void = () => {};
    // ControlValueAccessor eklendi //


    filteredOptions: Observable<string[]>;
    myControl = new FormControl('');

    @Input() dataSource: any[] = []; // aranacak veri
    @Input() searchData: string; // aranacak olan alan
    @Input() displayData: string; // gosterilecek olan alan
    @Input() displayCombineData: any = {data1: null, data2: null, status: false}; // gosterilecek olan alan
    @Input() labelName: string; // labelde gosterilecek
    @Input() placeholder: string;	// placeholder
    @Input() disabled: boolean = false;   // pasif/aktif
    @Input() escapeFromFocus: boolean = false;

    @Output() result = new EventEmitter<any>(); // donen sonuc

    @ViewChild('inputRef') inputRef: ElementRef;

    ngOnInit(): void
    {
        if (this.disabled) {
            this.myControl.disable();
        }
    }

    // ControlValueAccessor eklendi //
    writeValue(value: any): void {
        this.myControl.setValue(value, { emitEvent: false });
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    // Değer değiştiğinde form kontrolünü güncellemek için
    updateValue(value: any): void {
        this.myControl.setValue(value);
        this.onChange(value);
    }
    // ControlValueAccessor eklendi //

    
	/**
	 * baslatan
	 */ 
	start() {
        if( this.escapeFromFocus ){
            if (this.displayCombineData.status == 'bankAccounts') {
                this.filteredOptions = this.myControl.valueChanges.pipe(
                    // startWith(''), // bunun yerine rxjs filter kullanıldı,
                    filter(value => typeof value === 'string' && value.length > 0),
                    map(value => {
                        const iban = typeof value === 'string' ? value : value[this.searchData];
                        return iban ? this.filter(iban as string) : this.dataSource.slice();
                    }),
                );
                    
            } else {    
                this.filteredOptions = this.myControl.valueChanges.pipe(
                    // startWith(''), // bunun yerine rxjs filter kullanıldı,
                    filter(value => typeof value === 'string' && value.length > 0),
                    map(value => {
                        const name = typeof value === 'string' ? value : value[this.searchData];
                        return name ? this.filter(name as string) : this.dataSource.slice();
                    }),
                );
            }
        }else{
            if (this.displayCombineData.status == 'bankAccounts') {
                this.filteredOptions = this.myControl.valueChanges.pipe(
                    startWith(''), // bunun yerine rxjs filter kullanıldı,
                    // filter(value => typeof value === 'string' && value.length > 0),
                    map(value => {
                        const iban = typeof value === 'string' ? value : value[this.searchData];
                        return iban ? this.filter(iban as string) : this.dataSource.slice();
                    }),
                );
                    
            } else {    
                this.filteredOptions = this.myControl.valueChanges.pipe(
                    startWith(''), // bunun yerine rxjs filter kullanıldı,
                    // filter(value => typeof value === 'string' && value.length > 0),
                    map(value => {
                        const name = typeof value === 'string' ? value : value[this.searchData];
                        return name ? this.filter(name as string) : this.dataSource.slice();
                    }),
                );
            }
        }

		this.myControl.valueChanges.subscribe(value => {
            this.onChange(value);
        });
	}


	/**
	 * filtreleyen
	 */ 
	filter(value: string): string[] {
        const filterValue = value.toLocaleLowerCase();
        return this.dataSource.filter(option => eval(`option.${this.searchData}.toLocaleLowerCase().includes(filterValue)`));
    }


    /**
     * goruntuleyen
     */ 
    displayFn(model): string {
        if (this.displayCombineData.status == 'mobile') {
            return model && eval(`model.${this.displayData} ? model.${this.displayData} : model.name + ' ' + model.amounts.total`) + '₺';
        } 
        
        if (this.displayCombineData.status == 'bankAccounts') {
                return model && eval(`model.${this.displayData} ? model.${this.displayData} : model.iban`);
        }
        
        else {
            return model && eval(`model.${this.displayData} ? model.${this.displayData} : model.name`);
        }
    }
    

    
    /**
     * secilen deger
     */ 
    selected(event) {
    	this.result.emit(event.option.value)

        // ControlValueAccessor eklendi //
        const selectedValue = event.option.value;
        this.myControl.setValue(selectedValue, { emitEvent: false }); // Eğer form kontrol değerini de günceller
        this.onChange(selectedValue);
        // ControlValueAccessor eklendi //
        
    }


    /**
     * sifirlama
     */
    reset() {
        this.myControl.reset('');
    }


    /**
     * pasif
     */ 
    disable() {
        this.myControl.disable();
    }


    /**
     * aktif
     */ 
    enable() {
        this.myControl.enable();
    }


    focusOnIt(){
        this.inputRef.nativeElement.focus();
    }
    
}
