<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation
    class="dark bg-gray-900 print:hidden"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="navigation.default"
    [opened]="!isScreenSmall">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <div class="flex items-center w-full p-4">
            <!-- Logo -->
            <button>
                <div [routerLink]="['/']" class="flex items-center justify-center pr-2">
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" version="1.2" viewBox="0 0 64 81" height="81" class="h-10" style="fill: rgba(var(--fuse-primary-rgb)) !important;">
                        <g id="Layer">
                            <path id="Layer" class="s0" d="m31.6 42c2.5 1.5 4.5 1.4 7 1.4q3.8 0 7-1.4 3.3-1.4 5.7-3.9 2.4-2.4 3.8-5.7 1.4-3.2 1.4-6.8 0-3.7-1.4-7-1.4-3.3-3.8-5.7-2.4-2.4-5.7-3.8-3.2-1.4-7-1.4-3.7 0-7 1.4-3.3 1.4-5.7 3.8-2.4 2.4-3.8 5.7c-0.9 2.2-1.4 3.7-1.4 7 0 1.6-6.7 1.7-6.7 0 0-4.5 0.7-6.6 1.9-9.6q2-4.5 5.3-7.8 3.4-3.3 7.9-5.3 4.4-1.9 9.5-1.9 5.1 0 9.6 1.9 4.5 2 7.8 5.3 3.3 3.3 5.2 7.8 2 4.5 2 9.6 0 5.1-2 9.5-1.9 4.5-5.2 7.8-3.3 3.3-7.8 5.2-4.5 2-9.6 2c-3.5 0-5.5-0.3-9.9-2.1-5.2-2.1-2.3-9.2 2.9-6z"/>
                            <path id="Layer" fill-rule="evenodd" class="s0" d="m20.6 23.6c0 3.4 0 29.4 0 35.4q0 9 0 14.1 0 0 0 0.1 0 0.1 0 0.1 7.6-0.9 13.5-5.8c4.3-3.5 5.6-4.6 8.7-13.2 0.7-2.1 8.5-2.7 3.7 8.1-2 4.4-4.4 6.9-7.6 9.6q-4.4 4-10 6.1-7.2 2.7-14.9 1.9 0-5.5 0-16.4c0-7.2 0-34.3 0-38"/>
                            <path id="Layer" class="s0" d="m6.3 50.6c-1.6 0.9-3.6 0.4-4.5-1.2-1-1.6-0.4-3.7 1.2-4.6l25.4-14.7c1.6-0.9 3.6-0.3 4.5 1.3 1 1.6 0.4 3.6-1.2 4.5z"/>
                            <path id="Layer" class="s0" d="m6.3 38.9c-1.6 0.9-3.6 0.3-4.5-1.3-1-1.6-0.4-3.6 1.2-4.5l25.4-14.7c1.6-0.9 3.6-0.3 4.5 1.2 1 1.6 0.4 3.7-1.2 4.6z"/>
                        </g>
                    </svg> -->
                    <img class="w-12" src="assets/images/logo/onaymatik-icon.svg">
                </div>
            </button>
            <!-- Components -->
            <div class="flex items-center ml-auto">
                <!-- <notifications></notifications> -->
                <!-- <div class="flex bg-card rounded">
                    <div class="m-auto ml-2 font-bold">{{user.name}}</div> -->
                    <user [showAvatar]="false"></user>
                <!-- </div> -->
            </div>
        </div>
        <div class="p-4">
            <div class="bg-card rounded-lg">
                <div class="flex justify-between align-center p-1">
                    <div class="font-bold text-sm my-auto">Bakiye</div>
                    <div class="text-xl font-bold text-primary">{{ balance | money }} ₺</div>
                </div>

                <div class="flex justify-between align-center p-1">
                    <div class="font-bold text-sm my-auto">Onay Para</div>
                    <div class="text-xl font-bold text-warn">{{ cashback_balance | money }} ₺</div>
                </div>

                <div *ngIf="main_dealer_debt > 0" class="flex justify-between align-center p-1">
                    <div class="font-bold text-sm my-auto">A.T. Borç</div>
                    <div class="text-xl font-bold text-primary">{{ main_dealer_debt | money }} ₺</div>
                </div>

                <div *ngIf="main_dealer_balance > 0" class="flex justify-between align-center p-1">
                    <div class="font-bold text-sm my-auto">A.T. Bakiye</div>
                    <div class="text-xl font-bold text-primary">{{ main_dealer_balance | money }} ₺</div>
                </div>

                <div class="flex pb-4 px-3 pr-2">
                    <button class="w-full mr-1">
                        <div [routerLink]="['/myAccounts/paymentNotifications']" class="bg-primary font-bold rounded p-1 px-4 text-sm text-center m-1">YATIR</div>
                    </button>
                    <button class="w-full ml-1">
                        <div (click)="openWithdrawalDialog()" class="bg-default font-bold rounded p-1 px-5 text-sm text-center m-1">ÇEK</div>
                    </button>
                </div>
            </div>
            
        </div>

        <!-- User -->
        <!-- <div class="flex flex-col items-center w-full px-4 mb-4">
            <div class="relative w-18 h-18">
                <img
                    class="w-full h-full rounded-full"
                    *ngIf="user.avatar"
                    [src]="user.avatar"
                    alt="User avatar">
                <mat-icon
                    class="icon-size-18"
                    *ngIf="!user.avatar"
                    [svgIcon]="'heroicons_solid:user-circle'"></mat-icon>
            </div>
            <div class="flex flex-col items-center justify-center w-full mt-2">
                <div class="w-full whitespace-nowrap text-ellipsis overflow-hidden text-center leading-normal font-medium">
                    {{user.name}}
                </div>
                <div class="w-full mt-0.5 whitespace-nowrap text-ellipsis overflow-hidden text-center text-md leading-normal font-medium text-secondary">
                    {{user.email}}
                </div>
            </div>
        </div> -->

    </ng-container>
    <!-- Navigation footer hook -->
    <ng-container fuseVerticalNavigationContentFooter>
        <!-- <div class="flex flex-0 items-center justify-center h-16 pr-6 pl-2 mt-2 mb-4 opacity-12">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" width="350px" height="118px" viewBox="0 0 350 118" class="h-20" style="fill: rgba(var(--fuse-primary-rgb)) !important;">
                <g>
                    <path d="M34.611,74.96c2.514,1.516,4.514,1.408,6.997,1.408c2.517,0,4.855-0.47,7.02-1.408   c2.164-0.939,4.052-2.224,5.662-3.851c1.609-1.626,2.877-3.522,3.799-5.687c0.923-2.164,1.385-4.454,1.385-6.87   c0-2.449-0.462-4.764-1.385-6.946c-0.921-2.18-2.189-4.077-3.799-5.686c-1.61-1.61-3.498-2.886-5.662-3.826   c-2.164-0.939-4.503-1.409-7.02-1.409c-2.483,0-4.815,0.47-6.997,1.409c-2.182,0.939-4.078,2.215-5.688,3.826   c-1.609,1.609-2.885,3.506-3.824,5.686c-0.939,2.182-1.409,3.665-1.409,6.946c0,1.67-6.673,1.737-6.684,0.066   c-0.031-4.479,0.646-6.622,1.938-9.62c1.293-2.998,3.055-5.6,5.288-7.812c2.232-2.21,4.842-3.961,7.831-5.251   c2.988-1.289,6.178-1.934,9.567-1.934c3.39,0,6.58,0.645,9.568,1.933c2.988,1.289,5.59,3.038,7.807,5.248   c2.216,2.208,3.962,4.812,5.238,7.807c1.275,2.994,1.914,6.2,1.914,9.613c0,3.38-0.64,6.552-1.915,9.513   c-1.276,2.962-3.022,5.548-5.24,7.758c-2.217,2.209-4.82,3.957-7.809,5.245s-6.179,1.933-9.571,1.933   c-3.561,0-5.491-0.215-9.949-2.059C26.543,78.871,29.422,71.827,34.611,74.96z"/>
                    <path d="M17.007,58.619c0,3.692-0.031,30.732-0.031,38.006   c0,7.274,0,12.729,0,16.366c5.148,0.563,10.128-0.084,14.94-1.93c3.692-1.398,7.022-3.414,9.986-6.043   c3.19-2.686,5.59-5.182,7.555-9.568c4.848-10.822-2.938-10.245-3.692-8.142c-3.079,8.58-4.337,9.653-8.646,13.179   c-3.915,3.244-8.42,5.176-13.511,5.791c0,0,0-0.029,0-0.084c0-0.057,0-0.086,0-0.086c0-3.41,0-8.127,0-14.141   c0-6.016,0-31.946,0-35.359"/>
                    <path d="M9.329,83.59c-1.598,0.923-3.642,0.376-4.565-1.224l0,0c-0.923-1.598-0.375-3.641,1.224-4.564   l25.379-14.653c1.598-0.923,3.643-0.375,4.565,1.224l0,0c0.923,1.599,0.375,3.643-1.223,4.564L9.329,83.59z"/>
                    <path d="M9.329,71.868c-1.598,0.922-3.642,0.375-4.565-1.224l0,0c-0.923-1.599-0.375-3.643,1.224-4.565   l25.379-14.652c1.598-0.923,3.643-0.375,4.565,1.223l0,0c0.923,1.599,0.375,3.643-1.223,4.565L9.329,71.868z"/>
                    <g>
                        <path d="M95.583,83.66c-3.415,0-6.61-0.65-9.584-1.952c-2.975-1.302-5.562-3.067-7.758-5.3    c-2.198-2.23-3.939-4.842-5.224-7.834c-1.285-2.992-1.927-6.195-1.927-9.61c0-3.449,0.641-6.677,1.925-9.686    c1.283-3.008,3.022-5.637,5.217-7.885c2.195-2.248,4.779-4.023,7.751-5.325c2.972-1.301,6.164-1.953,9.575-1.953    c3.444,0,6.653,0.651,9.625,1.953c2.972,1.302,5.556,3.068,7.75,5.299c2.195,2.231,3.926,4.86,5.192,7.885    c1.266,3.026,1.9,6.263,1.9,9.711v20.842c0,0.98-0.336,1.809-1.007,2.484c-0.671,0.677-1.493,1.014-2.466,1.014    c-1.007,0-1.804-0.337-2.391-1.014c-0.587-0.676-0.88-1.504-0.88-2.484l-0.051-3.651c-2.231,2.333-4.843,4.167-7.835,5.502    C102.404,82.993,99.133,83.66,95.583,83.66z M95.558,76.916c2.539,0,4.892-0.473,7.059-1.42c2.167-0.946,4.037-2.239,5.612-3.879    c1.573-1.641,2.81-3.551,3.707-5.73c0.897-2.182,1.346-4.488,1.346-6.922c0-2.468-0.449-4.8-1.346-6.999    c-0.897-2.197-2.133-4.107-3.707-5.73c-1.575-1.623-3.445-2.907-5.612-3.854c-2.167-0.946-4.52-1.42-7.059-1.42    c-2.505,0-4.833,0.474-6.983,1.42c-2.149,0.947-4.021,2.231-5.611,3.854c-1.591,1.623-2.844,3.533-3.758,5.73    c-0.915,2.198-1.371,4.531-1.371,6.999c0,2.434,0.456,4.741,1.371,6.922c0.915,2.18,2.167,4.09,3.758,5.73    c1.591,1.64,3.462,2.933,5.611,3.879C90.725,76.443,93.053,76.916,95.558,76.916z"/>
                        <path d="M129.357,59.979c0-3.55,0.676-6.888,2.028-10.016c1.352-3.126,3.195-5.865,5.528-8.215    c2.333-2.35,5.054-4.209,8.164-5.579c3.11-1.369,6.423-2.054,9.939-2.054h0.406c1.082,0,1.935,0.322,2.561,0.964    c0.625,0.643,0.938,1.454,0.938,2.434c0,0.981-0.338,1.783-1.014,2.409c-0.677,0.626-1.505,0.938-2.485,0.938h-0.406    c-2.569,0-4.995,0.499-7.277,1.496c-2.282,0.998-4.285,2.358-6.009,4.082s-3.093,3.753-4.107,6.085s-1.521,4.817-1.521,7.455    v19.676c0,0.98-0.313,1.792-0.938,2.434c-0.626,0.643-1.395,0.964-2.308,0.964c-0.947,0-1.767-0.321-2.459-0.964    c-0.693-0.642-1.04-1.453-1.04-2.434V59.979z"/>
                        <path d="M184.327,83.66c-3.415,0-6.61-0.65-9.584-1.952c-2.975-1.302-5.562-3.067-7.758-5.3    c-2.198-2.23-3.939-4.842-5.224-7.834c-1.285-2.992-1.927-6.195-1.927-9.61c0-3.449,0.641-6.677,1.925-9.686    c1.283-3.008,3.022-5.637,5.217-7.885c2.195-2.248,4.779-4.023,7.751-5.325c2.972-1.301,6.164-1.953,9.575-1.953    c3.445,0,6.652,0.651,9.625,1.953c2.973,1.302,5.557,3.068,7.75,5.299c2.195,2.231,3.928,4.86,5.193,7.885    c1.266,3.026,1.9,6.263,1.9,9.711v20.842c0,0.98-0.336,1.809-1.008,2.484c-0.672,0.677-1.492,1.014-2.465,1.014    c-1.008,0-1.805-0.337-2.393-1.014c-0.586-0.676-0.881-1.504-0.881-2.484l-0.051-3.651c-2.23,2.333-4.842,4.167-7.834,5.502    C191.147,82.993,187.878,83.66,184.327,83.66z M184.302,76.916c2.539,0,4.893-0.473,7.059-1.42    c2.166-0.946,4.037-2.239,5.611-3.879c1.574-1.641,2.811-3.551,3.707-5.73c0.896-2.182,1.346-4.488,1.346-6.922    c0-2.468-0.449-4.8-1.346-6.999c-0.896-2.197-2.133-4.107-3.707-5.73c-1.574-1.623-3.445-2.907-5.611-3.854    c-2.166-0.946-4.52-1.42-7.059-1.42c-2.505,0-4.833,0.474-6.983,1.42c-2.149,0.947-4.02,2.231-5.611,3.854    c-1.591,1.623-2.844,3.533-3.758,5.73c-0.915,2.198-1.371,4.531-1.371,6.999c0,2.434,0.456,4.741,1.371,6.922    c0.915,2.18,2.167,4.09,3.758,5.73c1.591,1.64,3.462,2.933,5.611,3.879C179.469,76.443,181.797,76.916,184.302,76.916z"/>
                        <path d="M218.102,22.959c0-0.946,0.337-1.757,1.014-2.434c0.676-0.676,1.471-1.014,2.384-1.014    c0.946,0,1.766,0.329,2.459,0.986c0.692,0.657,1.04,1.474,1.04,2.451v11.574h22.363c0.946,0,1.758,0.341,2.434,1.022    c0.677,0.682,1.015,1.499,1.015,2.453c0,0.886-0.329,1.653-0.989,2.299c-0.659,0.647-1.479,0.971-2.459,0.971h-22.363v18.205    c0,2.468,0.464,4.767,1.395,6.896c0.93,2.131,2.206,3.981,3.828,5.554c1.623,1.571,3.524,2.806,5.705,3.702    c2.181,0.896,4.539,1.344,7.074,1.344c2.231,0,4.311-0.338,6.237-1.015c1.928-0.676,3.753-1.774,5.477-3.296    c0.372-0.305,0.761-0.566,1.167-0.786c0.405-0.22,0.896-0.329,1.471-0.329c0.777,0,1.471,0.313,2.079,0.938    c0.608,0.626,0.912,1.378,0.912,2.257c0,0.507-0.109,1.015-0.329,1.521c-0.221,0.508-0.532,0.947-0.938,1.319    c-2.13,2.028-4.556,3.558-7.276,4.589c-2.722,1.031-5.654,1.547-8.799,1.547c-3.448,0-6.686-0.617-9.711-1.852    c-3.026-1.232-5.663-2.94-7.911-5.121s-4.023-4.75-5.324-7.708c-1.302-2.958-1.952-6.161-1.952-9.61V22.959z"/>
                        <path d="M266.023,23.162c0-1.149,0.388-2.112,1.166-2.89c0.777-0.777,1.74-1.167,2.891-1.167    c1.148,0,2.121,0.389,2.916,1.167c0.794,0.778,1.191,1.741,1.191,2.89c0,1.116-0.397,2.071-1.191,2.865    c-0.795,0.795-1.768,1.192-2.916,1.192c-1.15,0-2.113-0.397-2.891-1.192C266.411,25.233,266.023,24.278,266.023,23.162z     M266.632,37.564c0-0.98,0.335-1.8,1.006-2.459c0.671-0.659,1.477-0.989,2.417-0.989c0.973,0,1.77,0.33,2.391,0.989    c0.62,0.66,0.931,1.479,0.931,2.459v42.344c0,0.946-0.327,1.749-0.981,2.408s-1.435,0.988-2.34,0.988    c-0.94,0-1.746-0.329-2.417-0.988s-1.006-1.462-1.006-2.408V37.564z"/>
                        <path d="M284.507,82.088c-0.659-0.642-0.989-1.453-0.989-2.434V49.836c0-2.164,0.405-4.209,1.217-6.136    s1.961-3.634,3.448-5.122c1.487-1.487,3.187-2.637,5.097-3.449c1.91-0.811,3.947-1.217,6.11-1.217c2.468,0,4.793,0.55,6.974,1.648    c2.18,1.099,4.048,2.595,5.604,4.488c1.487-1.893,3.321-3.389,5.502-4.488c2.181-1.098,4.504-1.648,6.973-1.648    c2.13,0,4.184,0.406,6.161,1.217c1.978,0.812,3.71,1.945,5.198,3.398c1.521,1.488,2.704,3.195,3.55,5.122    c0.845,1.927,1.268,3.973,1.268,6.136v29.869c0,0.98-0.339,1.792-1.015,2.434c-0.677,0.643-1.471,0.964-2.383,0.964    c-0.947,0-1.75-0.321-2.409-0.964c-0.659-0.642-0.989-1.453-0.989-2.434V50.343c0-1.217-0.236-2.409-0.71-3.575    c-0.474-1.166-1.133-2.188-1.978-3.068c-0.845-0.879-1.843-1.563-2.991-2.054c-1.15-0.49-2.367-0.735-3.651-0.735    c-1.184,0-2.308,0.22-3.372,0.659c-1.065,0.44-2.021,1.065-2.865,1.876c-1.759,1.758-2.705,3.803-2.84,6.136    c0,0.102,0.017,0.187,0.051,0.254v0.253v29.514c0,0.947-0.339,1.75-1.015,2.408c-0.677,0.66-1.471,0.989-2.384,0.989    c-0.946,0-1.741-0.329-2.383-0.989c-0.643-0.658-0.964-1.461-0.964-2.408V50.394c0-1.25-0.228-2.451-0.685-3.601    c-0.456-1.149-1.107-2.181-1.952-3.093c-0.846-0.913-1.825-1.614-2.941-2.104c-1.115-0.49-2.315-0.735-3.601-0.735    c-1.184,0-2.307,0.22-3.372,0.659c-1.064,0.44-2.021,1.048-2.865,1.826c-0.812,0.778-1.479,1.691-2.003,2.738    c-0.524,1.048-0.837,2.13-0.938,3.246c0,0.102,0.017,0.186,0.051,0.253v0.304l-0.102,29.768c-0.033,0.98-0.38,1.792-1.039,2.434    c-0.659,0.643-1.445,0.964-2.358,0.964C285.969,83.052,285.166,82.73,284.507,82.088z"/>
                    </g>
                </g>
                </svg>
        </div> -->
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

    <!-- Header -->
    <div class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
        <!-- Navigation toggle button -->
        <button
            mat-icon-button
            (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
        </button>

        <div class="p-4 flex justify-between items-center">
            <a href="tel:{{contactPhone}}" class="text-base text-gray-700 hidden lg:block"> Müşteri Destek Hattı: <span class="mr-1 font-bold text-blue-700 underline decoration-2 underline-offset-4 decoration-blue-600 hover:decoration-gray-700 hover:text-gray-700">{{contactPhone}}</span></a>
            <a href="tel:{{contactPhone}}" class="block lg:hidden" matTooltip="Müşteri Destek Hattı: {{contactPhone}}" mat-icon-button ><mat-icon color="primary" [svgIcon]="'mat_solid:phone_in_talk'"></mat-icon></a>
            
        </div>
          
        
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <!-- <languages></languages> -->
            <button [routerLink]="['/payments/invoice/payment']" matTooltip="Fatura Öde" mat-icon-button ><mat-icon [svgIcon]="'mat_outline:receipt_long'"></mat-icon></button>
            <button [routerLink]="['/payments/socialInsurance/payment']" matTooltip="SSK-Bağkur Ödeme" mat-icon-button ><mat-icon [svgIcon]="'mat_outline:house_siding'"></mat-icon></button>
            <button [routerLink]="['/payments/mobileCredit/payment']" matTooltip="Cep Lira" mat-icon-button ><mat-icon [svgIcon]="'mat_solid:phone_iphone'"></mat-icon></button>

            <button [routerLink]="['/moneyTransfer/domestic/sendMoney']" matTooltip="Para Transferi" mat-icon-button ><mat-icon [svgIcon]="'heroicons_outline:cash'"></mat-icon></button>

            <button [routerLink]="['/payments/invoice/basket']" matTooltip="Sepet" mat-icon-button ><mat-icon [svgIcon]="'mat_outline:add_shopping_cart'"></mat-icon></button>
            <collective-query></collective-query>
            <upgrade></upgrade>
            <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
            <announcements></announcements>
            <notifications></notifications>
            <!-- <user [showAvatar]="false"></user> -->
            <!-- <search [appearance]="'bar'"></search> -->
            <!-- <shortcuts></shortcuts> -->
            <!-- <messages></messages> -->
            <!-- <button
                class="lg:hidden"
                mat-icon-button
                (click)="quickChat.toggle()">
                <mat-icon [svgIcon]="'heroicons_outline:chat-alt-2'"></mat-icon>
            </button> -->
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
        <quick-withdrawal></quick-withdrawal>

    </div>

    <!-- Footer -->
    <!--<div class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">Fuse &copy; {{currentYear}}</span>
    </div>-->

</div>

<!-- Quick chat -->
<!-- <quick-chat #quickChat="quickChat"></quick-chat> -->
