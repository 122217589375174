<toast-service></toast-service>
<div class="flex flex-col flex-auto min-w-0">

    <!-- Header -->
    <div class="p-6 sm:p-10">
        <div class="flex flex-between justify-between w-full bg-card shadow rounded-2xl overflow-hidden p-2 px-4 h-16">

            <div class="flex items-center">
                Kayıtlı Müşteriler
            </div>
    
            <div class="flex items-center">
    
                <div class="flex items-center justify-center items-center mr-2">
                    
                    <div
                        class="absolute inset-x-0 bottom-0"
                        *ngIf="isLoading">
                        <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
                    </div>

                    <div class="flex shrink-0 items-center mt-6 sm:mt-0 sm:ml-4">

                        <!-- Arama -->
                        <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded min-w-64">
                            <mat-icon
                                class="icon-size-5"
                                matPrefix
                                [svgIcon]="'heroicons_solid:search'"></mat-icon>
                            <input
                                matInput
                                [formControl]="searchInputControl"
                                [autocomplete]="'off'"
                                [placeholder]="'Müşteri Ara'">
                        </mat-form-field>

                        <!-- Yeni musteri ekle -->
                        <button
                            [disabled]="selectedCustomerInformations?.id == 0"
                            class="ml-4"
                            mat-flat-button
                            [color]="'primary'"
                            (click)="createCustomer()">
                            <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                            <span class="ml-2 mr-1">Müşteri Ekle</span>
                        </button>
                    </div>
    
                </div>
    
    
            </div>
        </div>
    </div>

    <!-- Main -->
    <div class="flex flex-auto overflow-hidden">

        <div class="flex flex-col flex-auto sm:mb-18 overflow-hidden sm:overflow-y-auto">

            <ng-container *ngIf="customers?.length > 0; else noProducts;">

                <div class="grid bg-card">
                    
                    <!-- Header -->
                    <div
                        class="inventory-grid z-10 sticky top-0 grid gap-4 py-4 px-6 md:px-8 shadow text-md font-semibold text-secondary bg-gray-50 dark:bg-black dark:bg-opacity-5"
                        (matSortChange)="sortData($event)"
                        matSort
                        matSortDisableClear>
                        
                        <div class="hidden sm:block">Düzenle/Sil</div>

                        <div
                            [mat-sort-header]="'display_name'">
                            Müşteri
                        </div>

                        
                        <div 
                            class="hidden lg:block"
                            [mat-sort-header]="'name'">
                            Ad
                        </div>

                        <div
                            class="hidden lg:block" 
                            [mat-sort-header]="'surname'">
                            Soyad
                        </div>

                        <!--
                        <div
                            class="hidden lg:block"
                            [mat-sort-header]="'price'">
                            Kayıt Tarihi
                        </div>
                        -->

                        <div class="hidden sm:block">Abonelikler</div>
                    </div>


                    <!-- Rows -->
                    <ng-container *ngFor="let customer of customers; trackBy: trackByFn">


                        <div class="inventory-grid grid items-center gap-4 py-3 px-6 md:px-8 border-b">

                            <div class="m-auto">
                                <button
                                    [disabled]="templateStatus == 'subscribers'"
                                    class="min-w-10 min-h-7 h-7 px-2 leading-6"
                                    mat-stroked-button
                                    (click)="toggleInformations(customer.id)">
                                    <mat-icon
                                        class="icon-size-5"
                                        [svgIcon]="selectedCustomerInformations?.id === customer.id ? 'heroicons_solid:chevron-up' : 'heroicons_solid:chevron-down'"></mat-icon>
                                </button>
                            </div>

                            <div class="truncate">
                                {{ customer?.display_name }}
                            </div>

                            <div class="hidden lg:block truncate">
                                {{ customer?.name }}
                            </div>

                            <div class="truncate hidden lg:block">
                                {{ customer?.surname }}
                            </div>

                            <!--
                            <div class="hidden lg:block">
                                {{ customer?.created_at }}
                            </div>
                            -->

                            <div class="m-auto">
                                <button
                                    [disabled]="templateStatus == 'informations'"
                                    class="min-w-10 min-h-7 h-7 px-2 leading-6"
                                    mat-stroked-button
                                    (click)="toggleSubscribers(customer.id)">
                                    <mat-icon
                                        class="icon-size-5"
                                        [svgIcon]="selectedCustomerSubscribers?.id === customer.id ? 'heroicons_solid:chevron-up' : 'heroicons_solid:chevron-down'"></mat-icon>
                                </button>
                            </div>
                        </div>

                        <div class="grid">
                            <ng-container *ngIf="selectedCustomerInformations?.id === customer.id && templateStatus == 'informations'">
                                <ng-container *ngTemplateOutlet="rowInformationTemplate; context: {$implicit: customer}"></ng-container>
                            </ng-container>

                            <ng-container *ngIf="selectedCustomerSubscribers?.id === customer.id && templateStatus == 'subscribers'">
                                <ng-container *ngTemplateOutlet="rowSubscribersTemplate; context: {$implicit: customer}"></ng-container>
                            </ng-container>
                        </div>
                    </ng-container>

                </div>

                <mat-paginator
                    class="sm:absolute sm:inset-x-0 sm:bottom-0 border-b sm:border-t sm:border-b-0 z-10 bg-gray-50 dark:bg-transparent"
                    [ngClass]="{'pointer-events-none': isLoading}"
                    [length]="pagination.length"
                    [pageIndex]="pagination.page"
                    [pageSize]="pagination.size"
                    [pageSizeOptions]="[5, 10, 25, 100]"
                    (page)="getServerData($event)"
                    [showFirstLastButtons]="true"></mat-paginator>

            </ng-container>


            <ng-template
                #rowInformationTemplate
                let-customer>
                <div class="shadow-lg bg-card overflow-hidden">
                    <div class="flex border-b">

                        <!-- Selected product form -->
                        <form
                            class="flex flex-col w-full"
                            [formGroup]="selectedCustomerInformationsForm">


                            <div class="flex flex-col w-full p-6">

                                <div class="flex">

                                    <mat-form-field class="w-full pr-4">
                                        <mat-label>Görünen Ad</mat-label>
                                        <input
                                            matInput
                                            [formControlName]="'display_name'">
                                        <mat-error *ngIf="selectedCustomerInformationsForm.get('display_name').hasError('required')">
                                            Görünen ad alanı zorunludur.
                                        </mat-error>
                                        <mat-error *ngIf="selectedCustomerInformationsForm.get('display_name').hasError('minlength')">
                                            Görünen ad alanı en az 2 karakter olabilir.
                                        </mat-error>
                                        <mat-error *ngIf="selectedCustomerInformationsForm.get('display_name').hasError('maxlength')">
                                            Görünen ad alanı en fazla 25 karakter olabilir.
                                        </mat-error>
                                    </mat-form-field>


                                    <mat-form-field class="w-full pr-4">
                                        <mat-label>Telefon Numarası</mat-label>
                                        <input
                                            matInput
                                            mask="(000) 000 00 00"
                                            [showMaskTyped]="true"
                                            [formControlName]="'telephone'">
                                        <mat-error *ngIf="selectedCustomerInformationsForm.get('telephone').hasError('required')">
                                            Telefon alanı zorunludur.
                                        </mat-error>
                                        <mat-error *ngIf="selectedCustomerInformationsForm.get('telephone').hasError('min')">
                                            Telefon alanı 5** ile başlamalıdır.
                                        </mat-error>
                                        <mat-error *ngIf="selectedCustomerInformationsForm.get('telephone').hasError('max')">
                                            Telefon alanı 5** ile başlamalıdır.
                                        </mat-error>
                                        <mat-error *ngIf="selectedCustomerInformationsForm.get('telephone').hasError('minlength')">
                                            Telefon alanı 10 rakamdan oluşmalıdır.
                                        </mat-error>
                                        <mat-error *ngIf="selectedCustomerInformationsForm.get('telephone').hasError('maxlength')">
                                            Telefon alanı 10 rakamdan oluşmalıdır.
                                        </mat-error>
                                    </mat-form-field>

                                    <mat-form-field class="w-full">
                                        <mat-label>E-Posta</mat-label>
                                        <input
                                            matInput
                                            type="email"
                                            [formControlName]="'email'">
                                        <mat-error *ngIf="selectedCustomerInformationsForm.get('email').hasError('email')">
                                            E-posta alanı sadece mail olabilir.
                                        </mat-error>
                                    </mat-form-field>

                                </div>
                                

                                <div class="flex">
                                    <mat-form-field class="w-1/2 pr-4">
                                        <mat-label>Ad</mat-label>
                                        <input
                                            matInput
                                            [formControlName]="'name'">
                                        <mat-error *ngIf="selectedCustomerInformationsForm.get('name').hasError('required')">
                                            Ad alanı zorunludur.
                                        </mat-error>
                                        <mat-error *ngIf="selectedCustomerInformationsForm.get('name').hasError('minlength')">
                                            Ad alanı en az 2 karakter olabilir.
                                        </mat-error>
                                        <mat-error *ngIf="selectedCustomerInformationsForm.get('name').hasError('maxlength')">
                                            Ad alanı en fazla 25 karakter olabilir.
                                        </mat-error>
                                    </mat-form-field>
                                    <mat-form-field class="w-1/2">
                                        <mat-label>Soyad</mat-label>
                                        <input
                                            matInput
                                            [formControlName]="'surname'">
                                        <mat-error *ngIf="selectedCustomerInformationsForm.get('surname').hasError('required')">
                                            Soyad alanı zorunludur.
                                        </mat-error>
                                        <mat-error *ngIf="selectedCustomerInformationsForm.get('surname').hasError('minlength')">
                                            Soyad alanı en az 2 karakter olabilir.
                                        </mat-error>
                                        <mat-error *ngIf="selectedCustomerInformationsForm.get('surname').hasError('maxlength')">
                                            Soyad alanı en fazla 25 karakter olabilir.
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                

                            </div>
                                       

                            <div class="flex items-center justify-between w-full border-t px-8 py-4">

                                <button
                                    *ngIf="selectedCustomerInformations?.id != 0"
                                    class="-ml-4"
                                    mat-button
                                    [color]="'warn'"
                                    [disabled]="selectedCustomerInformationsForm.invalid || selectedCustomerInformationsForm.disabled"
                                    (click)="hardDeleteCustomer()">
                                    <span *ngIf="!selectedCustomerInformationsForm.disabled">
                                        Sil
                                    </span>
                                    <mat-progress-spinner
                                        *ngIf="selectedCustomerInformationsForm.disabled"
                                        [diameter]="24"
                                        [mode]="'indeterminate'"></mat-progress-spinner>
                                </button>
                                <button
                                    *ngIf="selectedCustomerInformations?.id == 0"
                                    class="-ml-4"
                                    mat-button
                                    [color]="'warn'"
                                    (click)="cancel()">
                                    <span *ngIf="!selectedCustomerInformationsForm.disabled">
                                        İptal Et
                                    </span>
                                    <mat-progress-spinner
                                        *ngIf="selectedCustomerInformationsForm.disabled"
                                        [diameter]="24"
                                        [mode]="'indeterminate'"></mat-progress-spinner>
                                </button>

                                <button
                                    *ngIf="selectedCustomerInformations?.id != 0"
                                    mat-flat-button
                                    [color]="'primary'"
                                    [disabled]="selectedCustomerInformationsForm.invalid || selectedCustomerInformationsForm.disabled"
                                    (click)="updateCustomer()">
                                    <span *ngIf="!selectedCustomerInformationsForm.disabled">
                                        Güncelle
                                    </span>
                                    <mat-progress-spinner
                                        *ngIf="selectedCustomerInformationsForm.disabled"
                                        [diameter]="24"
                                        [mode]="'indeterminate'"></mat-progress-spinner>
                                </button>

                                <button
                                    *ngIf="selectedCustomerInformations?.id == 0"
                                    mat-flat-button
                                    [color]="'primary'"
                                    [disabled]="selectedCustomerInformationsForm.invalid || selectedCustomerInformationsForm.disabled"
                                    (click)="storeCustomer()">
                                    <span *ngIf="!selectedCustomerInformationsForm.disabled">
                                        Ekle
                                    </span>
                                    <mat-progress-spinner
                                        *ngIf="selectedCustomerInformationsForm.disabled"
                                        [diameter]="24"
                                        [mode]="'indeterminate'"></mat-progress-spinner>
                                </button>
                            </div>
                            
                        </form>

                    </div>
                </div>
            </ng-template>


            <ng-template
                #rowSubscribersTemplate
                let-customer>

                    <!-- Subscriptions Header -->
                    <div class="flex flex-col w-full">
                        <div class="bg-default rounded-lg p-6 h-full">
                            <div class="flex flex-between justify-between w-full bg-card shadow rounded-2xl overflow-hidden p-2 px-4 h-16">
                    
                                 <!-- Yeni abone ekle -->
                                 <div class="flex shrink-0 items-center mt-6 sm:mt-0 sm:ml-2">
                                    <button
                                        mat-flat-button
                                        [color]="'primary'"
                                        [disabled]="queryAllStatus"
                                        (click)="queryAll()">

                                        <span *ngIf="!queryAllStatus">
                                            Tüm Borçları Sorgula
                                        </span>

                                        <span *ngIf="queryAllStatus" class="mr-2">
                                            Lütfen Bekleyiniz
                                        </span>
                                        <mat-progress-spinner
                                            *ngIf="queryAllStatus"
                                            [diameter]="24"
                                            [mode]="'indeterminate'"></mat-progress-spinner>
                                    </button>
                                </div>

                                 <div class="flex shrink-0 items-center mt-6 sm:mt-0 sm:ml-2">
                                    <mat-checkbox [color]="'primary'" class="text-sm" [(ngModel)]="orderByDesc" name="orderByDesc" [ngModelOptions]="{standalone: true}" id="orderByDesc">Sorgu sonucunu açıklamaya göre sırala</mat-checkbox>
                                </div>
                        
                                <div class="flex items-center">
                        
                                    <div class="flex items-center justify-center items-center mr-2">
                                        
                                        <div
                                            class="absolute inset-x-0 bottom-0"
                                            *ngIf="isLoading">
                                            <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
                                        </div>
                    
                                        <div class="flex shrink-0 items-center mt-6 sm:mt-0 sm:ml-4">
                    
                                            <!-- Yeni abone ekle -->
                                            <button
                                                class="ml-4"
                                                mat-flat-button
                                                [color]="'primary'"
                                                (click)="newSubscribers()">
                                                <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                                                <span class="ml-2 mr-1">Abonelik Ekle</span>
                                            </button>
                                        </div>
                        
                                    </div>
                        
                        
                                </div>
                            </div>

                            <div class="mt-4 bg-card rounded-2xl p-6">


                                <table datatable [dtOptions]="dtOptions" class="row-border lg:text-md md:text-sm hover">
                                </table>


                            </div>
                        </div>
                    </div>

                    <!-- Subscriptions Table -->
                    <div class="flex flex-col w-full sm:pl-8">
                    </div>

            </ng-template>


            <ng-template #noProducts>
                <div *ngIf="customersWaiting == false" class="flex flex-col justify-center items-center h-full">
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" version="1.2" viewBox="0 0 64 81" width="64" height="81">
                        <g id="Layer"/>
                        <path id="logop" repeatCount="indefinite" d="M 26.618 43.414 C 26.618 43.414 30.396 46.751 38.434 47.092 C 46.472 47.433 60.076 40.647 59.923 25.512 C 59.77 10.377 46.296 4.406 39.179 4.409 C 32.062 4.412 17.827 8.69 17.193 25.337 C 16.559 41.984 17.304 77.099 17.304 77.099 C 17.304 77.099 41.189 75.471 45.17 55.386"/>
                        <polyline id="one" repeatCount="indefinite" points="2.305 37.347 32.348 19.748"/>
                        <polyline id="two" repeatCount="indefinite" points="2.334 49.19 32.377 31.591"/>
                    </svg> -->
                    <svg class="onaymatik-logo-animation" version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="64" height="64">
                        <defs>
                            <image  width="64" height="64" id="img1" href="assets/images/logo/logo-text.svg"/>
                        </defs>
                        <style>
                        </style>
                        <use id="Background" href="#img1" x="0" y="0"/>
                    </svg>
                    <span class="text-xl text-secondary py-2">
                        Lütfen Bekleyiniz..
                    </span>
                </div>

                <div *ngIf="customersWaiting == true" class="flex flex-col justify-center items-center">Kayıtlı Müşteri Bulunmamaktadır.</div>
            </ng-template>


        </div>

    </div>

</div>
