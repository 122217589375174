<fuse-alert [type]="'warning'">
  <span fuseAlertTitle>Hesabını Doğrula</span>
  Bu işlemi gerçekleştirebilmek için hesabınızı doğrulamanız gerekmektedir. Hesabınızı doğrulamak için <b>Şimdi Doğrula</b> veya  <b>Ayarlar -> Hesap Doğrulama</b> bölümünden gerçekleştirebilirsiniz.  
</fuse-alert>

<div class="flex items-right mt-4 sm:mt-0">
  <!-- Discard -->
  <button class="ml-auto sm:ml-0 sm:mr-3" mat-stroked-button (click)="discard()">
    Vazgeç
  </button>
  <!-- Send -->
  <button
    class="order-first sm:order-last"
    mat-flat-button
    [color]="'primary'"
    (click)="redirectForKyc()"
  >
    Şimdi Doğrula
  </button>
</div>