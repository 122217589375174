import { NgModule } from '@angular/core';
import { PhonePipe } from 'app/layout/common/pipes/phonePipe';
import { IbanPipes } from 'app/layout/common/pipes/ibanPipes';
import { MoneyPipes } from 'app/layout/common/pipes/moneyPipes';

@NgModule({
    declarations: [
        PhonePipe,
        IbanPipes,
        MoneyPipes
    ],
    exports: [
        PhonePipe,
        IbanPipes,
        MoneyPipes
    ]
})
export class SharedPipesModule
{
}
