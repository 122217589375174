import { Component, ViewChild } from '@angular/core';
import { ThemingComponent } from 'app/modules/settings/customization/theming/theming';
import { AppConfig, Scheme, Theme, Themes } from 'app/core/config/app.config';
import { FuseConfigService } from "@fuse/services/config";
// import Echo from 'laravel-echo';

@Component({
    selector   : 'app-root',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss']
})

export class AppComponent
{
    @ViewChild(ThemingComponent) themingComponent: ThemingComponent;


    /**
     * Constructor
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
    )
    {
    }


    ngOnInit(): void {

        if (localStorage.getItem("scheme")) {
            this._fuseConfigService.config = {
                scheme: localStorage.getItem("scheme"),
            };
        }

        if (localStorage.getItem("theme")) {
            this._fuseConfigService.config = {
                theme: localStorage.getItem("theme"),
            };
        }
    }

}
