import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from 'app/shared/shared.module';
import { UpgradeComponent } from './upgrade.component';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';


@NgModule({
    declarations: [
        UpgradeComponent,
    ],
    imports     : [
        SharedModule,
        MatIconModule,
        MatButtonModule,
        MatTooltipModule
    ],
    exports     : [
        UpgradeComponent
    ],
})

export class UpgradeModule
{
}
