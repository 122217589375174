<toast-service></toast-service>

<div class="flex flex-col flex-auto min-w-0">
	<div class="flex flex-row justify-end">

	    <button
	    	[color]="'warn'"
		    mat-icon-button
		    matTooltip="Kapat"
		    (click)="cancel()">
		    <mat-icon [svgIcon]="'mat_solid:close'"></mat-icon>
		</button>


	</div>

    <div class="flex-auto md:p-2 lg:p-4">
		<div class="flex flex-col rounded-xl overflow-hidden mb-6">

    		<!-- Fatura Seç -->
			<form *ngIf="data?.debt_count > 0">
				<div 
    				*ngFor="let institutions of data?.soots; let i = index;"
                	class="bg-card rounded-xl overflow-hidden">
	                <div 
	            		*ngFor="let invoices of institutions?.subscribers; let j = index;"
	            		class="flex flex-col w-full">

						<div class="flex flex-col mt-4 md:mt-6 mb-2 bg-default rounded-xl">
							<table class="mat-table-bg-none" mat-table [dataSource]="invoices?.debts">

								<ng-container matColumnDef="id">
									<th class="text-center px-2 text-center border-r" mat-header-cell *matHeaderCellDef>
										<div class="flex flex-col items-center justify-center">
											<span class="text-sm font-normal text-secondary"></span>
										</div>
									</th>
									<td class="text-center" style="border-left: 1px solid rgba(var(--fuse-border-rgb), var(--tw-border-opacity)) !important; padding: 0 0.5rem !important;" [ngClass]="{'mat-table-last-border-none': k === invoices?.debts.length - 1}" mat-cell class="text-center" *matCellDef="let element; let k = index;">
										<mat-checkbox
											[(ngModel)]="invoicePayCheckStatus[i][j][k]"
											name="invoicePayCheckStatus[i][j][k]"
											(change)="selectPayInvoice()"
											[disabled]="!invoiceStatus"
											[color]="'primary'">
										</mat-checkbox>
									</td>
								</ng-container>

								

								<ng-container matColumnDef="institutions">
									<th class="px-2 text-center border-r" mat-header-cell *matHeaderCellDef> Kurum </th>
									<td class="px-2 text-center text-sm" style="border-left: 1px solid rgba(var(--fuse-border-rgb), var(--tw-border-opacity)) !important;" [ngClass]="{'mat-table-last-border-none': k === invoices?.debts.length - 1}" mat-cell *matCellDef="let element; let k = index;"> {{ institutions?.name }} </td>
								</ng-container>

								<ng-container matColumnDef="name">
									<th class="px-2 text-center border-r" mat-header-cell *matHeaderCellDef> Abone </th>
									<td class="px-2 text-center text-sm" style="border-left: 1px solid rgba(var(--fuse-border-rgb), var(--tw-border-opacity)) !important;" [ngClass]="{'mat-table-last-border-none': k === invoices?.debts.length - 1}" mat-cell *matCellDef="let element; let k = index;"> {{ invoices?.name }} </td>
								</ng-container>

								<ng-container matColumnDef="fields">
									<th class="px-2 text-center border-r" mat-header-cell *matHeaderCellDef> Abone No </th>
									<td class="px-2 text-center text-sm" style="border-left: 1px solid rgba(var(--fuse-border-rgb), var(--tw-border-opacity)) !important;" [ngClass]="{'mat-table-last-border-none': k === invoices?.debts.length - 1}" mat-cell *matCellDef="let element; let k = index;"> {{ invoices.fields[0] }} </td>
								</ng-container>

								<ng-container matColumnDef="invoice_no">
									<th class="px-2 text-center border-r" mat-header-cell *matHeaderCellDef> Fatura No </th>
									<td class="px-2 text-center text-sm" style="border-left: 1px solid rgba(var(--fuse-border-rgb), var(--tw-border-opacity)) !important;" [ngClass]="{'mat-table-last-border-none': k === invoices?.debts.length - 1}" mat-cell *matCellDef="let element; let k = index;"> {{ element.invoice_no }} </td>
								</ng-container>

								<ng-container matColumnDef="debt">
									<th class="px-2 text-center border-r" mat-header-cell *matHeaderCellDef> Borç </th>
									<td class="px-2 text-center" style="border-left: 1px solid rgba(var(--fuse-border-rgb), var(--tw-border-opacity)) !important;" [ngClass]="{'mat-table-last-border-none': k === invoices?.debts.length - 1}" mat-cell *matCellDef="let element; let k = index;"> {{ element.debt | number: '1.2-2' }} ₺ </td>
								</ng-container>

								<ng-container matColumnDef="total_commission">
									<th class="px-2 text-center border-r" mat-header-cell *matHeaderCellDef> İşlem Tutar </th>
									<td class="px-2 text-center" style="border-left: 1px solid rgba(var(--fuse-border-rgb), var(--tw-border-opacity)) !important;" [ngClass]="{'mat-table-last-border-none': k === invoices?.debts.length - 1}" mat-cell *matCellDef="let element; let k = index;"> {{ element.total_commission | number: '1.2-2' }} ₺ </td>
								</ng-container>

								<ng-container matColumnDef="total">
									<th class="px-2 text-center border-r" mat-header-cell *matHeaderCellDef> Toplam Tutar </th>
									<td class="px-2 text-center" style="border-left: 1px solid rgba(var(--fuse-border-rgb), var(--tw-border-opacity)) !important;" [ngClass]="{'mat-table-last-border-none': k === invoices?.debts.length - 1}" mat-cell *matCellDef="let element; let k = index;"> {{ element.debt + element.total_commission | number: '1.2-2' }} ₺ </td>
								</ng-container>

								<ng-container matColumnDef="last_payment_date">
									<th class="px-2 text-center border-r" mat-header-cell *matHeaderCellDef> Son Ödeme Tarihi </th>
									<td class="px-2 text-center text-sm" style="border-left: 1px solid rgba(var(--fuse-border-rgb), var(--tw-border-opacity)) !important;" [ngClass]="{'mat-table-last-border-none': k === invoices?.debts.length - 1}" mat-cell *matCellDef="let element; let k = index;"> {{ element.last_payment_date | date: 'dd.MM.y' }} </td>
								</ng-container>

								<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
								<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
							</table>	
						</div>
						
	                </div>

	            </div>

	            <div *ngIf="responseQueryOne" class="flex flex-col rounded-xl overflow-hidden bg-card">
                    <div class="flex flex-col px-6">

                        
                        <table class="mat-table-bg-none" mat-table [dataSource]="responseQueryOne?.debts">

								<ng-container matColumnDef="id">
									<th class="text-center px-2 text-center border-r" mat-header-cell *matHeaderCellDef>
										<div class="flex flex-col items-center justify-center">
											<span class="text-sm font-normal text-secondary"></span>
										</div>
									</th>
									<td class="text-center" style="border-left: 1px solid rgba(var(--fuse-border-rgb), var(--tw-border-opacity)) !important; padding: 0 0.5rem !important;" [ngClass]="{'mat-table-last-border-none': k === responseQueryOne?.debts.length - 1}" mat-cell class="text-center" *matCellDef="let element; let k = index;">
										<mat-checkbox
											[(ngModel)]="invoicePayCheckStatus[k]"
											name="invoicePayCheckStatus[k]"
											(change)="selectPayInvoice()"
											[disabled]="!invoiceStatus"
											[color]="'primary'">
										</mat-checkbox>
									</td>
								</ng-container>

								<ng-container matColumnDef="institutions">
									<th class="px-2 text-center border-r" mat-header-cell *matHeaderCellDef> Kurum </th>
									<td class="px-2 text-center text-sm" style="border-left: 1px solid rgba(var(--fuse-border-rgb), var(--tw-border-opacity)) !important;" mat-cell *matCellDef="let element; let k = index;"> {{ responseQueryOne?.soot?.name }} </td>
								</ng-container>

								<ng-container matColumnDef="name">
									<th class="px-2 text-center border-r" mat-header-cell *matHeaderCellDef> Abone </th>
									<td class="px-2 text-center text-sm" style="border-left: 1px solid rgba(var(--fuse-border-rgb), var(--tw-border-opacity)) !important;" mat-cell *matCellDef="let element; let k = index;"> {{ responseQueryOne?.subscriber?.name }} </td>
								</ng-container>

								<ng-container matColumnDef="fields">
									<th class="px-2 text-center border-r" mat-header-cell *matHeaderCellDef> Abone No </th>
									<td class="px-2 text-center text-sm" style="border-left: 1px solid rgba(var(--fuse-border-rgb), var(--tw-border-opacity)) !important;" mat-cell *matCellDef="let element; let k = index;"> {{ responseQueryOne?.subscriber.fields[0] }} </td>
								</ng-container>

								<ng-container matColumnDef="invoice_no">
									<th class="px-2 text-center border-r" mat-header-cell *matHeaderCellDef> Fatura No </th>
									<td class="px-2 text-center text-sm" style="border-left: 1px solid rgba(var(--fuse-border-rgb), var(--tw-border-opacity)) !important;" mat-cell *matCellDef="let element; let k = index;"> {{ element.invoice_no }} </td>
								</ng-container>

								<ng-container matColumnDef="debt">
									<th class="px-2 text-center border-r" mat-header-cell *matHeaderCellDef> Borç </th>
									<td class="px-2 text-center" style="border-left: 1px solid rgba(var(--fuse-border-rgb), var(--tw-border-opacity)) !important;" mat-cell *matCellDef="let element; let k = index;"> {{ element.debt | number: '1.2-2' }} ₺ </td>
								</ng-container>

								<ng-container matColumnDef="total_commission">
									<th class="px-2 text-center border-r" mat-header-cell *matHeaderCellDef> İşlem Tutar </th>
									<td class="px-2 text-center" style="border-left: 1px solid rgba(var(--fuse-border-rgb), var(--tw-border-opacity)) !important;" mat-cell *matCellDef="let element; let k = index;"> {{ element.total_commission | number: '1.2-2' }} ₺ </td>
								</ng-container>

								<ng-container matColumnDef="total">
									<th class="px-2 text-center border-r" mat-header-cell *matHeaderCellDef> Toplam Tutar </th>
									<td class="px-2 text-center" style="border-left: 1px solid rgba(var(--fuse-border-rgb), var(--tw-border-opacity)) !important;" mat-cell *matCellDef="let element; let k = index;"> {{ element.debt + element.total_commission | number: '1.2-2' }} ₺ </td>
								</ng-container>

								<ng-container matColumnDef="last_payment_date">
									<th class="px-2 text-center border-r" mat-header-cell *matHeaderCellDef> Son Ödeme Tarihi </th>
									<td class="px-2 text-center text-sm" style="border-left: 1px solid rgba(var(--fuse-border-rgb), var(--tw-border-opacity)) !important;" mat-cell *matCellDef="let element; let k = index;"> {{ element.last_payment_date | date: 'dd.MM.y' }} </td>
								</ng-container>

								<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
								<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
							</table>	

                    </div>

                </div>


	            <div class="bg-card shadow rounded-xl overflow-hidden p-2 sm:p-4 md:p-4">

		            <div class="flex flex-col my-2 sm:my-0 text-center">
						<div class="grid grid-cols-3 gap-3">
							<div class="flex flex-col fuse-border-color-right-1">
								<span class="text-sm text-secondary">Borç Toplam</span>
								<span class="font-medium">{{ data?.totalDebt | number: '1.2-2' }} ₺</span>
							</div>
							<div class="flex flex-col fuse-border-color-right-1">
								<span class="text-sm text-secondary">İşlem Bedeli</span>
								<span class="font-medium">{{ data?.totalCommission | number: '1.2-2' }} ₺</span>
							</div>
							<div class="flex flex-col">
								<span class="text-sm text-secondary">Genel Toplam</span>
								<span class="font-medium">{{ data?.totalDebtCommission | number: '1.2-2' }} ₺</span>
							</div>
						</div>
					</div>

					<div class="flex flex-col sm:flex-row justify-between items-center sm:mt-4">

						<button
		                    class="px-4 my-2 sm:my-0"
		                    mat-flat-button
		                    style="background: #f43f5f;"
							[disabled]="!invoiceStatus"
		                    (click)="cancel()"
		                    [color]="'warn'">
		                    <span *ngIf="invoiceStatus">
		                        İptal
		                    </span>
		                    <span *ngIf="!invoiceStatus" class="mr-2">
		                        Lütfen Bekleyiniz
		                    </span>
		                    <mat-progress-spinner
		                        *ngIf="!invoiceStatus"
		                        [diameter]="24"
		                        [mode]="'indeterminate'"></mat-progress-spinner>
		                </button>

		                <div class="flex items-center bg-card rounded-xl overflow-hidden p-4">
							<div class="mr-2">Tümünü Seç</div>
							<div class="h-10 flex">
								<mat-checkbox
									class="flex m-auto"
									[(ngModel)]="invoiceAllPayCheckStatus"
									name="invoiceAllPayCheckStatus"
									(change)="selectAllPayInvoices()"
									[disabled]="!invoiceStatus || basket?.debt_count == 0"
									[color]="'primary'">
								</mat-checkbox>
							</div>
						</div>

						<button
		                    class="px-4 my-2 sm:my-0"
		                    mat-flat-button
		                    [color]="'primary'"
							(click)="getBasketRoute()">
		                    <span *ngIf="invoiceStatus">
		                        Sepete Git
		                    </span>
						</button>


		                <button
		                    class="px-4 my-2 sm:my-0"
		                    mat-flat-button
		                    [color]="'primary'"
							[disabled]="data?.totalDebtCommission == 0 || !invoiceStatus"
							(click)="basket()">

		                    <span *ngIf="invoiceStatus">
		                        Sepete Ekle
		                    </span>
		                    <span *ngIf="!invoiceStatus" class="mr-2">
		                        Lütfen Bekleyiniz
		                    </span>
		                    <mat-progress-spinner
		                        *ngIf="!invoiceStatus"
		                        [diameter]="24"
		                        [mode]="'indeterminate'"></mat-progress-spinner>
						</button>



					</div>

				</div>


            </form>

    	</div>
    </div>
</div>