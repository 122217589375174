import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
// import { MarkdownModule } from 'ngx-markdown';
import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';
import { FuseMockApiModule } from '@fuse/lib/mock-api';
import { CoreModule } from 'app/core/core.module';
import { appConfig } from 'app/core/config/app.config';
import { mockApiServices } from 'app/mock-api';
import { LayoutModule } from 'app/layout/layout.module';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';

import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from 'environments/environment';



// import { SocketIOService } from 'app/core/socket/socket-io.service';
// import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';

/*
const config: SocketIoConfig = {
    url: 'http://platform-user-api.onaymatik.com:3000',
    options: {
        
        
        extraHeaders: {
            Authorization: localStorage.getItem('accessToken')
        },
    } 
};
*/

const routerConfig: ExtraOptions = {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled'
};

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),

        // Fuse, FuseConfig & FuseMockAPI
        FuseModule,
        FuseConfigModule.forRoot(appConfig),
        FuseMockApiModule.forRoot(mockApiServices),

        // Core module of your application
        CoreModule,

        // Layout module of your application
        LayoutModule,
        MatSnackBarModule,

        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: !isDevMode(),
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        }),

        // 3rd party modules that require global configuration via forRoot
        // MarkdownModule.forRoot({})

        // SocketIoModule.forRoot(config)
    ],
    providers: [
        // SocketIOService,
        { provide: "baseUrl", useValue: environment.baseUrl, multi: true },
        { provide: "storageUrl", useValue: environment.storageUrl, multi: true },
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
}
