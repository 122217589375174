<div class="absolute inset-0 flex flex-col min-w-0 overflow-hidden">

    <mat-drawer-container class="flex-auto h-full">

        <!-- Drawer -->
        <mat-drawer
            class="w-60 dark:bg-gray-900"
            [autoFocus]="false"
            [mode]="drawerMode"
            [opened]="drawerOpened"
            #matDrawer>

            <fuse-vertical-navigation
                [navigation]="filteredMenuData"
                [inner]="true"
                [mode]="'side'"
                [name]="'docs-guides-navigation'"
                [opened]="true"></fuse-vertical-navigation>

        </mat-drawer>

        <!-- Drawer content -->
        <mat-drawer-content
            class="flex flex-col"
            fuseScrollReset>

            <!-- Main -->
            <div class="flex flex-auto">
                <router-outlet></router-outlet>
            </div>

        </mat-drawer-content>

    </mat-drawer-container>

</div>


<div class="flex flex-col justify-center items-center h-full w-full absolute z-20 m-auto" style="border:5px solid; background-color:rgba(0, 0, 0, 0.5); backdrop-filter: blur(10px);" *ngIf="!passStatus">
    <div class="w-full sm:w-auto py-8 px-4 sm:p-12 sm:rounded-2xl sm:shadow sm:bg-card">
        <div class="w-full max-w-80 sm:w-80 mx-auto sm:mx-0">
           
            <div class="w-full w-72 m-auto">
                <img src="assets/images/logo/onaymatik-logo.svg">
            </div>

            
            <div class="mt-8 text-4xl font-extrabold tracking-tight leading-tight">Güvenlik Kilidini Aç</div>
            <div class="mt-0.5 font-medium">Lütfen güvenlik için giriş şifrenizi giriniz.</div>
           

            
            <form class="mt-8">

                <mat-form-field class="w-full">
                    <mat-label>Şifre</mat-label>
                    <input
                        #inputRef
                        id="password"
                        matInput
                        type="password"

                        [(ngModel)]="pass"
                        [ngModelOptions]="{standalone: true}"
                        #passwordField>
                    <button
                        mat-icon-button
                        type="button"
                        (click)="passwordField.type === 'password' ? passwordField.type = 'text' : passwordField.type = 'password'"
                        matSuffix>
                        <mat-icon
                            class="icon-size-5"
                            *ngIf="passwordField.type === 'password'"
                            [svgIcon]="'heroicons_solid:eye'"></mat-icon>
                        <mat-icon
                            class="icon-size-5"
                            *ngIf="passwordField.type === 'text'"
                            [svgIcon]="'heroicons_solid:eye-off'"></mat-icon>
                    </button>
                    <mat-error>
                        Şifre alanı zorunludur!
                    </mat-error>
                </mat-form-field>

                <button
                    class="fuse-mat-button-large w-full mt-3"
                    mat-flat-button
                    [color]="'primary'"
                    (click)="checkPassword()"
                    [disabled]="!pass"
                    >
                    <span>
                        Giriş
                    </span>
                </button>
            </form>
        </div>
    </div>
</div>